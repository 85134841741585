<div class="container resources-page">

   <div class="fs-3 pt-2 fw-bold text-uppercase text-site-secondary mb-4 ms-2 ms-sm-0 text-sm-center">
      Menu
   </div>

   <div class="resources-wrapper">

      <div class="d-flex flex-column resources align-items-center">

         <!-- temporary measure to get in the app store -->
         <!--<a class="d-flex border rounded p-2 bg-white" style="max-width:450px"
      href="{{resourceMenuUris.academicResources}}" [attr.target]="(linkTarget$|async)">
      <div class="flex-shrink-0">
         <img src="{{resourceImagesFolder}}/adv4teens.png" alt="Advantage4Teens" class="rounded media-image">
      </div>
      <div class="flex-grow-1 ms-3">
         <div class="fs-4 fw-bold text-site">Academic resources</div>
         <span class="fw-bold link-underline-primary">Click here</span>
         <span class="text-site"> to see instructional videos, quizzes, worksheets, and articles that provide comprehensive learning and practice in core subjects.</span>
      </div>
   </a>-->
         <div class="d-flex border rounded p-2 bg-white pointable" style="max-width:450px"
              (click)="externalNavigate(resourceMenuUris.academicResources)">
            <div class="flex-shrink-0">
               <img src="{{resourceImagesFolder}}/academic-resources_v2.png" alt="Advantage4Teens" class="rounded media-image">
            </div>
            <div class="flex-grow-1 ms-3">
               <div class="fs-4 fw-bold text-site">Academic Resources</div>
               <span class="fw-bold link-underline-primary">Click here</span>
               <span class="text-site"> to see instructional videos, quizzes, worksheets, and articles that provide comprehensive learning and practice in core subjects.</span>
            </div>
         </div>

         <div class="d-flex border rounded p-2 bg-white pointable" style="max-width:450px"
              (click)="navigate(contentRoutes.meetTheMentors.uri)">
            <div class="flex-shrink-0">
               <img src="{{resourceImagesFolder}}/meet-the-mentors_v2.png" alt="Meet The Mentors" class="rounded media-image">
            </div>
            <div class="flex-grow-1 ms-3">
               <div class="fs-4 fw-bold text-site mb-3">Meet The Mentors</div>
               <span class="fw-bold link-underline-primary">Click here</span>
               <span class="text-site"> to read and get to know your mentors so you can book a meeting with your favorite!</span>
            </div>
         </div>

         <div class="d-flex border rounded p-2 bg-white pointable" style="max-width:450px"
              (click)="navigate(contentRoutes.faq.uri)">
            <div class="flex-shrink-0">
               <img src="{{resourceImagesFolder}}/faq_v2.png" alt="FAQ" class="rounded media-image">
            </div>
            <div class="flex-grow-1 ms-3">
               <div class="fs-4 fw-bold text-site mb-3">FAQ</div>
               <span class="text-site">Have a question about</span>
               <span class="fw-bold link-underline-primary"> LE&Delta;D</span>
               <span class="text-site">? This is your first stop!</span>
            </div>
         </div>

         <div class="d-flex border rounded p-2 bg-white pointable" style="max-width:450px"
              (click)="externalNavigate(resourceMenuUris.privacyPolicy)">
            <div class="flex-shrink-0">
               <img src="{{resourceImagesFolder}}/privacy-policy_v2.png" alt="Privacy Policy - Southwestern Advantage" class="rounded media-image">
            </div>
            <div class="flex-grow-1 ms-3">
               <div class="fs-4 fw-bold text-site mb-3">Privacy Policy</div>
               <span class="text-site">What is the </span>
               <span class="fw-bold link-underline-primary">Privacy Policy</span>
               <span class="text-site"> of Southwestern Advantage?</span>
            </div>
         </div>


         <!--<div class="resource">
      <a href="{{resourceMenuUris.academicResources}}" [attr.target]="(linkTarget$|async)">
         <img src="{{resourceImagesFolder}}/academic-resources.png" />
      </a>
   </div>-->
         <!--<div class="resource">
      <a href="#" [attr.target]="(linkTarget$|async)">
         <img src="{{resourceImagesFolder}}/lead-highlights.png" />
      </a>
   </div>

   <div class="resource">
      <a href="#" [attr.target]="(linkTarget$|async)">
         <img src="{{resourceImagesFolder}}/meet-the-mentors.png" />
      </a>
   </div>

   <div class="resource">
      <a href="#" [attr.target]="(linkTarget$|async)">
         <img src="{{resourceImagesFolder}}/faq.png" />
      </a>
   </div>-->

      </div>

   </div>

</div>

