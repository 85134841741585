<form [formGroup]="passwordForm"
      (ngSubmit)="updatePassword()"
      autocomplete="off"
      novalidate class="">
   <fieldset>
      <legend class="text-uppercase text-nowrap">
         <span *ngIf="isReset">Reset</span><span *ngIf="!isReset">Set</span>
         Password
      </legend>

      <div class="row"
           *ngIf="isReset">  

         <div class="col"
              [ngClass]="{'error': currentPassword.invalid && (currentPassword.dirty || currentPassword.touched || mouseoverPasswordSubmit)}">

            <label for="currentPassword" class="form-label">Current Password</label>
            <em *ngIf="currentPassword.invalid && (currentPassword.dirty || currentPassword.touched || mouseoverPasswordSubmit)" class="error-msg">
               Required
            </em>
            <input formControlName="currentPassword"
                   id="currentPassword"
                   type="password"
                   class="form-control"
                   required />
         </div>

      </div>

      <div class="row">

         <div class="col"
              [ngClass]="{'error': password.invalid && (password.dirty || password.touched || mouseoverPasswordSubmit)}">
            <label for="password" class="form-label">New Password</label>
            <em *ngIf="password.invalid && (password.dirty || password.touched || mouseoverPasswordSubmit)" class="error-msg">
               <span *ngIf="password.errors?.required">
                  Required
               </span>
               <span *ngIf="password.errors?.pattern">
                  {{passwordMessage}}
               </span>
            </em>
            <input formControlName="password"
                   id="password"
                   type="password"
                   class="form-control"
                   required />
         </div>

      </div>

      <div class="row">

         <div class="col"
              [ngClass]="{'error': confirmPassword.invalid && (confirmPassword.dirty || confirmPassword.touched || mouseoverPasswordSubmit) }">
            <label for="confirmPassword" class="form-label">Confirm New Password:</label>
            <em *ngIf="confirmPassword.invalid && (confirmPassword.dirty || confirmPassword.touched || mouseoverPasswordSubmit)" class="error-msg">
               <span *ngIf="confirmPassword.errors?.required">
                  Required
               </span>
               <span *ngIf="!confirmPassword.errors?.required && confirmPassword.errors?.matching">
                  Confirm password must match above
               </span>
            </em>
            <input formControlName="confirmPassword"
                   id="confirmPassword"
                   type="password"
                   class="form-control"
                   required />
         </div>

      </div>

      <div class="row">

         <div class="col text-center">

            <span (mouseenter)="mouseoverPasswordSubmit=true" (mouseleave)="mouseoverPasswordSubmit=false">
               <app-button [buttonConfig]="{textChange: ['Update']}"
                           [isLoading]="isSubmitting"
                           [disabled]="!passwordForm.valid || isSubmitting"
                           (textBtnClickEmt)="updatePassword()"></app-button>
            </span>

         </div>

      </div>

   </fieldset>
</form>
