import { mapToCanActivate, Routes } from '@angular/router'
import {
   LeadSection,
   LeadSections,
   LeadSection_
} from '../shared/services/lookup/lead-section'
import { MemberShellComponent, ResetPasswordShellComponent } from '@appMember/containers'
import { HomeComponent, SiteNotificationComponent } from '@appMember/components'
import { AuthGuardService } from '@appShared/services/auth-guard.service'
import { ACCOUNT_ROUTES } from '@appAccount/account.routes'
import { environment } from '@appEnvironments/environment'

const _homeSection: LeadSection = LeadSections.find(
   section => section.code == LeadSection_.Home
)

const memberRoutes = environment.routes.member

const childAccountRoutes: Routes = [
   {
      path: '',
      component: HomeComponent,
      data: { section: { ..._homeSection, title: memberRoutes.title } }
   },
   {
      path: memberRoutes.siteNotifications.baseUri,
      component: SiteNotificationComponent,
      data: { section: { ..._homeSection, title: memberRoutes.siteNotifications.title } }
   },
   ...ACCOUNT_ROUTES,
   //{ /* for lazy-loading below */
   //   path: memberRoutes.account.baseUri,
   //   loadChildren: () =>
   //      import('../account/account.routes').then(mod => mod.ACCOUNT_ROUTES)
   //},
   {
      path: memberRoutes.setPassword.baseUri,
      component: ResetPasswordShellComponent,
      data: { section: { ..._homeSection, title: memberRoutes.setPassword.title } }
   }
]

export const MEMBER_ROUTES: Routes = [
   {
      path: memberRoutes.baseUri, /* If not lazy-loading memberRoutes.baseUri, else '' */
      component: MemberShellComponent,
      canActivate: mapToCanActivate([AuthGuardService]), /* remove if lazy-loading */
      children: childAccountRoutes
   }
]
