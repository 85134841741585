import { Injectable } from '@angular/core';
import { Router } from '@angular/router'
import { BehaviorSubject, Observable, combineLatest, take } from 'rxjs';
import { RegistrationsService } from '@appShared/services/registrations.service';
import { SubscriptionStatus_ } from '@appShared/services/lookup/[CodeGen]/subscription-status.domain'
import { ConfirmOptions, ConfirmService } from '@appShared/components/confirm-modal-and-service'
import { environment } from '@appEnvironments/environment'

export interface StepModel {
   stepIndex: RegistrationStep_;
   title: string;
   route: string;
   isComplete: boolean;
}

export enum RegistrationStep_ {
   Profile = 0,
   Payment,
   Confirmation
}

export const STEPS: StepModel[] = [
   {
      stepIndex: RegistrationStep_.Profile,
      title: '',
      route: '/registration',
      isComplete: false
   },
   {
      stepIndex: RegistrationStep_.Payment,
      title: ' - Payment',
      route: '/registration/payment',
      isComplete: false
   },
   {
      stepIndex: RegistrationStep_.Confirmation,
      title: ' - Confirmation',
      route: '/registration/confirmation',
      isComplete: false
   }
];

@Injectable({
   providedIn: 'root'
})
export class StepsService {
   steps$: BehaviorSubject<StepModel[]> = new BehaviorSubject<StepModel[]>(
      STEPS
   );
   private _initialStep = STEPS.find(step => step.stepIndex === RegistrationStep_.Profile)
   currentStep$: BehaviorSubject<StepModel> = new BehaviorSubject<StepModel>(
      this._initialStep
   );

   constructor(
      private _registrationsService: RegistrationsService,
      private _confirmService: ConfirmService,
      private _router: Router
   ) {
      this.currentStep$.next(this.steps$.value[0]);
   }

   setCurrentStep(step: StepModel): void {
      this.currentStep$.next(step);
   }

   getCurrentStep(): Observable<StepModel> {
      return this.currentStep$.asObservable();
   }

   getSteps(): Observable<StepModel[]> {
      return this.steps$.asObservable();
   }

   moveToNextStep(): void {

      combineLatest([
         this._registrationsService.registrationSubscription$,
         this.currentStep$
      ])
      .pipe(take(1))
      .subscribe(([subscription, currentStep]) => {
         if (!subscription) {
            this.setCurrentStep(this._initialStep)
            return
         }

         const subscriptionStatus: SubscriptionStatus_ = subscription.statusCode

         if (subscriptionStatus == SubscriptionStatus_.New) {

            if (subscription.info?.hasActiveSubscriptionAuthorization) {
               /* before sending to Confirmation step, verify they
                  didn't have a failed confirmation (due to payment)
                  and need to return to payment section */
               this._registrationsService.lastPaymentFailed$.pipe(
                  take(1)
               ).subscribe(lastPaymentFailed => {
                  let shouldBeRegistrationStep = lastPaymentFailed
                     ? RegistrationStep_.Payment
                     : RegistrationStep_.Confirmation

                  //redirect to proper step
                  if (currentStep.stepIndex !== shouldBeRegistrationStep) {
                     let shouldBeStep = STEPS.find(step => step.stepIndex === shouldBeRegistrationStep)
                     this.setCurrentStep(shouldBeStep)
                  }
               })
            } else {
               //redirect to payment step
               if (currentStep.stepIndex !== RegistrationStep_.Payment) {
                  let paymentStep = STEPS.find(step => step.stepIndex === RegistrationStep_.Payment)
                  this.setCurrentStep(paymentStep)
               }
            }
         } else {
            if ([SubscriptionStatus_.Active, SubscriptionStatus_.OnHold].includes(subscriptionStatus)) {
               this._confirmService
                  .confirm({
                     title: 'Already Registered!',
                     message: 'You have already registered for this subscription.',
                     hideNo: true,
                     yesText: 'OK',
                     backdrop: 'static'
                  } as ConfirmOptions)
                  .then(() => {
                     this._router.navigate([environment.routes.uri])
                  })
                  .catch(() => {
                     /* Do nothing */
                  })
            } else {
               //TODO - what about this situation
               //this._confirmService
               //   .confirm({
               //      title: 'Subscription Cancelled!',
               //      message: `This subscription has been cancelled.
               //                <p class="mt-3 text-error">Feel free to register again</p>`,
               //      hideNo: true,
               //      yesText: 'OK',
               //      backdrop: 'static'
               //   } as ConfirmOptions)
               //   .then(() => {
               //      this._startFreshRegistration()
               //   })
               //   .catch(() => {
               //      /* Do nothing */
               //   })
            }
         }
      })
   }

   isLastStep(): boolean {
      return this.currentStep$.value.stepIndex === this.steps$.value.length;
   }
}
