import { Injectable, inject } from '@angular/core'
import { Router } from '@angular/router'
import { Observable, of } from 'rxjs'
import { AppFacade } from '@appShared/services/app.facade'
import { environment } from '@appEnvironments/environment'

@Injectable({ providedIn: 'root' })
export class PublicOnlyGuardService {
   private _appFacade = inject(AppFacade)
   private _router = inject(Router)

   canActivate(): Observable<boolean> {

      const currentUser = this._appFacade.currentUser()

      // if already logged in - then send to "content" section
      if (currentUser) {
         this._router.navigate([`${environment.routes.content.uri}`])
         return of(false)
      }

      return of(true)
   }
}
