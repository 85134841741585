import { ChangeDetectionStrategy, Component } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { UrlService } from '@appShared/services/url.service'
import { IFramePlayerComponent } from '@appShared/components/iframe-player/iframe-player.component'
import { environment } from '@appEnvironments/environment'

@Component({
    selector: 'app-content-meet-the-mentors',
    template: `
      <div class="d-flex flex-column h-100 w-100 bg-white">
         <div class="fs-2 text-center pt-2 fw-bold text-uppercase mb-3">{{meetTheMentorsTitle}}</div>
         <app-iframe-player [frameSourceInput]="meetTheMentorsUri"></app-iframe-player>
      </div>
   `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [IFramePlayerComponent]
})
export class MeetTheMentorsComponent {
   meetTheMentorsUri: string
   meetTheMentors = environment.routes.content.meetTheMentors
   meetTheMentorsTitle = this.meetTheMentors.title

   constructor(
      /*router: Router*/
      private _urlService: UrlService,
      titleService: Title
   ) {
      titleService.setTitle(`LEAD - ${this.meetTheMentorsTitle}`)

      /* this src for brightcove personalities cannot
         be contained in a player - redirect to
         our iframeplayercomponent and set the src
      */
      const experienceUri = encodeURIComponent(environment.brightcove.experiences.meetTheMentorsUrl)
      if (experienceUri) {
         // if need to direct to the /player page...
         //const queryParams = { queryParams: { src: experienceUri } }
         //router.navigate([`${environment.playerUri}`], queryParams)

         this.meetTheMentorsUri = experienceUri
      }
   }

   ngOnInit() {
      //they don't want header for now since page had a header
      //setTimeout(() => { this._urlService.setHeaderTitle(this.meetTheMentorsTitle) })

      setTimeout(() => { this._urlService.setHeaderTitle('') })
   }
}
