import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core'
import { CommonModule, NgIf } from '@angular/common'
import { FormsModule, NgForm } from '@angular/forms'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { AchievementsService } from '@appShared/services/achievements.service'
import { IAlert } from '@appShared/services/common.service'
import { ConfirmTemplateDirective, ConfirmModalComponent } from '@appShared/components/confirm-modal-and-service'
import { ConfirmOptions, ConfirmService } from '@appShared/components/confirm-modal-and-service'

@Component({
  selector: 'app-redeem-code',
  standalone: true,
   imports: [CommonModule, FormsModule, NgIf, ConfirmTemplateDirective, ConfirmModalComponent],
  templateUrl: './redeem-code.component.html',
  styleUrls: ['./redeem-code.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RedeemCodeComponent {
   private _achievementsService = inject(AchievementsService)
   private _confirmService = inject(ConfirmService)
   alert = signal<IAlert | undefined>(null)
   isSubmitting = signal<boolean>(false)

   constructor(private _activeModal: NgbActiveModal) {}

   redeemCode(form: NgForm) {

      if (!form.valid) {
         this.alert.set({
            message: '** Enter redemption Code',
            alertClass: 'alert-danger'
         })
         return
      }

      const mentorCode = form.value['mentorCode']

      if (mentorCode.length < 3) {
         this.alert.set({
            message: '** Enter full redemption Code',
            alertClass: 'alert-danger'
         })
         return
      }

      this.isSubmitting.set(true)
      this.alert.set({
         message: 'Redeeming...',
         alertClass: 'alert-success'
      })

      this._achievementsService
         .unlockBadge(mentorCode)
         .then(() => {
            this._achievementsService.refreshContactAchievements()

            this._confirmService
               .confirm({
                  message: `<div class="d-flex flex-column align-items-center gap-3">
                              <img src="/assets/images/celebration.png" alt="Redeem Mentor Code Success">
                              <div class="fs-5 fw-bold text-center">Woo! You've earned points<br/>for attending your meeting!</div>
                              <div class="text-center">Your points have been added. Check your points at the top right corner!</div>
                            </div>`,
                  yesText: 'GOT IT!',
                  yesButtonClass: 'btn-site-primary',
                  hideHeader: true,
                  hideNo: true
               } as ConfirmOptions)
               .then(() => {
                  this._activeModal.close()
               })
               .catch(() => {
                  /* Do Nothing */
               })

            this.alert.set({
               message: 'Successfully redeemed!',
               alertClass: 'alert-success'
            })

            setTimeout(() => { this._activeModal.close() }, 500)
         })
         .catch(err => {
            this.alert.set({
               message: `${err}`,
               alertClass: 'alert-danger'
            })
         })
         .finally(() => (this.isSubmitting.set(false)))



   }

}
