<div class="d-flex justify-content-center">

   <div class="area">

      <div class="my-3 mt-0 p-3 pt-2 background-box-opaque-rounded shadow">

         <fieldset class="">
            <legend id="account-profile">Users</legend>

            <app-account-students-list [canEdit]="canEdit"
                                       (profileSelected)="setSelectedProfile($event)"
                                       (profileUpdated)="profileCreatedUpdated($event)"
                                       (profileDeleted)="profileDeleted()"></app-account-students-list>
         </fieldset>


      </div>

      <div class="my-3 mt-4 p-3 pt-2 background-box-opaque-rounded shadow"
           [hidden]="!selectedProfile">

         <app-account-student [canEdit]="canEdit"
                              [profile]="selectedProfile"
                              (profileCreatedUpdated)="profileCreatedUpdated($event)"
                              (cancelProfile)="cancelProfile()"></app-account-student>
         <!--(profileCreatedUpdated)="setSelectedProfile($event)"-->

      </div>

   </div>

</div>
