import { Injectable } from '@angular/core'
import { EMPTY, Observable, catchError, combineLatestWith, of, switchMap, take } from 'rxjs'
import { AppFacade } from '@appShared/services/app.facade'
import { environment } from '@appEnvironments/environment'

@Injectable({ providedIn: 'root' })
export class AppConfigProvider {

   constructor(

      private _appFacade: AppFacade
   ) { }

   init(): Observable<boolean> {

      return this._appFacade.initializeSite().pipe(
         take(1),
         switchMap(() => of(true)),
         catchError((err) => {
            console.log('Error occurred loading site config:', err)
            return of(false)
         })
      )
   }

   private _prepareRouteUris(): Observable<never> {

      /* iterative method to loop over route objects
         to create usuable uris for ease of routing
         and no need to concatenate routes in code
      */

      let setConditionalUri = (parentUri?: any, uri?: any): string => {
         return parentUri
            ? uri
               ? `${parentUri}/${uri}`
               : parentUri
            : uri
               ? uri
               : ''
      }

      let setUrl = (obj: any, parentBaseUri?: any): void => {
         let baseUri = obj.baseUri

         Object.entries(obj)
            .forEach(([key, value]) => {
               if (typeof value === "object") {

                  // build "baseUri" to pass to children objects
                  baseUri = setConditionalUri(parentBaseUri, obj.baseUri)

                  setUrl(value, baseUri)

               } else if (key === 'uri') {

                  // set uri based on parent routes
                  obj[key] = setConditionalUri(parentBaseUri, value)
               }
            })
      }

      let routes = environment.routes

      setUrl(routes)

      return EMPTY
   }

}
