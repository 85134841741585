<div class="p-2 pt-3 p-lg-5 pt-md-4 mb-3 h-100">

   <div class="container">

      <div class="my-3 mt-0 p-3 pt-0 background-box-opaque-rounded shadow">

         <div class="registration-page-container">

            <div class="step-page-container">

               <!--<div [ngClass]="{'d-none':!isLoading}"
                    class="text-center mt-4">
                  <span class="fa fa-spinner spinner-splash"></span>
               </div>-->
               <!-- !! they want this to actually be a first step to login and then redirect to next steps so need to split into different routes -->
               <div [ngClass]="{'d-none':isLoading}"
                    class="fw-bold text-site-secondary text-uppercase fs-2 mt-2 text-center">
                  Create Account
               </div>

               <div [ngClass]="{'d-none':isLoading}" class="registration-step">

                  <fieldset id="account-profile"
                            class="mt-2">
                     <legend class="fs-5 text-center pb-2">
                        Enter the information for the person who will be managing the account
                        <span class="d-block">(student info can be added in a later step)</span>
                        <span class="fs-6 d-flex justify-content-center align-items-center gap-1"
                              (click)="signIn()"> <span>&tilde; or </span><span class="text-decoration-underline fw-bold text-site-primary pointable"> Sign In </span> <span>(if you already have an account) &tilde;</span></span>
                     </legend>

                     <form [formGroup]="profileSubmissionForm"
                           (ngSubmit)="register()"
                           autocomplete="off" novalidate>

                        <div class="row mt-4">

                           <!--<div class="col-md-12 d-lg-none">

                              <div class="mt-3 d-flex h-100 align-items-center">

                                 <div class="pretty p-icon p-smooth">
                                    <input type="checkbox"
                                           #useNicknameInsteadCheckBox
                                           id="useNicknameInsteadCheckBox"
                                           [checked]="useNickname"
                                           (change)="useNicknameInstead(useNicknameInsteadCheckBox.checked)" />
                                    <div class="state p-success">
                                       <i class="icon mdi mdi-check"></i>
                                       <label>Sign-in with Nickname instead</label>
                                    </div>
                                 </div>

                              </div>

                           </div>-->

                           <div class="col-lg-12">

                              <div [ngClass]="{'error': emailAddress.invalid && (emailAddress.dirty || emailAddress.touched || mouseoverProfileSubmissionSubmit) }">
                                 <!--*ngIf="!useNickname": NOT allowing nickname for now-->
                                 <label for="emailAddress" class="form-label">
                                    Email Address (for sign-in)

                                 </label>
                                 <em *ngIf="emailAddress.invalid && (emailAddress.dirty || emailAddress.touched || mouseoverProfileSubmissionSubmit)" class="error-msg">
                                    <span *ngIf="emailAddress.errors?.required">
                                       Required
                                    </span>
                                    <span *ngIf="emailAddress.errors?.pattern">
                                       Enter valid email
                                    </span>
                                 </em>
                                 <input formControlName="emailAddress"
                                        id="emailAddress"
                                        type="text"
                                        class="form-control"
                                        placeholder="Email Address"
                                        required />
                              </div>

                              <!-- NOT allowing nickname for now -->
                              <!--<div [ngClass]="{'error': nickname.invalid && (nickname.dirty || nickname.touched || mouseoverProfileSubmissionSubmit) }"
                                   *ngIf="useNickname">
                                 <label for="nicknameInput" class="form-label">
                                    Nickname (for sign-in)
                                 </label>
                                 <em *ngIf="nickname.invalid && (nickname.dirty || nickname.touched || mouseoverProfileSubmissionSubmit)" class="error-msg">
                                    <span *ngIf="nickname.errors?.required">
                                       Required
                                    </span>
                                    <span *ngIf="nickname.errors?.pattern">
                                       {{nicknameMessage}}
                                    </span>
                                 </em>
                                 <input formControlName="nickname"
                                        id="nicknameInput"
                                        type="text"
                                        maxlength="16"
                                        [textMask]="{mask: nicknameMask, guide: false}"
                                        class="form-control"
                                        placeholder="Nickname"
                                        required />
                              </div>-->

                           </div>
                           <!-- NOT allowing nickname for now -->
                           <!--<div class="col-lg-6">

                              <div class="mt-lg-3 d-flex h-100 align-items-center">

                                 <div class="pretty p-icon p-smooth">
                                    <input type="checkbox"
                                           #useNicknameInsteadCheckBox
                                           id="useNicknameInsteadCheckBox"
                                           [checked]="useNickname"
                                           (change)="useNicknameInstead(useNicknameInsteadCheckBox.checked)" />
                                    <div class="state p-success">
                                       <i class="icon mdi mdi-check"></i>
                                       <label>Sign-in with Nickname instead</label>
                                    </div>
                                 </div>

                              </div>

                           </div>-->

                        </div>

                        <div class="row">

                           <div class="col-lg-6">
                              <div class="form-group"
                                   [ngClass]="{'error': firstName.invalid && (firstName.dirty || firstName.touched || mouseoverProfileSubmissionSubmit)}">
                                 <label for="firstName" class="form-label">Legal First Name</label>
                                 <em *ngIf="firstName.invalid && (firstName.dirty || firstName.touched || mouseoverProfileSubmissionSubmit)" class="error-msg">
                                    Required
                                 </em>
                                 <input formControlName="firstName"
                                        id="firstName"
                                        type="text"
                                        class="form-control"
                                        placeholder="First Name"
                                        required />
                              </div>
                           </div>

                           <div class="col-lg-6">
                              <div class="form-group"
                                   [ngClass]="{'error': lastName.invalid && (lastName.dirty || lastName.touched || mouseoverProfileSubmissionSubmit)}">
                                 <label for="lastName" class="form-label">Last Name</label>
                                 <em *ngIf="lastName.invalid && (lastName.dirty || lastName.touched || mouseoverProfileSubmissionSubmit)" class="error-msg">
                                    Required
                                 </em>
                                 <input formControlName="lastName"
                                        id="lastName"
                                        type="text"
                                        class="form-control"
                                        placeholder="Last Name"
                                        required />
                              </div>
                           </div>

                        </div>

                        <div class="row">

                           <div class="col-lg-6 border-top">

                              <div class="row">

                                 <div class="col"
                                      [ngClass]="{'error': password.invalid && (password.dirty || password.touched || mouseoverProfileSubmissionSubmit)}">
                                    <label for="password" class="form-label">Password</label>
                                    <em *ngIf="password.invalid && (password.dirty || password.touched || mouseoverProfileSubmissionSubmit)" class="error-msg">
                                       <span *ngIf="password.errors?.required">
                                          Required
                                       </span>
                                       <span *ngIf="password.errors?.pattern">
                                          {{passwordMessage}}
                                       </span>
                                    </em>
                                    <input formControlName="password"
                                           id="password"
                                           type="password"
                                           class="form-control"
                                           required />
                                 </div>

                              </div>

                              <div class="row">
                                 <div class="col"
                                      [ngClass]="{'error': confirmPassword.invalid && (confirmPassword.dirty || confirmPassword.touched || mouseoverProfileSubmissionSubmit) }">
                                    <label for="confirmPassword" class="form-label">Confirm Password:</label>
                                    <em *ngIf="confirmPassword.invalid && (confirmPassword.dirty || confirmPassword.touched || mouseoverProfileSubmissionSubmit)" class="error-msg">
                                       <span *ngIf="confirmPassword.errors?.required">
                                          Required
                                       </span>
                                       <span *ngIf="!confirmPassword.errors?.required && confirmPassword.errors?.matching">
                                          Confirm password must match
                                       </span>
                                    </em>
                                    <input formControlName="confirmPassword"
                                           id="confirmPassword"
                                           type="password"
                                           class="form-control"
                                           required />
                                 </div>

                              </div>

                           </div>

                           <div class="col d-flex justify-content-center align-items-center border-top pt-3 pt-lg-0">

                              <span (mouseenter)="mouseoverProfileSubmissionSubmit=true" (mouseleave)="mouseoverProfileSubmissionSubmit=false">
                                 <app-button [buttonConfig]="{textChange: ['Sign Up']}"
                                             [isLoading]="isSubmitting"
                                             [disabled]="isSubmitting"
                                             (textBtnClickEmt)="register()"></app-button>
                                 <!--[disabled]="!profileSubmissionForm.valid || isSubmitting"-->
                              </span>

                           </div>

                        </div>

                     </form>

                  </fieldset>

                  <div *ngIf="errorMessage" class="alert alert-danger mt-4" role="alert">
                     Error Occurred: {{errorMessage}}
                  </div>

               </div>

            </div>

            <!--<button (click)="onNextStep()" [disabled]="!(currentStep | async)?.isComplete" class="registration-continue-btn fs-1 text-uppercase">

            </button>-->


         </div>

      </div>

      <!--<pre>{{(currentSubscription$|async)|json}}</pre>-->
   </div>
</div>
