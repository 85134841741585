<div *ngIf="!iframeSrc"
     class="d-flex w-100 h-100 justify-content-center align-items-center">
   <div class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
   </div>
</div>
<div class="position-relative w-100 h-100">
   <iframe #iframe (load)="onIFrameLoad(iframe)" id="leadIframe" style="height:100%; width: 100%; border: 0;" frameborder="0"></iframe>
   <div *ngIf="token && iframeSrc"
        (click)="exitCourse()"
        class="position-absolute top-0 end-0 btn btn-primary mt-2 me-2 exit-button fw-bold">EXIT COURSE</div>
</div>
<!--<ng-template app-confirm-simple>
   <app-confirm-modal-component></app-confirm-modal-component>
</ng-template>-->
<ng-template #modaltemplate>
   <div class="modal-header">
      <h4 class="modal-title pull-left">Exiting Course</h4>
   </div>
   <div class="modal-body">
      <span class="pe-3">One moment while we exit the course</span>
      <div class="spinner-border spinner-border-sm" role="status">
         <span class="visually-hidden">Loading...</span>
      </div>
   </div>
</ng-template>
