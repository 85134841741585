import { ChangeDetectionStrategy, Component, inject, TrackByFunction } from '@angular/core'
import { NgIf, NgFor, NgClass, CurrencyPipe, DatePipe } from '@angular/common'
import { tap } from 'rxjs'
import { toSignal } from '@angular/core/rxjs-interop'
import { AccountsService } from '@appShared/services/accounts.service'
import { ISubscriptionPaymentTransaction } from '@appShared/interfaces/[Model-based]/subscription-payment-transaction.interface'
import { PaymentInstrumentDisplayComponent } from '@appAccount/components/payment-instrument-display/payment-instrument-display.component'


@Component({
    selector: 'app-account-billing-history',
    templateUrl: './history.component.html',
    styleUrls: ['./history.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, NgFor, PaymentInstrumentDisplayComponent, NgClass, CurrencyPipe, DatePipe]
})
export class AccountBillingHistoryComponent {
   isLoading = true
   subscriptionPaymentTransactions = toSignal(inject(AccountsService).subscriptionPaymentTransactions$()
      .pipe(tap(() => this.isLoading = false)))

   trackByPaymentTransactionId: TrackByFunction<ISubscriptionPaymentTransaction> = (index, transaction) => transaction.id
}
