import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalComponent } from '@appShared/components/modal/modal.component'
import { RedeemCodeComponent } from './redeem-code.component';

@Component({
   template: `
    <app-modal title="Redeem Code" titleContainerName="redeem-code" [showFooter]="false">
      <app-redeem-code></app-redeem-code>
    </app-modal>`,
   standalone: true,
   imports: [ModalComponent, RedeemCodeComponent],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class RedeemCodeModalComponent {

}



