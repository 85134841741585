<section class="bg-site-secondary shadow opacity-75 site-footer">
   <div class="container d-flex align-items-center justify-content-center justify-content-md-between px-md-4"
        [hidden]="hideFooter">
      <span class="ms-2 text-white copy">Copyright &copy; {{currentYear}} - Southwestern Lead</span>

      <ul class="nav me-2">
         <li class="nav-item">
            <a href="/assets/communication/LEADGlobalAcademyTermsAndConditionsSWA.pdf"
               class="nav-link text-white"
               target="_blank">Terms of Use</a>
         </li>
      </ul>
   </div>
</section>
