import { ChangeDetectionStrategy, Component, inject, ViewChild } from '@angular/core'
import { AccountsService, AppFacade, CommonService, UrlService } from '@appShared/services'
import { IProfile } from '@appShared/interfaces/[Model-based]/profile.interface'
import { AccountStudentsListComponent } from '@appAccount/components/students/students-list/students-list.component'
import { AccountStudentComponent } from '@appAccount/components/students/student/student.component';
import { environment } from '@appEnvironments/environment'

@Component({
    selector: 'app-account-students',
    templateUrl: './students.component.html',
    styles: ['.area { flex: 0 1 800px }'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [AccountStudentsListComponent, AccountStudentComponent]
})
export class AccountStudentsComponent {
   @ViewChild(AccountStudentsListComponent)
   accountStudentListcompoment: AccountStudentsListComponent

   private _commonService = inject(CommonService)
   private _accountsService = inject(AccountsService)
   private _currentUser = inject(AppFacade).currentUser

   canEdit: boolean
   selectedProfile: IProfile

   constructor(
      urlService: UrlService
   ) {
      urlService.setHeaderTitle(environment.routes.member.account.users.title)
   }

   /*
    * events
    * */

   /*
    * private methods
    * */

   private _loadProfiles() {
      this._accountsService.getProfiles(/*includeAccountOwner*/false, this._currentUser())
   }

   /*
   * public methods
   * */

   setSelectedProfile(profile: IProfile): void {
      this.selectedProfile = profile ? { ...profile } : null

      //const anchor = profile ? 'account-profile' : 'account-profile-edit';

      setTimeout(() => this._commonService.scrollToModalAnchor('account-profile'), 0)
   }

   //TODO - not sure if Leo is going to pass me back a profile or not
   profileCreatedUpdated(profile: IProfile): void {
      this._loadProfiles()
      this.setSelectedProfile(null)
   }

   profileDeleted() {
      this._loadProfiles()
      this.setSelectedProfile(null)
   }

   cancelProfile(): void {
      this.selectedProfile = null
      this.setSelectedProfile(null)
      this.accountStudentListcompoment.clearForm()
   }
}
