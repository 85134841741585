<ng-container *ngIf="isLoaded && modules.length">

   <div class="modules-wrapper">

      <div>

         <div class="d-flex align-items-stretch flex-wrap modules">

            <div *ngFor="let module of modules; trackBy: trackByModuleId"
                 (click)="goToRoute(module)"
                 class="module"
                 [ngClass]="{'filler-card': !module.id, pointable: module.id}">

               <div class="position-relative w-100">
                  <img [src]="module.iconImageUri" class="w-100" />
                  <!-- top/start/bottom/end 0 in case we want a color overlay for whole image -->
                  <div class="position-absolute top-0 start-0 bottom-0 end-0 position-relative">
                     <div *ngIf="module.statusImageUri"
                          class="course-status position-absolute">
                        <img [src]="module.statusImageUri" class="w-100" />
                     </div>
                  </div>
               </div>

            </div>

         </div>

      </div>

   </div>

</ng-container>

<div *ngIf="isLoaded && !modules.length" class="fs-1 mt-5 text-center">
   * Content Coming Soon *
</div>
