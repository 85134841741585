import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { CommonService } from './common.service';
import { IProfile } from '../interfaces/[Model-based]/profile.interface';
import { IContactAddress } from '../interfaces/[Model-based]/contact-address.interface';
import { StateProvince_ } from './lookup/[CodeGen]/state-province.domain';
import * as _ from 'lodash';

@Injectable({ providedIn: 'root' })
export class ProfileService {
   /* https://blogs.msmvps.com/deborahk/build-a-simple-angular-service-to-share-data/ */
   currentProfile: IProfile;
   private profileApi: string = '/api/profile/';

   constructor(private _http: HttpClient, private common: CommonService) {}

   async getBillingAddress(contactId: number): Promise<IContactAddress> {
      const url = `${this.profileApi}/contact/billing-address/${contactId}`;

      //return await this._http.get<IContactAddress>(url).toPromise()

      return await new Promise(resolve => {
         resolve({
            contactId: contactId,
            line1: '2451 Atrium Way',
            city: 'Nashville',
            stateProvinceCode: StateProvince_.TN_US,
            postalCode: '37167'
         } as IContactAddress);
      });
   }

   //getBillingAddress(
   //   contactId: number
   //): Observable<IContactAddress> {
   //   const url = `${this.profileApi}/contact/billing-address/${contactId}`

   //   return this.http
   //      .get<IContactAddress>(url)
   //      .pipe(
   //         tap(data => this.common.logApi(url, data)),
   //         catchError(
   //            this.common.handleError<IContactAddress>('getBillingAddress')
   //         )
   //      )
   //}
   //async createUpdateShareholder(
   //   shareholder: IShareholder
   //): Promise<IShareholder> {
   //   if (!shareholder) {
   //      return new Promise((resolve, reject) => reject('No Shareholder passed!'))
   //   }

   //   const url = shareholder.id
   //      ? `${this._shareholderAdminApi}/${shareholder.id}`
   //      : this._shareholderAdminApi
   //   const options = this._commonService.httpOptions()

   //   if (shareholder.id) {
   //      /* update */
   //      return await this._http
   //         .put<IShareholder>(url, shareholder, options)
   //         .toPromise()
   //   }

   //   /* else create */
   //   return await this._http
   //      .post<IShareholder>(url, shareholder, options)
   //      .toPromise()

   //   // TODO return await this._http.post<IShareholder>(url, shareholder, options).toPromise();
   //   // const newShareHolder = {
   //   //  ...shareholder,
   //   //  id: 987654
   //   // };
   //   // // this.fakeData.push(newShareHolder);
   //   // return await new Promise(resolve => {
   //   //  resolve({ ...newShareHolder } as IShareholder);
   //   // });
   //}
}
