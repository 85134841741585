import { Injectable } from '@angular/core'
import { Observable, ReplaySubject } from 'rxjs'
import { CommonService } from './common.service'
import * as _ from 'lodash'


export enum SiteNotificationType_ {
   AccountOwnerIssueWithAccount = 1,
   Information = 2,
   NonAccountOwnerIssueWithAccount = 3
}

export interface SiteNotification {
   typeCode: SiteNotificationType_,
   notification: string
}


@Injectable({
   providedIn: 'root'
})
export class NotificationsService {

   private _currentNotification: ReplaySubject<SiteNotification> = new ReplaySubject<
      SiteNotification
   >(null)
   public get currentNotification$(): Observable<SiteNotification> {
      return this._currentNotification.asObservable()
   }

   constructor(
      private _commonService: CommonService
   ) { }

   /*
    * private methods
    */

   /*
    * public methods
    */

   setCurrentNotification(siteNotification?: SiteNotification | SiteNotificationType_): void {

      if (!siteNotification) {
         const date = new Date()

         siteNotification = {
            typeCode: SiteNotificationType_.Information,
            notification: `Notification Timestamp: ${date.toDateString()} - ${date.getTime()}`
         } as SiteNotification

      } else if (!_.isObject(siteNotification)) {
         siteNotification = this.getCurrentNotificationByType(siteNotification)
      }

      if (siteNotification) this._currentNotification.next(siteNotification)
   }

   getCurrentNotificationByType(notificationType: SiteNotificationType_): SiteNotification {
      let siteNotification: SiteNotification

      if (notificationType) {

         switch (notificationType) {
            case SiteNotificationType_.NonAccountOwnerIssueWithAccount:
               return {
                  typeCode: SiteNotificationType_.AccountOwnerIssueWithAccount,
                  notification: `<h3>Account Issue</h3>
                                 <p>Please have the account owner login and address the issue with their account</p>
                                 <p>Thank You</p>`
               } as SiteNotification
         }
      }

      return siteNotification
   }
}
