<div class="col mx-auto">

   <div class="mt-0 p-3 pt-2 background-box-opaque-rounded shadow">

      <fieldset>
         <legend>{{progressTitle}}</legend>

         <div class="mt-0 p-3 pt-2 background-box-opaque-rounded shadow">

            <!--<h3 *ngIf="selectedProfile">
      Levels Achieved /
      <span *ngIf="badgesLevel">level {{badgesLevel}}</span>
      <span *ngIf="!badgesLevel">none</span>
   </h3>-->

            <p>
               Advance levels by completing module quizzes. Unlock new
               member opportunities after level 12.
            </p>

            <div *ngIf="currentUser()?.profile?.info?.isAccountOwner">Account Owners can use this tab to view their progress as well as progress of any Users they have added</div>

            <!--<div *ngIf="!currentUser()?.profile?.info?.isAccountOwner">Select a badge below to see Certificates and Analytics</div>-->

         </div>

         <div class="mt-3 d-flex lower-content">

            <div class="mx-auto mb-3">

               <app-account-progress-student-list
                  (profileSelected)="setSelectedProfile($event)"></app-account-progress-student-list>

            </div>

            <div class="container text-center">

               <app-account-progress-student-badges
                  [profile]="selectedProfile"></app-account-progress-student-badges>
               <!--(badgesLevel) ="setBadgesLevel($event)"-->
            </div>

         </div>

      </fieldset>

   </div>

</div>
