import { ChangeDetectionStrategy, Component, DestroyRef, inject } from '@angular/core'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { Event, NavigationEnd, Router } from '@angular/router'
import { environment } from '@appEnvironments/environment'

@Component({
    selector: 'app-site-footer',
    templateUrl: './site-footer.component.html',
    styleUrls: ['./site-footer.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true
})

export class SiteFooterComponent {
   currentYear = (new Date()).getFullYear()
   private _router = inject(Router)
   private _registrationLandingUri = environment.routes.registrationLanding.baseUri
   hideFooter = this._router.url.includes(this._registrationLandingUri)

   constructor(
      destroyRef: DestroyRef
   ) {

      this._router.events
         .pipe(takeUntilDestroyed(destroyRef))
         .subscribe((event: Event) => {
            if (event instanceof NavigationEnd) {
               const url = event?.url
               this.hideFooter = url.includes(this._registrationLandingUri)
            }
         })
   }
}
