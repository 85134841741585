import { IDomainModel } from './[CodeGen]/domain-model.interface';

export enum SiteType_ {
   Advantage4Kids = 1,
   Advantage4Teens = 2,
   Lead = 3,
   Skwids = 4
}

export const SiteTypes: SiteType[] = [
   {
      code: SiteType_.Advantage4Kids,
      literal: 'Adv4Kids',
      description: 'Advantage4Kids',
      shortDescription: 'Adv4Kids',
      listPriority: 0,

      typeName: 'SiteType'
   },
   {
      code: SiteType_.Advantage4Teens,
      literal: 'Adv4Teens',
      description: 'Advantage4Teens',
      shortDescription: 'Adv4Teens',
      listPriority: 0,

      typeName: 'SiteType'
   },
   {
      code: SiteType_.Lead,
      literal: 'Lead',
      description: 'Lead',
      shortDescription: 'Lead',
      listPriority: 0,

      typeName: 'SiteType'
   },
   {
      code: SiteType_.Skwids,
      literal: 'Skwids',
      description: 'Skwids',
      shortDescription: 'Skwids',
      listPriority: 0,

      typeName: 'SiteType'
   }
];

export class SiteType implements IDomainModel {
   code: SiteType_;
   literal: string;
   description: string;
   shortDescription: string;
   listPriority: number;

   typeName: string;

   static toDomainModel(code: SiteType_): SiteType {
      return SiteTypes.find(d => d.code === code);
   }
}
