import { ChangeDetectionStrategy, Component, DestroyRef, inject, Input } from '@angular/core'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { Event, NavigationEnd, Router, RouterLink } from '@angular/router'
import { environment } from '@appEnvironments/environment'

@Component({
   selector: 'app-public-header',
   templateUrl: './public-header.component.html',
   styleUrls: ['./public-header.component.less'],
   changeDetection: ChangeDetectionStrategy.OnPush,
   standalone: true,
   imports: [RouterLink]
})
export class PublicHeaderComponent {
   @Input() title: string
   private _router = inject(Router)
   private _currentUrl = this._router.url
   private _registrationLandingUri = environment.routes.registrationLanding.baseUri
   private _signUpUri = environment.routes.signUp.baseUri
   hideHeader = this._currentUrl.includes(this._registrationLandingUri)
   hideSignIn = this._currentUrl.includes(this._signUpUri)

   constructor(
      destroyRef: DestroyRef
   ) {
      this._router.events
         .pipe(takeUntilDestroyed(destroyRef))
         .subscribe((event: Event) => {
            if (event instanceof NavigationEnd) {
               const url = event?.url
               this.hideHeader = url.includes(this._registrationLandingUri)
               this.hideSignIn = url.includes(this._signUpUri)
            }
         })
   }

   signIn() {
      this._router.navigate([`${environment.routes.content.uri}`])
   }
}
