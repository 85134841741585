<div class="lead-section-{{leadSection?.code}} shadow">
   <header class="container d-flex flex-wrap align-items-center justify-content-between py-3 px-4">

      <div *ngIf="user()"
           class="d-flex">
         <div class="dropdown flex-grow-1">
            <a href="#" class="d-block text-decoration-none dropdown-toggle" id="dropdownUserMenu" data-bs-toggle="dropdown" aria-expanded="false">
               <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-person-circle" viewBox="0 0 16 16">
                  <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                  <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z" />
               </svg>
               <span class="ps-2 fw-bold d-none d-sm-inline-block">{{user().displayName}}</span>
            </a>
            <ul class="dropdown-menu dropdown-menu-end text-small" aria-labelledby="dropdownUserMenu">
               <li>
                  <a *ngIf="user().isUser"
                     class="dropdown-item fw-bold" routerLink="/{{routes.content.uri}}">Home</a>
                  <a *ngIf="!user().isUser"
                     class="dropdown-item fw-bold" routerLink="/{{routes.member.uri}}">Home</a>
               </li>
               <li *ngIf="user().isUser">
                  <a class="dropdown-item fw-bold" routerLink="/{{routes.content.videoLibrary.uri}}">{{routes.content.videoLibrary.title}}</a>
               </li>
               <li *ngIf="user().isUser">
                  <a class="dropdown-item fw-bold" routerLink="/{{routes.content.courseLibrary.uri}}">{{routes.content.courseLibrary.title}}</a>
               </li>
               <li *ngIf="user().isUser">
                  <a class="dropdown-item fw-bold" routerLink="/{{routes.content.progress.uri}}">{{routes.content.progress.title}}</a>
               </li>
               <li *ngIf="user().isUser">
                  <a class="dropdown-item fw-bold" routerLink="/{{routes.content.subMenu.uri}}">{{routes.content.subMenu.title}}</a>
               </li>
               <li *ngIf="user().profile.info.isAccountOwner">
                  <a class="dropdown-item fw-bold" routerLink="/{{routes.member.account.uri}}">{{routes.member.account.title}}</a>
                  <!--<span *ngIf="!user().profile.info.isAccountOwner"
               (click)="comingSoon('Awards')"
               class="dropdown-item" >Awards</span>-->
               </li>
               <li><hr class="dropdown-divider"></li>
               <li><span class="dropdown-item pointable" (click)="logout()">Sign out</span></li>
            </ul>
         </div>
      </div>

      <a class="d-flex align-items-center mb-md-0 d-none d-md-inline-block"
         routerLink="/{{user()?.isUser ? routes.content.uri : 'member'}}">
         <!--TODO route-->
         <img src="/assets/images/southwesternlead-brand_small.png" class="logo" />
      </a>

      <a class="d-flex align-items-center mb-md-0 d-inline-block d-md-none"
         routerLink="/{{user()?.isUser ? routes.content.uri : 'member'}}">
         <!--TODO route-->
         <img src="/assets/images/LEAD-logo-blue-medium.png" class="logo-small" />
      </a>
      <!--<form class="d-flex align-items-center col-md-3 mb-2 mb-md-0 d-none d-sm-block">
      <input type="search" class="form-control" placeholder="Search..." aria-label="Search">
   </form>-->
      <!--<div *ngIf="!isDotNetInteroperable()"
        class="title nav col-12 col-md-auto mb-2 justify-content-center mb-md-0 fw-bold text-uppercase">
      {{headerTitle() || leadSection?.title}}
   </div>-->

      <div class="fw-bold" *ngIf="!isRegistrationContext">
         <span class="fs-4">{{contactPartitionProgress()?.overallPointsEarned || 0}}</span> points
      </div>


   </header>
</div>
