import { AfterViewInit, Directive, ElementRef, Inject } from '@angular/core'
import { DOCUMENT } from '@angular/common'
import { environment } from '@appEnvironments/environment'

/*
   EXAMPLE USAGE:
   <div brightcove-experience-player
      [attr.data-experience-id]="experienceId"
      [attr.data-user-id]="brightcoveClientUserId">

   </div>
*/
@Directive({
    selector: '[brightcove-experience-player]',
    standalone: true,
})
export class BrightcoveExperiencePlayerDirective implements AfterViewInit {
   private _experience: any
   private _bcovApi: any
   private _experienceApi: any
   private _experienceId: string
   private _connectivityAttempts = 0
   private _userId: string
   constructor(
      private el: ElementRef,
      @Inject(Window) private _window: Window,
      @Inject(DOCUMENT) private _document: Document) {
   }

   ngAfterViewInit() {
      this._createPlayer()
   }

   private _createPlayer() {
      const templateEl = this.el.nativeElement as HTMLElement

      this._experienceId = templateEl.getAttribute('data-experience-id') || environment.brightcove.experiences.dashboard
      this._userId = templateEl.getAttribute('data-user-id')

      const brightcoveSettings = environment.brightcove
      const brightcoveScriptSrc = `https://players.brightcove.net/${brightcoveSettings.accountId}/experience_${this._experienceId}/live.js`

      let experienceDiv = this._document.createElement("div")
      experienceDiv.setAttribute('data-experience', this._experienceId)
      this.el.nativeElement.appendChild(experienceDiv)

      const script = this._document.createElement('script') as HTMLScriptElement
      const scriptContent = this._document.createTextNode(templateEl.textContent)
      script.src = brightcoveScriptSrc
      script.appendChild(scriptContent)

      // add the new script element to the host div so the browser will execute it
      this.el.nativeElement.appendChild(script)

      console.log(`************ bcov experience player ************`)
      console.group()

      this._getExperience()
   }

   private _getExperience() {

      if (this._connectivityAttempts === 10) {
         console.log(`- Unable to connect after ${this._connectivityAttempts} attempts - discontinuing`)
         console.groupEnd()
         console.log(`************************************************`)
         return
      }

      const bcov = window.top['bcov']

      if (bcov) {
         console.log(`- creating experience (${this._experienceId})`)
         this._experience = bcov.gal.getEmbed(this._experienceId)

         if (this._experience) {
            this._bcovApi = this._experience.api
            this._experienceApi = this._experience.clientApi

            console.log('- bcov api available on client')

            if (this._userId) {
               let playerConnectivityAttempts = 0

               const setUser = () => {
                  if (playerConnectivityAttempts === 10) {
                     console.log(`- Unable to connect to player after ${playerConnectivityAttempts} attempts - discontinuing`)
                     console.groupEnd()
                     console.log(`************************************************`)
                     return
                  }

                  if (this._bcovApi.player) {
                     console.log('- bcov api player available on client')
                     this._bcovApi.player.bcAnalytics.client.user(this._userId)
                     console.log(`- bcov api set user (${this._userId})`)

                     console.groupEnd()
                     console.log(`************************************************`)
                  } else {
                     console.log('- bcov api player not ready yet - setTimeout')
                     setTimeout(() => { playerConnectivityAttempts++;  setUser() }, 1000)
                  }
               }

               setUser()

            } else {
               console.groupEnd()
               console.log(`************************************************`)
            }

         } else {
            console.log('- bcov experience doesn\'t exist yet - setTimeout')
            this._connectivityAttempts++;
            setTimeout(this._getExperience.bind(this), 1000)
         }

      } else {
         console.log('- bcov doesn\'t exist yet - setTimeout')
         this._connectivityAttempts++;
         setTimeout(this._getExperience.bind(this), 1000)
      }
      //if (experience) {
      //   experienceApi = experience.clientApi
      //   // get initial video
      //   experienceApi.once('videoLoaded', function () {
      //      video = experienceApi.getCurrentVideo()
      //      current_video.textContent = video.name
      //   })

      //   // event listeners
      //   experienceApi.on('videoChanged', function () {
      //      video = experienceApi.getCurrentVideo()
      //      current_video.textContent = video.name
      //   })

      //   experienceApi.on('videoStarted', function () {
      //      video_paused.textContent = 'false'
      //   })

      //   experienceApi.on('videoPaused', function () {
      //      video_paused.textContent = 'true'
      //   })

      //} else {
      //   t = window.setTimeout(getExperience, 1000)
      //}
   }
}
