import { ChangeDetectionStrategy, Component, DestroyRef, inject, Input, OnChanges, SimpleChanges } from '@angular/core'
import { NgIf, NgFor, NgClass, AsyncPipe } from '@angular/common'
import { RouterLink } from '@angular/router'
import { Observable, of } from 'rxjs'
import { tap } from 'rxjs/operators'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { AccountsService } from '@appShared/services/accounts.service'
import { IProfile } from '@appShared/interfaces/[Model-based]/profile.interface'
import { IProgressBadge } from '@appShared/interfaces/[Model-based]/progress-badge.interface'
import { environment } from '@appEnvironments/environment'
import * as _ from 'lodash'

@Component({
    selector: 'app-account-progress-student-badges',
    templateUrl: './student-badges.component.html',
    styleUrls: ['./student-badges.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, NgFor, RouterLink, NgClass, AsyncPipe]
})
export class AccountProgressStudentBadgesComponent implements OnChanges {
   @Input() profile: IProfile

   profileBadges$: Observable<IProgressBadge[]> = of([])
   badgesLevel: number
   contentRootUri = environment.routes.content.uri

   private _destroyRef = inject(DestroyRef)

   constructor(
      private _accountsService: AccountsService
   ) {

   }

   ngOnChanges(changes: SimpleChanges): void {
      if (changes?.['profile']?.previousValue?.id != changes?.['profile']?.currentValue?.id) {
         this._getStudentBadges()
      }
   }

   /*
    * private methods
    * */

   _getStudentBadges() {
      if (this.profile) {
         this.profileBadges$ =
            this._accountsService.getProfileBadges(this.profile.id)
            .pipe(
                  takeUntilDestroyed(this._destroyRef),
                  //map(badges => _.sortBy(badges, (badge) => !badge.isMastered)),
                  tap(badges => this.badgesLevel = badges.filter(badge => badge.isMastered).length)
               )
      }
   }

   /*
    * public methods
    * */
}
