import { IDomainModel } from './[CodeGen]/domain-model.interface';

export enum LeadSection_ {
   Home = 0,
   LifeLeadership,
   Education,
   Accountability,
   DifferenceMaking
}

export const LeadSections: LeadSection[] = [
   {
      code: LeadSection_.Home,
      literal: 'home',
      description: 'Select a Path',
      shortDescription: 'Home',
      title: '',/*Select a Path*/
      listPriority: 0,

      typeName: 'LeadModule'
   },
   {
      code: LeadSection_.LifeLeadership,
      literal: 'life-lead',
      description: 'Life & Leadership',
      shortDescription: 'L&L',
      title: 'Personal Leadership',
      listPriority: 1,

      typeName: 'LeadModule'
   },
   {
      code: LeadSection_.Education,
      literal: 'edu',
      description: 'Education',
      shortDescription: 'Edu',
      title: 'Education',
      listPriority: 2,

      typeName: 'LeadModule'
   },
   {
      code: LeadSection_.Accountability,
      literal: 'acc',
      description: 'Accountability',
      shortDescription: 'Acct',
      title: 'Accountability',
      listPriority: 3,

      typeName: 'LeadModule'
   },
   {
      code: LeadSection_.DifferenceMaking,
      literal: 'diff',
      description: 'Difference-Making',
      shortDescription: 'DiffMak',
      title: 'Difference-Making',
      listPriority: 4,

      typeName: 'LeadModule'
   }
];

export class LeadSection implements IDomainModel {
   code: LeadSection_;
   literal: string;
   description: string;
   shortDescription: string;
   title: string;
   listPriority: number;

   typeName: string;

   static toDomainModel(code: LeadSection_): LeadSection {
      return LeadSections.find(d => d.code === code);
   }
}
