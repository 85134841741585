<form [formGroup]="paymentInstrumentForm"
      id="paymentInstrumentForm"
      (ngSubmit)="updatePaymentAuth()"
      autocomplete="off"
      novalidate class="">

   <div class="row">
      <div class="col">

         <div class="row ms-2" *ngIf="!addNewPaymentAuthorization">
            <div class="col">
               <button (click)="addNewPayment(true)"
                       type="button"
                       class="btn btn-site-secondary">
                  Edit Payment
               </button>
            </div>
         </div>

         <fieldset *ngIf="addNewPaymentAuthorization"
                   class="mt-4">
            <legend>New Payment Method</legend>

            <div class="row">

               <div class="col">

                  <!-- Payment Type -->
                  <div class="form-group mt-2">
                     <label for="{{paymentInstrumentTypeSelectFieldName}}">Payment Type</label>
                     <em *ngIf="!paymentInstrumentTypeSelect && mouseoverPaymentAuthSubmit"
                         class="error-msg float-none">
                        Required
                     </em>
                     <div class="well" style="margin: 5px 10px;">
                        <span class="d-inline-block" *ngFor="let paymentInstrumentType of paymentInstrumentTypes">
                           <span class="d-block pretty p-default p-round">
                              <input type="radio"
                                     #inputPaymentInstrumentType
                                     [formControlName]="paymentInstrumentTypeSelectFieldName"
                                     [value]="paymentInstrumentType.code"
                                     (change)="setPaymentInstrumentType(paymentInstrumentType)" /><!--paymentInstrumentTypeSelect = paymentInstrumentType-->
                              <span class="d-block state p-success-o">
                                 <label>{{paymentInstrumentType.description}}</label>
                              </span>
                           </span>
                        </span>
                     </div>
                  </div>

               </div>

            </div>

            <div [hidden]="!paymentInstrument">

               <div class="row">

                  <div class="col-lg-6">

                     <fieldset class="mt-3">
                        <legend>
                           <span *ngIf="paymentInstrument?.typeCode === paymentInstrumentType.PaymentCard">Payment Card</span>
                           <span *ngIf="paymentInstrument?.typeCode === paymentInstrumentType.EftRecord">Bank</span> Information
                        </legend>

                        <!-- Payment Card -->
                        <div *ngIf="paymentInstrument?.typeCode === paymentInstrumentType.PaymentCard">
                           <div class="row">
                              <div class="col">

                                 <div [ngClass]="{'error': pan.invalid && (pan.dirty || pan.touched || mouseoverPaymentAuthSubmit) }">
                                    <label for="pan" class="form-label">Credit Card</label><img *ngIf="pan.valid" [src]="ccImgUrl" class="cc-img" />
                                    <em *ngIf="pan.invalid && (pan.dirty || pan.touched || mouseoverPaymentAuthSubmit)" class="error-msg">
                                       Enter a valid CC number
                                    </em>
                                    <input formControlName="pan"
                                           id="pan"
                                           type="tel"
                                           autocomplete="cc-number"
                                           class="form-control"
                                           placeholder="CC #"
                                           maxlength="23"
                                           required
                                           ccNumber />
                                 </div>

                              </div>
                           </div>

                           <div class="row">
                              <div class="col-sm-4">
                                 <div [ngClass]="{'error': cvv2.invalid && (cvv2.dirty || cvv2.touched || mouseoverPaymentAuthSubmit) }">
                                    <label for="cvv2" class="form-label">CVV</label><i class="far fa-question-circle ps-2 question" data-bs-toggle="modal" data-bs-target="#cvvModal"></i>
                                    <em *ngIf="cvv2.invalid && (cvv2.dirty || cvv2.touched || mouseoverPaymentAuthSubmit)" class="error-msg">
                                       Invalid
                                    </em>
                                    <input formControlName="cvv2"
                                           id="cvv2"
                                           type="tel"
                                           class="form-control"
                                           placeholder="CVV number..."
                                           autocomplete="off"
                                           maxlength="4"
                                           ccCVC
                                           required />
                                 </div>

                              </div>

                              <div class="col-sm-8">

                                 <div [ngClass]="{'error': expiration.invalid && (expiration.dirty || expiration.touched || mouseoverPaymentAuthSubmit) }">
                                    <label for="expiration" class="form-label">CC Exp</label>
                                    <em *ngIf="expiration.invalid && (expiration.dirty || expiration.touched || mouseoverPaymentAuthSubmit)" class="error-msg">
                                       <span *ngIf="expiration.errors.required">
                                          Required
                                       </span>
                                       <span *ngIf="!expiration.errors.required && expiration.errors.expDate">
                                          Invalid Expiriation (MM/YY)
                                       </span>
                                    </em>
                                    <input formControlName="expiration"
                                           id="expiration"
                                           type="tel"
                                           autocomplete="cc-exp"
                                           ccExp
                                           class="form-control"
                                           placeholder="MM/YY"
                                           maxlength="7"
                                           required />
                                 </div>
                                 <!--[textMask]="{mask: creditCardExpMask}" OR [specialCharacters]="[ '/' ]" mask="00/00"-->
                              </div>

                           </div>

                           <div class="row">
                              <div class="col">
                                 <!--@Html.TextBoxFor(m => m.CardModel.NameOnCard, new { maxlength = "50" })-->
                                 <div [ngClass]="{'error': cardholderName.invalid && (cardholderName.dirty || cardholderName.touched || mouseoverPaymentAuthSubmit)}">
                                    <label for="cardholderName" class="form-label">Name On Card</label>
                                    <em *ngIf="cardholderName.invalid && (cardholderName.dirty || cardholderName.touched || mouseoverPaymentAuthSubmit)" class="error-msg">
                                       Required
                                    </em>
                                    <input formControlName="cardholderName"
                                           id="cardholderName"
                                           type="text"
                                           class="form-control"
                                           maxlength="30"
                                           required />
                                 </div>
                              </div>
                           </div>

                        </div>

                        <!-- Eft Record -->
                        <div *ngIf="paymentInstrument?.typeCode === paymentInstrumentType.EftRecord">

                           <div class="row">

                              <div class="col">

                                 <div [ngClass]="{'error': routingNumber.invalid && (routingNumber.dirty || routingNumber.touched || mouseoverPaymentAuthSubmit) }">
                                    <label for="routingNumber" class="form-label">Routing Number</label>
                                    <em *ngIf="routingNumber.invalid && (routingNumber.dirty || routingNumber.touched || mouseoverPaymentAuthSubmit)" class="error-msg">
                                       Enter a valid Routing number
                                    </em>
                                    <input formControlName="routingNumber"
                                           id="routingNumber"
                                           type="text"
                                           mask="999999999"
                                           class="form-control"
                                           required />
                                 </div>

                              </div>

                           </div>

                           <div class="row">

                              <div class="col">

                                 <div [ngClass]="{'error': accountNumber.invalid && (accountNumber.dirty || accountNumber.touched || mouseoverPaymentAuthSubmit) }">
                                    <label for="accountNumber" class="form-label">Account Number</label>
                                    <em *ngIf="accountNumber.invalid && (accountNumber.dirty || accountNumber.touched || mouseoverPaymentAuthSubmit)" class="error-msg">
                                       Enter a valid Account Number
                                    </em>
                                    <input formControlName="accountNumber"
                                           id="accountNumber"
                                           type="text"
                                           mask="999999900000000"
                                           class="form-control"
                                           required />
                                 </div>

                              </div>

                           </div>

                           <div class="row">

                              <div class="col">

                                 <div [ngClass]="{'error': accountHolderName.invalid && (accountHolderName.dirty || accountHolderName.touched || mouseoverPaymentAuthSubmit) }">
                                    <label for="accountHolderName" class="form-label">Account Name</label>
                                    <em *ngIf="accountHolderName.invalid && (accountHolderName.dirty || accountHolderName.touched || mouseoverPaymentAuthSubmit)" class="error-msg">
                                       Enter an Account Name
                                    </em>
                                    <input formControlName="accountHolderName"
                                           id="accountHolderName"
                                           type="text"
                                           class="form-control"
                                           maxlength="30"
                                           required />
                                 </div>

                              </div>

                           </div>

                           <div class="row">

                              <div class="col">

                                 <div class="mt-2">
                                    <label for="{{eftRecordTypeSelectFieldName}}" class="form-label">Account Type</label>
                                    <em *ngIf="!eftRecordTypeSelect && mouseoverPaymentAuthSubmit"
                                        class="error-msg float-none">
                                       Required
                                    </em>
                                    <div class="well" style="margin: 5px 10px;">
                                       <span class="d-inline-block" *ngFor="let eftRecordType of eftRecordTypes">
                                          <span class="d-block pretty p-default p-round">
                                             <input type="radio"
                                                    [formControlName]="eftRecordTypeSelectFieldName"
                                                    [value]="eftRecordType.code"
                                                    (change)="eftRecordTypeSelect = eftRecordType" />
                                             <span class="d-block state p-success-o">
                                                <label>{{eftRecordType.description}}</label>
                                             </span>
                                          </span>
                                       </span>
                                    </div>
                                 </div>

                              </div>

                           </div>

                        </div>

                     </fieldset>

                  </div>

                  <div class="col-lg-6" formGroupName="billingAddress">

                     <fieldset class="mt-3">
                        <legend>Billing Address</legend>

                        <div class="row">

                           <div class="col">
                              <div [ngClass]="{'error': line1.invalid && (line1.dirty || line1.touched || mouseoverPaymentAuthSubmit)}">
                                 <label for="line1" class="form-label">Address</label>
                                 <em *ngIf="line1.invalid && (line1.dirty || line1.touched || mouseoverPaymentAuthSubmit)" class="error-msg">
                                    Required
                                 </em>
                                 <input formControlName="line1"
                                        id="line1"
                                        type="text"
                                        class="form-control"
                                        placeholder="Address"
                                        required />
                              </div>
                           </div>

                        </div>

                        <div class="row">

                           <div class="col">
                              <div [ngClass]="{'error': city.invalid && (city.dirty || city.touched || mouseoverPaymentAuthSubmit)}">
                                 <label for="city" class="form-label">City</label>
                                 <em *ngIf="city.invalid && (city.dirty || city.touched || mouseoverPaymentAuthSubmit)" class="error-msg">
                                    Required
                                 </em>
                                 <input formControlName="city"
                                        id="city"
                                        type="text"
                                        class="form-control"
                                        placeholder="City"
                                        required />
                              </div>
                           </div>

                        </div>

                        <div class="row">

                           <div class="col">
                              <div [ngClass]="{'error': billingCountriesSelect.invalid && (billingCountriesSelect.dirty || billingCountriesSelect.touched || mouseoverPaymentAuthSubmit)}">
                                 <span class="form-label fake-label">Country</span>
                                 <em *ngIf="billingCountriesSelect.invalid && (billingCountriesSelect.dirty || billingCountriesSelect.touched || mouseoverPaymentAuthSubmit)" class="error-msg">
                                    Required
                                 </em>
                                 <ng-select [items]="billingCountries"
                                            bindLabel="description"
                                            formControlName="billingCountriesSelect"
                                            [searchFn]="standardDomainSearchFn"
                                            (change)="onBillingCountryChange($event)"
                                            [clearable]="false"
                                            required>
                                 </ng-select>
                              </div>
                           </div>

                        </div>

                        <div class="row">

                           <div class="col">
                              <div [ngClass]="{'error': billingStatesSelect.invalid && (billingStatesSelect.dirty || billingStatesSelect.touched || mouseoverPaymentAuthSubmit)}">
                                 <span class="form-label fake-label">State/Province</span>
                                 <em *ngIf="billingStatesSelect.invalid && (billingStatesSelect.dirty || billingStatesSelect.touched || mouseoverPaymentAuthSubmit)" class="error-msg">
                                    Required
                                 </em>
                                 <ng-select [items]="billingStates"
                                            bindLabel="name"
                                            formControlName="billingStatesSelect"
                                            [searchFn]="standardDomainSearchFn"
                                            [clearable]="false"
                                            required>
                                 </ng-select>
                              </div>
                           </div>

                        </div>

                        <div class="row">

                           <div class="col">
                              <div [ngClass]="{'error': postalCode.invalid && (postalCode.dirty || postalCode.touched || mouseoverPaymentAuthSubmit)}">
                                 <label for="postalCode" class="form-label">Zip</label>
                                 <em *ngIf="postalCode.invalid && (postalCode.dirty || postalCode.touched || mouseoverPaymentAuthSubmit)" class="error-msg">
                                    <span *ngIf="postalCode.errors.required">
                                       Req
                                    </span>
                                    <span *ngIf="postalCode.errors.pattern">
                                       Invalid
                                    </span>
                                 </em>
                                 <input formControlName="postalCode"
                                        id="postalCode"
                                        type="text"
                                        class="form-control"
                                        placeholder="Zip Code"
                                        required />
                              </div>
                           </div>

                        </div>

                     </fieldset>

                  </div>

               </div>

               <div class="row mt-3">

                  <div class="col text-center">

                     <button (click)="addNewPayment(false)"
                             [disabled]="isSubmitting"
                             class="btn btn-danger fw-bold fs-6 text-uppercase">
                        Cancel
                     </button>

                     <span *ngIf="paymentInstrument?.typeCode"
                           (mouseenter)="mouseoverPaymentAuthSubmit=true" (mouseleave)="mouseoverPaymentAuthSubmit=false"
                           class="ms-4">
                        <app-button [buttonConfig]="{textChange: ['Update'], class: 'btn btn-site-secondary fw-bold fs-6 text-uppercase'}"
                                    [isLoading]="isSubmitting"
                                    [disabled]="!paymentInstrumentForm.valid || isSubmitting"
                                    (textBtnClickEmt)="updatePaymentAuth()"></app-button>
                     </span>

                  </div>

               </div>

            </div>

         </fieldset>

      </div>
   </div>

</form>
<ng-template app-confirm-simple>
   <app-confirm-modal-component></app-confirm-modal-component>
</ng-template>
<app-simple-modal closeOnBodyClick="true" elementId="cvvModal" title="Card Security Code (CVV)">
   <!--[closeFunction]="bubba"-->
   <div>
      <img src="/assets/images/creditcards_cvv.png" class="img-fluid" alt="Card Security Code (CVV)" />
   </div>
</app-simple-modal>
