
<div class="text-center fw-bold fs-3">
   Enter Mentor Meeting Code
</div>

<p class="text-center mb-3">
   Enter the code your mentor gave you to earn your mentor meeting points!
</p>

<form #f="ngForm" (ngSubmit)="redeemCode(f)" novalidate>
   <div class="input-group mb-3">
      <input name="mentorCode" ngModel required #mentorCode="ngModel"
             type="text" class="form-control text-uppercase fw-bold"
             placeholder="Enter code..."
             aria-label="Enter code..."
             aria-describedby="button-submit"
             maxlength="20"/>
      <button class="btn btn-site-primary" type="submit" id="button-submit"
              [disabled]="isSubmitting()">Submit</button>
   </div>

   <div *ngIf="alert()" class="alert {{alert()?.alertClass}}" role="alert">
      {{alert().message}}
   </div>

</form>

<ng-template app-confirm-simple>
   <app-confirm-modal-component></app-confirm-modal-component>
</ng-template>
