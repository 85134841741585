import { Injectable } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs'

@Injectable({ providedIn: 'root' })
export class UrlService {
   // https://medium.com/@jacobneterer/accessing-the-previous-url-in-angular-32cf97bec3ce
   private _previousUrl: BehaviorSubject<string> = new BehaviorSubject<string>(
      ''
   );
   public get previousUrl$(): Observable<string> {
      return this._previousUrl.asObservable()
   }

   private _headerTitle: ReplaySubject<string> = new ReplaySubject<string>();
   public get headerTitle$(): Observable<string> {
      return this._headerTitle.asObservable()
   }

   constructor(private _titleService: Title) { }

   setPreviousUrl(previousUrl: string): void {
      this._previousUrl.next(previousUrl)
   }

   setHeaderTitle(title: string, setBrowserTitle?: boolean): void {
      this._headerTitle.next(title)

      if (setBrowserTitle) {
         this._titleService.setTitle(`LEAD - ${title}`)
      }
   }
}
