<div class="container">
   <div class="row sections">

      <div class="col-12 text-center brightcove-player">

         <div class="rounded">

            <div brightcove-experience-player
                 [attr.data-experience-id]="brightcoveSettings.experiences.dashboard"
                 [attr.data-user-id]="brightcoveClientUserId">
            </div>

         </div>

      </div>

      <div class="col-md-6 mb-3">

         <div (click)="externalNavigate('https://calendly.com/southwesternlead?hide_gdpr_banner=1')" class="pointable">
            <img [src]="scheduleMentorImageUri" title="Book a Mentor Meeting" class="img-link w-100 pointable" />
         </div>

      </div>

      <div class="col-md-6 mb-3">

         <!-- LEAD specific...instead of cards -->
         <a routerLink="/{{coursesUri}}">
            <img [src]="coursesImageUri" title="Courses" class="img-link w-100" />
         </a>

      </div>

   </div>

</div>

