import { ChangeDetectionStrategy, Component, effect, inject } from '@angular/core'
import { NgIf, NgClass } from '@angular/common'
import { FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms'
import { take } from 'rxjs'
import {
   AccountsService,
   AppFacade,
   CommonService,
   UrlService
} from '@appShared/services'
import { IUser } from '@appShared/interfaces/[Interface-based]'
import { IProfileSubmission } from '@appShared/interfaces/[Model-based]/profile-submission.interface'
import { IAccount } from '@appShared/interfaces/[Model-based]/account.interface'
import { ResetPasswordComponent } from '@appMember/components/reset-password/reset-password.component'
import { ButtonComponent } from '@appShared/components/button/button.component'
import { environment } from '@appEnvironments/environment'

@Component({
    selector: 'app-account-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, FormsModule, ReactiveFormsModule, NgClass, ButtonComponent, ResetPasswordComponent]
})
export class AccountProfileComponent {
   isSubmitting = false
   mouseoverProfileSubmit = false

   private _appFacade = inject(AppFacade)
   private _currentUser = this._appFacade.currentUser

   private _commonService = inject(CommonService)
   private _accountsService = inject(AccountsService)

   /* form/form-fields */
   profileSubmissionForm: FormGroup
   firstName: FormControl
   lastName: FormControl
   emailAddress: FormControl
   nickname: FormControl
   nicknameMessage = this._commonService.regexp().usernameMessage
   nicknameMask = this._commonService.mask().username
   isLocalIdentity: boolean
   canEditProfileInfo: boolean


   constructor(
      urlService: UrlService
   ) {
      urlService.setHeaderTitle(environment.routes.member.account.profile.title)

      this._createFormGroup()

      effect(() => {
         this._setProfileData(this._currentUser())
      })
   }

   /*
   * private methods
   * */

   private _createFormGroup(): void {
      this.firstName = new FormControl(null, Validators.required)
      this.lastName = new FormControl(null, Validators.required)
      this.emailAddress = new FormControl(null, [
         Validators.required,
         Validators.pattern(this._commonService.regexp().email)
      ])
      this.nickname = new FormControl(null, [
         Validators.required,
         Validators.pattern(this._commonService.regexp().username)
      ])

      this.profileSubmissionForm = new FormGroup({
         firstName: this.firstName,
         lastName: this.lastName,
         emailAddress: this.emailAddress,
         nickname: this.nickname
      })
   }

   private _setProfileData(currentUser: IUser) {

      const profile = currentUser?.profile
      const profileInfo = profile?.info
      this.isLocalIdentity = profileInfo?.isLocalIdentity
      const profileContact = profile?.contact
      const emailAddress = this.isLocalIdentity ? '' : profileContact?.emailAddress
      const nickname = this.isLocalIdentity ? profileContact?.info?.nickname : ''

      this.canEditProfileInfo = !currentUser?.isAdmin && !currentUser?.isRep

      if (this.isLocalIdentity) {
         this.nickname.enable()
         this.emailAddress.disable()
         this.emailAddress.setValue(null)
      } else {
         this.emailAddress.enable()
         this.nickname.disable()
         this.nickname.setValue(null)
      }

      this.profileSubmissionForm.patchValue({
         emailAddress,
         firstName: profileContact?.firstName,
         lastName: profileContact?.lastName,
         nickname
      })

      this.profileSubmissionForm.updateValueAndValidity()
   }


   /*
   * public methods
   * */

   updateProfile() {
      if (this.profileSubmissionForm.valid) {
         this.isSubmitting = true

         const profileSubmissionValues = this.profileSubmissionForm.value
         const firstName = profileSubmissionValues.firstName
         const lastName = profileSubmissionValues.lastName
         const emailAddress = profileSubmissionValues.emailAddress
         const nickname = profileSubmissionValues.nickname
         const userLogonName = this.isLocalIdentity
            ? nickname
            : emailAddress

         const profileSubmission = {
            firstName,
            lastName,
            userLogonName
         } as IProfileSubmission

         this._accountsService
            .createUpdateProfile(profileSubmission, this._currentUser().profile.id)
            .then((account: IAccount) => {
               this._appFacade.refreshCurrentUser().pipe(take(1)).subscribe({
                  next: () => this._commonService.messageUser(
                     `<div class="text-center fs-5 text-uppercase">Profile Updated!</div>`
                  )
               })
            })
            .finally(() => this.isSubmitting = false)
      }
   }
}
