import { ChangeDetectionStrategy, Component, DestroyRef, SecurityContext } from '@angular/core'
import { NgClass } from '@angular/common'
import { SafeHtml } from '@angular/platform-browser'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { CommonService } from '@appShared/services/common.service'
import { NotificationsService, SiteNotification, SiteNotificationType_ } from '@appShared/services/notifications.service'
import { tap } from 'rxjs'


@Component({
    selector: 'app-site-notification',
    templateUrl: './site-notification.component.html',
    styles: ['.logo { width: 450px }'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgClass]
})
export class SiteNotificationComponent {
   private _siteNotification: SiteNotification = {
      typeCode: SiteNotificationType_.Information
   } as SiteNotification

   get safeHtmlNotification(): SafeHtml {
      return this._siteNotification.notification
         ? this._commonService.transform(this._siteNotification.notification, SecurityContext.HTML)
         : null
   }

   get notificationAlertLevel(): string {
      switch (this._siteNotification.typeCode) {
         case SiteNotificationType_.Information:
            return 'alert-info'
         case SiteNotificationType_.AccountOwnerIssueWithAccount:
         case SiteNotificationType_.NonAccountOwnerIssueWithAccount:
            return 'alert-danger'
         default:
            return 'alert-warning'
      }
   }

   constructor(
      private _commonService: CommonService,
      private _notificationsService: NotificationsService,
      destroyRef: DestroyRef
   ) {
      this._notificationsService.currentNotification$
         .pipe(
            takeUntilDestroyed(destroyRef),
            tap(data => console.log('notification', data)))
         .subscribe(notification => this._siteNotification = notification)
   }

   /*
   * private methods
   * */

   /*
   * public methods
   * */
}
