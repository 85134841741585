import { InjectionToken } from '@angular/core';

export enum ToastrType {
  error = 'errors',
  info = 'info',
  success = 'success',
  warning = 'warning'
}

export enum ToastrPosition {
  topRight = 'toast-top-right',
  bottomRight = 'toast-bottom-right',
  topLeft = 'toast-top-left',
  bottomLeft = 'toast-bottom-left',
  topFullWidth = 'toast-top-full-width',
  bottomFullWidth = 'toast-bottom-full-width',
  topCenter = 'toast-top-center',
  bottomCenter = 'toast-bottom-center'
}

/* eslint-disable @typescript-eslint/member-ordering */
export interface Toastr {
  success(msg: string, title?: string): void;
  info(msg: string, title?: string): void;
  warning(msg: string, title?: string): void;
  error(msg: string, title?: string): void;
  options?: any;
}
/* eslint-enable @typescript-eslint/member-ordering */

// use 'any' if more complex object
// export let TOASTR_TOKEN = new InjectionToken<any>('toastr');

// OR pass interface for what is returned
export let TOASTR_TOKEN = new InjectionToken<Toastr>('toastr');



/*
 * USE:
 *
  this._commonService.messageUser('Basic Data updated SUCCESS TOP RIGHT!');
  this._commonService.messageUser('Basic Data updated SUCCESS TOP RIGHT!', 'And I mean it');
  this._commonService.messageUser('Basic Data updated ERROR TOP RIGHT!', null, ToastrType.error);
  this._commonService.messageUser('Basic Data updated INFO BOTTOM CENTER!', null, ToastrType.info,
    { positionClass: ToastrPosition.bottomCenter });
  this._commonService.messageUser('Basic Data updated SUCCESS TOP CENTER!', 'JOHN is HAWT!', null,
    { positionClass: ToastrPosition.topCenter });
  this._commonService.messageUser('Basic Data updated WARNING TOP LEFT!', null, ToastrType.warning,
    { positionClass: ToastrPosition.topLeft });
 */
