import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { lastValueFrom, take } from 'rxjs'
import { CommonService } from './common.service'

@Injectable({ providedIn: 'root' })
export class ContentRegistrationsService {
   private _contentRegistrationsApi = '/api/content/content-registrations';

   // https://stackoverflow.com/a/71961946/1146117
   private _httpOptions = {}

   constructor(
      commonService: CommonService,
      private _httpClient: HttpClient
   ) {
      this._httpOptions = commonService.httpOptions()
   }

   async getCourseLink(token: string): Promise<any> {
      const url = `${this._contentRegistrationsApi}/retrieve-launch-link-from-token?token=${token}`

      const request$ = this._httpClient.get(url).pipe(take(1))

      return lastValueFrom(request$)
   }
}
