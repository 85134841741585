import { ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit } from '@angular/core'
import { NgIf, NgFor, NgClass } from '@angular/common'
import { HttpStatusCode } from '@angular/common/http'
import { EMPTY, Observable } from 'rxjs'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { catchError, distinctUntilChanged, filter, finalize, switchMap, take } from 'rxjs/operators'
import { FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms'
import { NgSelectModule } from '@ng-select/ng-select'
import { NgxMaskDirective } from 'ngx-mask'
import { CreditCard, CreditCardValidators, CreditCardDirectivesModule } from 'angular-cc-library'
import { AccountsService, CommonService } from '@appShared/services'
import { AppFacade } from '@appShared/services/app.facade'
import { IDomainModel } from '@appShared/services/lookup/[CodeGen]/domain-model.interface'
import { EftRecordTypes, EftRecordType_ } from '@appShared/services/lookup/[CodeGen]/eft-record-type.domain'
import {
   PaymentInstrumentType,
   PaymentInstrumentTypes,
   PaymentInstrumentType_
} from '@appShared/services/lookup/[CodeGen]/payment-instrument-type.domain'
import { PaymentCardTypes, PaymentCardType_ } from '@appShared/services/lookup/[CodeGen]/payment-card-type.domain'
import { IEftRecordSubmission, IPaymentCardSubmission, IPaymentInstrument } from '@appShared/interfaces/[Model-based]/payment-instrument.interface'
import { Countries, Country, Country_ } from '@appShared/services/lookup/[CodeGen]/country.domain'
import { StateProvince, StateProvinces, StateProvince_ } from '@appShared/services/lookup/[CodeGen]/state-province.domain'
import { IContactAddress } from '@appShared/interfaces/[Model-based]/contact-address.interface'
import { AddressType_ } from '@appShared/services/lookup/[CodeGen]/address-type.domain'
import { IPaymentInstrumentSubmission } from '@appShared/interfaces/[Model-based]/payment-instrument-submission.interface'
import { SimpleModalComponent } from '@appShared/components/simple-modal.component';
import { ConfirmTemplateDirective, ConfirmModalComponent } from '@appShared/components/confirm-modal-and-service';
import { ButtonComponent } from '@appShared/components/button/button.component';
import * as _ from 'lodash'

@Component({
    selector: 'app-account-payment-auth',
    templateUrl: './payment-auth.component.html',
    styleUrls: ['./payment-auth.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgIf, NgFor, NgClass, CreditCardDirectivesModule, NgxMaskDirective, NgSelectModule, ButtonComponent, ConfirmTemplateDirective, ConfirmModalComponent, SimpleModalComponent]
})
/** account-payment component*/
export class AccountPaymentAuthComponent implements OnInit {
   private _appFacade = inject(AppFacade)
   private _profile = this._appFacade.currentUser().profile

   private _commonService = inject(CommonService)
   private _todayMoment = this._commonService.dateTime.getMomentDate(null, true)
   standardDomainSearchFn = this._commonService.standardDomainSearchFn

   private _accountsService = inject(AccountsService)

   annualBillingMonthDay: string
   isSubmitting = false

   activePaymentAuthorization: any //TODO strongly type
   /* form/form-fields */
   paymentInstrumentForm: FormGroup
   addNewPaymentAuthorization: boolean
   mouseoverPaymentAuthSubmit = false
   pan: FormControl
   expiration: FormControl
   cvv2: FormControl
   cardholderName: FormControl
   creditCardMask: Array<string | RegExp>
   creditCardExpMask: Array<string | RegExp>
   ccImgUrl: string
   // https://egghead.io/lessons/angular-create-a-formcontrol-dynamically-with-reactive-forms-in-angular
   paymentInstrumentTypeSelectFieldName = 'paymentInstrumentTypeInput'
   paymentInstrumentTypeSelect: any = null
   paymentInstrumentTypes = _.sortBy(PaymentInstrumentTypes.filter(payAuth =>
      _.includes([
         PaymentInstrumentType_.EftRecord,
         PaymentInstrumentType_.PaymentCard], payAuth.code)), ['listPriority'])

   paymentInstrument: IPaymentInstrument
   paymentInstrumentType = PaymentInstrumentType_
   billingDateSelect: any = null
   billingDates: IDomainModel[] = []
   eftRecordTypeSelectFieldName = 'eftRecordTypeInput'
   eftRecordTypeSelect: any = null
   eftRecordTypes = EftRecordTypes
   routingNumber: FormControl
   accountNumber: FormControl
   accountHolderName: FormControl
   //billingAddress
   line1: FormControl
   city: FormControl
   billingCountriesSelect: FormControl
   billingCountries = Countries.filter(country => country.code) //filter out (0) uknown
   billingStatesSelect: FormControl
   billingStates: StateProvince[]

   postalCode: FormControl

   private _destroyRef = inject(DestroyRef)

   constructor() {
      this._createFormGroup()
   }

   ngOnInit() {
      this._setData()

      // used to set the CC image if valid
      this.pan.valueChanges.pipe(
         takeUntilDestroyed(this._destroyRef),
         distinctUntilChanged(),
         filter(() => this.pan.valid)
      ).subscribe(cardNumber => {
         let cardType = CreditCard.cardType(cardNumber)
         this.ccImgUrl = cardType ? `/assets/images/cc/${cardType}.png` : ''

      })

      this._commonService.scrollToPosition()
   }

   /*
   * private methods
   * */

   private _createFormGroup(): void {
      // credit card payment
      const masks = this._commonService.mask()
      this.creditCardMask = masks.creditCard
      this.creditCardExpMask = masks.creditCardExp
      this.pan = new FormControl(null)
      this.expiration = new FormControl(null)
      this.cvv2 = new FormControl(null)
      this.cardholderName = new FormControl(null)
      // eft record
      this.routingNumber = new FormControl(null)
      this.accountNumber = new FormControl(null)
      this.accountHolderName = new FormControl(null)

      // paymentInstrument.contact (billing address)

      this.line1 = new FormControl(null, Validators.required)
      this.city = new FormControl(null, Validators.required)
      this.billingCountriesSelect = new FormControl(null, Validators.required)
      this.billingStatesSelect = new FormControl(null, Validators.required)
      this.postalCode = new FormControl(null, Validators.required)

      this.paymentInstrumentForm = new FormGroup({
         /* https://egghead.io/lessons/angular-create-a-formcontrol-dynamically-with-reactive-forms-in-angular */
         [this.paymentInstrumentTypeSelectFieldName]: new FormControl(Validators.required),

         // credit card payment
         pan: this.pan,
         expiration: this.expiration,
         cvv2: this.cvv2,
         cardholderName: this.cardholderName,

         // eft record
         [this.eftRecordTypeSelectFieldName]: new FormControl(null),
         routingNumber: this.routingNumber,
         accountNumber: this.accountNumber,
         accountHolderName: this.accountHolderName,

         billingAddress: new FormGroup({
            line1: this.line1,
            city: this.city,
            billingCountriesSelect: this.billingCountriesSelect,
            billingStatesSelect: this.billingStatesSelect,
            postalCode: this.postalCode
         })
      })
   }

   private _setData(): void {
      //const eftPaymentType = PaymentInstrumentTypes.find(payAuth => payAuth.code === PaymentInstrumentType_.EftRecord)

      this.annualBillingMonthDay = this._todayMoment.format('Do of MMMM')

      //this.setPaymentInstrumentType({ ...eftPaymentType })

      //this.paymentInstrumentForm.patchValue({
      //   [this.paymentInstrumentTypeSelectFieldName]: eftPaymentType.code
      //})

      this._setBillingAddressData()
   }

   private _setBillingAddressData(billingAddress?: IContactAddress): void {

      billingAddress = billingAddress || { contactId: this._profile?.contactId, stateProvinceCode: StateProvince_.Unknown } as IContactAddress

      let billingAddressProvince: StateProvince
      if (billingAddress.stateProvinceCode) {
         billingAddressProvince = StateProvinces.find(province => province.code == billingAddress.stateProvinceCode)
      }

      this._commonService.setFormControlSelect(
         billingAddressProvince?.countryCode || Country_.US/* default to US if no country selected */,
         this.billingCountries,
         this.billingCountriesSelect
      )

      if (this.billingCountriesSelect) {
         const countryCode = (this.billingCountriesSelect.value as Country).code
         this.billingStates = StateProvinces.filter(province => province.countryCode === countryCode)

         this._commonService.setFormControlSelect(
            billingAddress.stateProvinceCode,
            this.billingStates,
            this.billingStatesSelect
         )
      }

      this.paymentInstrumentForm.patchValue({
         line1: billingAddress.line1 || null,
         city: billingAddress.city || null,
         postalCode: billingAddress.postalCode || null
      })
   }

   _clearpaymentInstrument(): void {

   }

   /*
   * public methods
   * */

   addNewPayment(addNew: boolean) {
      this.addNewPaymentAuthorization = addNew
      this.paymentInstrument = null

      if (addNew) {
         this.paymentInstrumentForm.enable()
         setTimeout(() => {
            this._commonService.scrollToAnchor('billing')
         }, 500)
      } else {
         this.paymentInstrumentForm.reset()
         this.paymentInstrumentForm.disable()
         this.paymentInstrumentForm.patchValue({
            ccAccountHolder: null,
            ccNumber: null,
            ccExpiry: null,
            ccCvv: null,
            eftRoutingNumber: null,
            eftAccountNumber: null,
            eftAccountName: null,
            [this.eftRecordTypeSelectFieldName]: null
         })
         this.billingDateSelect = null
         this.eftRecordTypeSelect = null
      }
   }

   onBillingCountryChange(country: Country): void {
      this.billingStatesSelect.setValue(null)
      this.billingStates = StateProvinces.filter(state => state.countryCode == country.code)
      this.billingStates.length ? this.billingStatesSelect.enable() : this.billingStatesSelect.disable()

      let postalValidators = [Validators.required]
      if (country.code === Country_.CA) postalValidators.push(Validators.pattern(this._commonService.regexp().postalCode.caOnly))
      if (country.code === Country_.US) postalValidators.push(Validators.pattern(this._commonService.regexp().postalCode.usOnly))

      this.postalCode.setValidators(postalValidators)
      this.postalCode.updateValueAndValidity()
   }

   setPaymentInstrumentType(paymentInstrumentType: PaymentInstrumentType): void {
      this.paymentInstrumentTypeSelect = paymentInstrumentType

      this.paymentInstrument = {
         typeCode: paymentInstrumentType.code
      } as IPaymentInstrument

      if (paymentInstrumentType.code === PaymentInstrumentType_.EftRecord) {
         this.routingNumber.setValidators([
            Validators.required,
            Validators.minLength(9),
            Validators.maxLength(9)
         ])
         this.accountNumber.setValidators([
            Validators.required,
            Validators.minLength(7),
            Validators.maxLength(15)
         ])
         this.accountHolderName.setValidators([
            Validators.required,
            Validators.minLength(4)
         ])
         this.pan.setValidators([])
         this.expiration.setValidators([])
         this.cvv2.setValidators([])
         this.cardholderName.setValidators([])
         this.paymentInstrumentForm.controls[this.eftRecordTypeSelectFieldName].setValidators(Validators.required)

         this.paymentInstrumentForm.patchValue({
            cardholderName: null,
            pan: null,
            expiration: null,
            cvv2: null
         })
      } else {
         this.pan.setValidators([<any>CreditCardValidators.validateCCNumber])
         this.expiration.setValidators([Validators.required, CreditCardValidators.validateExpDate])
         this.cvv2.setValidators([Validators.required, Validators.pattern(this._commonService.regexp().cvv)])
         this.cardholderName.setValidators([Validators.required, Validators.minLength(4)])
         this.paymentInstrumentForm.controls[this.eftRecordTypeSelectFieldName].setValidators([])
         this.routingNumber.setValidators([])
         this.accountNumber.setValidators([])
         this.accountHolderName.setValidators([])

         this.paymentInstrumentForm.patchValue({
            [this.eftRecordTypeSelectFieldName]: null,
            routingNumber: null,
            accountNumber: null,
            accountHolderName: null
         })
      }

      this.pan.updateValueAndValidity()
      this.expiration.updateValueAndValidity()
      this.cvv2.updateValueAndValidity()
      this.cardholderName.updateValueAndValidity()
      this.routingNumber.updateValueAndValidity()
      this.accountNumber.updateValueAndValidity()
      this.accountHolderName.updateValueAndValidity()
      this.paymentInstrumentForm.controls[this.eftRecordTypeSelectFieldName].updateValueAndValidity()

      this.paymentInstrumentForm.clearValidators()
   }

   updatePaymentAuth(): void {
      if (this.paymentInstrumentForm.valid) {
         this.isSubmitting = true

         const paymentInstrumentFormValues = this.paymentInstrumentForm.value

         const paymentInstrumentTypeCode =
            paymentInstrumentFormValues[this.paymentInstrumentTypeSelectFieldName] as PaymentInstrumentType_
         const eftRecordTypeCode =
            paymentInstrumentFormValues[this.eftRecordTypeSelectFieldName] as EftRecordType_

         // billing Address
         let billingAddress = paymentInstrumentFormValues.billingAddress
         const stateProvinceCode =
            ((this.billingStatesSelect &&
               this.billingStatesSelect.value) as StateProvince).code
         // remove country/state dropdown objects
         delete billingAddress.billingCountriesSelect
         delete billingAddress.billingStatesSelect

         //console.log(paymentInstrumentFormValues)

         const paymentInstrumentSubmission = {

            billingAddress: {
               ...billingAddress,
               stateProvinceCode,
               typeCode: AddressType_.Billing
            } as IContactAddress,

            walletContactId: this._profile.contactId

         } as IPaymentInstrumentSubmission

         if (paymentInstrumentTypeCode === PaymentInstrumentType_.EftRecord) {
            paymentInstrumentSubmission.eft = {
               accountHolderName: paymentInstrumentFormValues.accountHolderName,
               accountNumber: paymentInstrumentFormValues.accountNumber,
               routingNumber: paymentInstrumentFormValues.routingNumber,
               typeCode: eftRecordTypeCode
            } as IEftRecordSubmission
         } else {
            const expirationArray = paymentInstrumentFormValues.expiration.split(' / ')
            const expirationString = `${expirationArray[0]}/1/${expirationArray[1]}`
            const expirationMoment = this._commonService.dateTime.getMomentDate(expirationString)
            const expiration = expirationMoment.endOf('month').format('MM/DD/YYYY')
            const pan = paymentInstrumentFormValues.pan

            const cardType = CreditCard.cardType(pan)
            const typeCode = PaymentCardTypes.find(type => type.literal.toLowerCase() === cardType)?.code || PaymentCardType_.Unknown

            paymentInstrumentSubmission.card = {
               cardholderName: paymentInstrumentFormValues.cardholderName,
               cvv2: paymentInstrumentFormValues.cvv2,
               expiration,
               pan,
               typeCode
            } as IPaymentCardSubmission
         }

         const logError = (err, isPaymentError?: boolean): Observable<never> => {
            this.isSubmitting = false
            let message = err.error
            if (err?.status === HttpStatusCode.PaymentRequired) {
               message = message ?? `There was an issue with the payment information you provided.
                                           Please click OK and then update your payment information.`
               this._commonService.dialogOK({
                  title: 'Payment Failed!',
                  message
               })
            }
            return EMPTY
         }

         this._accountsService
            .updateSubscriptionAuthorizations(paymentInstrumentSubmission)
            .pipe(
               take(1),
               switchMap(() => this._appFacade.refreshCurrentUser()),
               catchError(err => logError(err)),
               finalize(() => this.isSubmitting = false)
            )
            .subscribe((user) => {
               this.addNewPayment(false)
               this._commonService.messageUser('Payment Method Updated!')
               this._accountsService.getActiveSubscriptionAuthorizations()
               this._commonService.scrollToPosition()
            })
      }
    }
}
