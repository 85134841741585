
<div class="bg-page-container">

   <div class="d-flex flex-column p-2 p-lg-5 pt-md-4 mb-3 container position-relative">
      <img src="/assets/images/LEAD-logo-blue-medium.png" class="logo d-none d-sm-block" alt="">

      <div class="fw-bold text-site-secondary fs-4 fst-italic mt-3">
         It takes more than classroom knowledge alone to achieve our goals in life.
      </div>

      <div class="font-site-secondary text-site-secondary fs-5 fst-italic mt-3">
         LEAD offers positive change through personal Leadership, Education, Accountability, and Difference-making.
      </div>

      <div class="font-site-secondary text-site-secondary fs-5 fst-italic  mt-3">
         Enjoy courses on life skills, strengthen your academics with virtual tutoring, gain leadership knowledge,
         and be part of a community that believes in personal responsibility and serving others.
      </div>

      <!--<div class="mt-5 mx-auto d-flex flex-column">
         <a class="btn btn-lg btn-site-secondary fw-bold fs-3 text-uppercase"
            routerLink="/{{registrationUri}}">Become a member</a>

         <a class="btn btn-lg bg-light fw-bold text-site-secondary fs-3 text-uppercase mt-3 bordered"
            [routerLink]="['/guest-access']">Guest Access</a>
      </div>-->

      <div *ngIf="!(isDotNetInteroperable$|async)"
           class="d-flex justify-content-center align-items-center gap-4 mt-4 app-store">
         <a [attr.href]="appStore.apple" target="_blank"><img src="/assets/images/app-store/apple-store.png" alt="LEAD Apple App Store" /></a>
         <a [attr.href]="appStore.googlePlay" target="_blank" ><img src="/assets/images/app-store/google-play.png" alt="LEAD Google Play App Store" /></a>
      </div>

   </div>

</div>

