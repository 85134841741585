import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { lastValueFrom, Observable, take, throwError } from 'rxjs'
import { CommonService } from '@appShared/services/common.service'
import { ISubscription } from '@appShared/interfaces/[Model-based]/subscription.interface'
import { IRegistrationSubmission } from '@appShared/interfaces/[Model-based]/registration-submission.interface'
import { IPaymentInstrumentSubmission } from '@appShared/interfaces/[Model-based]/payment-instrument-submission.interface'
import { IProfileSubmission } from '@appShared/interfaces/[Model-based]/profile-submission.interface'
import { ISubscriptionSeat } from '@appShared/interfaces/[Model-based]/subscription-seat.interface'
import { environment } from '@appEnvironments/environment'

export interface TokenProduct {
   sourceId: string
}

@Injectable({
   providedIn: 'root'
})
export class SubscriptionsService {
   private _subscriptionsApi = '/api/core/subscriptions'

   // https://stackoverflow.com/a/71961946/1146117
   private _httpOptions = {}

   FAKE_SUB = {
      "info": {
         "hasActiveSubscriptionAuthorization": true,
         "isOnRenewalProbation": false,
         "isPendingCancellation": false,
         "isPendingPause": false,
         "accountOwnerProfile": {
         },
         "activeSubscriptionAuthorization": null,
         "relevantSubscriptionAuthorization": null,
         "subscriptionProductPartionContainerId": 131078
      },
      "id": 1810,
      "accountId": 1601,
      "productId": 1,
      "productRegistered": "5/15/2024 12:00:00 AM",
      "referenceSourceId": "54515274",
      "partnerRepresentativeId": 40298,
      "statusCode": 0/*1 active 2 onhold*/,
      "seatLimit": 6,
      "activated": "5/22/2023 9:18:13 PM -05:00",
      "lastPaymentAttempted": "5/15/2024 4:07:16 PM -05:00",
      "retryAttempts": 0,
      "nextPaymentDue": "5/22/2024 12:00:00 AM",
      "renewalProbationEffective": null,
      "expires": null,
      "cancelled": null,
      "cancellationEffective": null,
      "pausedUntil": null,
      "cancellationReasonCode": null,
      "cancellationReasonText": null,
      "productVariantSourceId": null,
      "auditId": "732b0fa5-2e59-48ec-b852-3da748ccf51f",
      "version": "AAAAAAJy7DQ=",
      "account": {
         profiles: [{ contact: { emailAddress: 'bubba@jones.com' } }]
      },
      "partnerRepresentative": {
         "id": 40298,
         "partnerId": 131073,
         "sourceId": "11650178",
         "contracted": "1/1/2018 12:00:00 AM",
         "terminated": null,
         "auditId": "fbb6d3d3-b566-4cdb-b123-2ce98e6f8eea",
         "version": "AAAAAAJrglU=",
         "partner": null,
         "subscriptions": [
            {

            } as ISubscription
         ]
      },
      "product": {
         "id": 1,
         "partnerId": 131073,
         "sourceId": "LEM",
         "billingFrequencyCode": 1,
         "displayName": "LEAD Monthly",
         "configurationUri": null,
         "defaultSeatLimit": 6,
         "auditId": "609dd865-633b-4c9c-89a4-164f945924d9",
         "version": "AAAAAAJxxOM=",
         "groupCode": "LEAD",
         "partner": null,
         "accountingRules": [],
         "productPartitions": [
            {
               "id": 1,
               "productId": 1,
               "partitionId": 6,
               "auditId": "c85a89df-3934-4366-a257-834c303150ad",
               "partition": {
                  "$id": "6",
                  "id": 6,
                  "partnerId": 131073,
                  "containerAccessPolicyCode": 0,
                  "updateStarted": null,
                  "updateCompleted": null,
                  "updateFrequency": null,
                  "updateRetryFrequency": null,
                  "updateWindowFrom": null,
                  "updateWindowThru": null,
                  "auditId": "0bad9490-c89e-4247-8f85-5398d2195b20",
                  "version": "AAAAAAJtH5s=",
                  "partner": null,
                  "coreStandardCategories": [],
                  "indexSeries": [],
                  "productPartitions": [],
                  "containers": [
                     {
                        "$id": "7",
                        "info": {
                           "isInProgress": false,
                           "contentRegistration": null,
                           "sequence": 0,
                           "isComplete": false,
                           "isLocked": false,
                           "areaToken": null,
                           "hostedContentToken": null,
                           "contentHost": null,
                           "encodedQualifiedHostedContentToken": null,
                           "encodedToken": "NjAxMzM5ZDItNGNjNS00NTAzLWI0NmYtNTFlM2I0M2RiOThl"
                        },
                        "id": 131078,
                        "partitionId": 6,
                        "typeCode": 0,
                        "componentTypeCode": 0,
                        "sourceId": "24",
                        "name": "Lead",
                        "description": "soutwhesternlead.com",
                        "longDescription": "LEAD",
                        "urlSlug": null,
                        "headerImageUri": "",
                        "iconImageUri": "https://southwesternlead.com/Content/Images/Header/SiteSelector/LEADLogo-medium.png",
                        "qualifiedHostedContentToken": null,
                        "published": "4/7/2023 12:00:00 AM",
                        "achievementKey": null,
                        "auditId": "601339d2-4cc5-4503-b46f-51e3b43db98e",
                        "version": "AAAAAAJwDQ4=",
                        "partition": {
                           "$ref": "6"
                        },
                        "containerAccesses": [],
                        "containerBookmarks": [],
                        "containerCoreStandards": [],
                        "containerIndexSeriesEditionVolumes": [],
                        "containerStructureChildContainers": [],
                        "containerStructureParentContainers": [],
                        "containerTags": [],
                        "lessonContents": []
                     }
                  ],
                  "tenantPartitions": []
               },
               "product": {
                  "$ref": "5"
               }
            },
            {
               "id": 21,
               "productId": 1,
               "partitionId": 14,
               "auditId": "02a5c372-6e2b-49fb-94df-915fb9f6b7f3",
               "partition": {
                  "$id": "8",
                  "id": 14,
                  "partnerId": 131073,
                  "containerAccessPolicyCode": 2,
                  "updateStarted": null,
                  "updateCompleted": null,
                  "updateFrequency": null,
                  "updateRetryFrequency": null,
                  "updateWindowFrom": null,
                  "updateWindowThru": null,
                  "auditId": "ce40b29e-c13b-4f2c-ba53-c284f22dc1d7",
                  "version": "AAAAAAJwIJU=",
                  "partner": null,
                  "coreStandardCategories": [],
                  "indexSeries": [],
                  "productPartitions": [],
                  "containers": [
                     {
                        "$id": "9",
                        "info": {
                           "isInProgress": false,
                           "contentRegistration": null,
                           "sequence": 0,
                           "isComplete": false,
                           "isLocked": false,
                           "areaToken": null,
                           "hostedContentToken": "OW3CISIHU9",
                           "contentHost": 1,
                           "encodedQualifiedHostedContentToken": "U0NPUk1DbG91ZC5PVzNDSVNJSFU5",
                           "encodedToken": "YTMzOTZkNTItZmM0Zi00MmI1LTkyYmItODk1YzZmMWY4MDMy"
                        },
                        "id": 132409,
                        "partitionId": 14,
                        "typeCode": 0,
                        "componentTypeCode": 0,
                        "sourceId": "24",
                        "name": "Lead",
                        "description": "New Lead Using SCORM",
                        "longDescription": "LEAD",
                        "urlSlug": null,
                        "headerImageUri": null,
                        "iconImageUri": "https://southwesternlead.com/Content/Images/Header/SiteSelector/LEADLogo-medium.png",
                        "qualifiedHostedContentToken": "SCORMCloud.OW3CISIHU9",
                        "published": "2/11/2024 12:00:00 AM",
                        "achievementKey": null,
                        "auditId": "a3396d52-fc4f-42b5-92bb-895c6f1f8032",
                        "version": "AAAAAAJwe0Y=",
                        "partition": {
                           "$ref": "8"
                        },
                        "containerAccesses": [],
                        "containerBookmarks": [],
                        "containerCoreStandards": [],
                        "containerIndexSeriesEditionVolumes": [],
                        "containerStructureChildContainers": [],
                        "containerStructureParentContainers": [],
                        "containerTags": [],
                        "lessonContents": []
                     }
                  ],
                  "tenantPartitions": []
               },
               "product": {
                  "$ref": "5"
               }
            }
         ],
         "subscriptions": [
            {

            }
         ]
      },
      "subscriptionAuthorizations": [],
      "subscriptionPaymentTransactions": [],
      "subscriptionSeats": [
         {
            "id": 466,
            "subscriptionId": 1810,
            "profileId": 1540,
            "auditId": "033ea267-3921-4932-96fa-9b0aaf79c545",
            "profile": {
            },
            "subscription": {
            }
         }
      ]
   } as unknown

   FAKE_GOOD_SUB = {
      "$id": "3",
      "info": {
         "hasActiveSubscriptionAuthorization": true,
         "isOnRenewalProbation": false,
         "isPendingCancellation": false,
         "isPendingPause": false,
         "accountOwnerProfile": {
            contact: { emailAddress: 'bubba@jones.com' }
         },
         "activeSubscriptionAuthorization": null,
         "relevantSubscriptionAuthorization": null,
         "subscriptionProductPartionContainerId": 131078
      },
      "id": 1810,
      "accountId": 1601,
      "productId": 1,
      "productRegistered": "5/15/2024 12:00:00 AM",
      "referenceSourceId": "54515274",
      "partnerRepresentativeId": 40298,
      "statusCode": 0/*1 active 2 onhold*/,
      "seatLimit": 6,
      "activated": "5/22/2023 9:18:13 PM -05:00",
      "lastPaymentAttempted": "5/15/2024 4:07:16 PM -05:00",
      "retryAttempts": 0,
      "nextPaymentDue": "5/22/2024 12:00:00 AM",
      "renewalProbationEffective": null,
      "expires": null,
      "cancelled": null,
      "cancellationEffective": null,
      "pausedUntil": null,
      "cancellationReasonCode": null,
      "cancellationReasonText": null,
      "productVariantSourceId": null,
      "auditId": "732b0fa5-2e59-48ec-b852-3da748ccf51f",
      "version": "AAAAAAJy7DQ=",
      "account": {
         profiles: [{ contact: { emailAddress: 'bubba@jones.com' } }]
      },
      "partnerRepresentative": {
         "$id": "4",
         "id": 40298,
         "partnerId": 131073,
         "sourceId": "11650178",
         "contracted": "1/1/2018 12:00:00 AM",
         "terminated": null,
         "auditId": "fbb6d3d3-b566-4cdb-b123-2ce98e6f8eea",
         "version": "AAAAAAJrglU=",
         "partner": null,
         "subscriptions": [
            {
               "$ref": "3"
            }
         ]
      },
      "product": {
         "$id": "5",
         "id": 1,
         "partnerId": 131073,
         "sourceId": "LEM",
         "billingFrequencyCode": 1,
         "displayName": "LEAD Monthly",
         "configurationUri": null,
         "defaultSeatLimit": 6,
         "auditId": "609dd865-633b-4c9c-89a4-164f945924d9",
         "version": "AAAAAAJxxOM=",
         "groupCode": "LEAD",
         "partner": null,
         "accountingRules": [],
         "productPartitions": [
            {
               "id": 1,
               "productId": 1,
               "partitionId": 6,
               "auditId": "c85a89df-3934-4366-a257-834c303150ad",
               "partition": {
                  "$id": "6",
                  "id": 6,
                  "partnerId": 131073,
                  "containerAccessPolicyCode": 0,
                  "updateStarted": null,
                  "updateCompleted": null,
                  "updateFrequency": null,
                  "updateRetryFrequency": null,
                  "updateWindowFrom": null,
                  "updateWindowThru": null,
                  "auditId": "0bad9490-c89e-4247-8f85-5398d2195b20",
                  "version": "AAAAAAJtH5s=",
                  "partner": null,
                  "coreStandardCategories": [],
                  "indexSeries": [],
                  "productPartitions": [],
                  "containers": [
                     {
                        "$id": "7",
                        "info": {
                           "isInProgress": false,
                           "contentRegistration": null,
                           "sequence": 0,
                           "isComplete": false,
                           "isLocked": false,
                           "areaToken": null,
                           "hostedContentToken": null,
                           "contentHost": null,
                           "encodedQualifiedHostedContentToken": null,
                           "encodedToken": "NjAxMzM5ZDItNGNjNS00NTAzLWI0NmYtNTFlM2I0M2RiOThl"
                        },
                        "id": 131078,
                        "partitionId": 6,
                        "typeCode": 0,
                        "componentTypeCode": 0,
                        "sourceId": "24",
                        "name": "Lead",
                        "description": "soutwhesternlead.com",
                        "longDescription": "LEAD",
                        "urlSlug": null,
                        "headerImageUri": "",
                        "iconImageUri": "https://southwesternlead.com/Content/Images/Header/SiteSelector/LEADLogo-medium.png",
                        "qualifiedHostedContentToken": null,
                        "published": "4/7/2023 12:00:00 AM",
                        "achievementKey": null,
                        "auditId": "601339d2-4cc5-4503-b46f-51e3b43db98e",
                        "version": "AAAAAAJwDQ4=",
                        "partition": {
                           "$ref": "6"
                        },
                        "containerAccesses": [],
                        "containerBookmarks": [],
                        "containerCoreStandards": [],
                        "containerIndexSeriesEditionVolumes": [],
                        "containerStructureChildContainers": [],
                        "containerStructureParentContainers": [],
                        "containerTags": [],
                        "lessonContents": []
                     }
                  ],
                  "tenantPartitions": []
               },
               "product": {
                  "$ref": "5"
               }
            },
            {
               "id": 21,
               "productId": 1,
               "partitionId": 14,
               "auditId": "02a5c372-6e2b-49fb-94df-915fb9f6b7f3",
               "partition": {
                  "$id": "8",
                  "id": 14,
                  "partnerId": 131073,
                  "containerAccessPolicyCode": 2,
                  "updateStarted": null,
                  "updateCompleted": null,
                  "updateFrequency": null,
                  "updateRetryFrequency": null,
                  "updateWindowFrom": null,
                  "updateWindowThru": null,
                  "auditId": "ce40b29e-c13b-4f2c-ba53-c284f22dc1d7",
                  "version": "AAAAAAJwIJU=",
                  "partner": null,
                  "coreStandardCategories": [],
                  "indexSeries": [],
                  "productPartitions": [],
                  "containers": [
                     {
                        "$id": "9",
                        "info": {
                           "isInProgress": false,
                           "contentRegistration": null,
                           "sequence": 0,
                           "isComplete": false,
                           "isLocked": false,
                           "areaToken": null,
                           "hostedContentToken": "OW3CISIHU9",
                           "contentHost": 1,
                           "encodedQualifiedHostedContentToken": "U0NPUk1DbG91ZC5PVzNDSVNJSFU5",
                           "encodedToken": "YTMzOTZkNTItZmM0Zi00MmI1LTkyYmItODk1YzZmMWY4MDMy"
                        },
                        "id": 132409,
                        "partitionId": 14,
                        "typeCode": 0,
                        "componentTypeCode": 0,
                        "sourceId": "24",
                        "name": "Lead",
                        "description": "New Lead Using SCORM",
                        "longDescription": "LEAD",
                        "urlSlug": null,
                        "headerImageUri": null,
                        "iconImageUri": "https://southwesternlead.com/Content/Images/Header/SiteSelector/LEADLogo-medium.png",
                        "qualifiedHostedContentToken": "SCORMCloud.OW3CISIHU9",
                        "published": "2/11/2024 12:00:00 AM",
                        "achievementKey": null,
                        "auditId": "a3396d52-fc4f-42b5-92bb-895c6f1f8032",
                        "version": "AAAAAAJwe0Y=",
                        "partition": {
                           "$ref": "8"
                        },
                        "containerAccesses": [],
                        "containerBookmarks": [],
                        "containerCoreStandards": [],
                        "containerIndexSeriesEditionVolumes": [],
                        "containerStructureChildContainers": [],
                        "containerStructureParentContainers": [],
                        "containerTags": [],
                        "lessonContents": []
                     }
                  ],
                  "tenantPartitions": []
               },
               "product": {
                  "$ref": "5"
               }
            }
         ],
         "subscriptions": [
            {
               "$ref": "3"
            }
         ]
      },
      "subscriptionAuthorizations": [],
      "subscriptionPaymentTransactions": [],
      "subscriptionSeats": [
         {
            "$id": "10",
            "id": 466,
            "subscriptionId": 1810,
            "profileId": 1540,
            "auditId": "033ea267-3921-4932-96fa-9b0aaf79c545",
            "profile": {
               "$ref": "1"
            },
            "subscription": {
               "$ref": "3"
            }
         }
      ]
   } as unknown

   constructor(
      private _http: HttpClient,
      private _commonService: CommonService
   ) {
      this._httpOptions = _commonService.httpOptions()
   }

   async getPartnerRepresentative(partnerRepresentative: string): Promise<any> {
      if (!partnerRepresentative) {
         return Promise.reject('No partnerRepresentative number passed!')
      }
      const dealerApiUri = environment.dealerApiUri
      const url = `${dealerApiUri}/dealer-profile-info/${partnerRepresentative}`

      const request$ = this._http.get(url).pipe(take(1))

      return await lastValueFrom(request$)
   }

   async getSubscription(subscriptionId: number): Promise<ISubscription> {
      if (!subscriptionId) {
         return new Promise((resolve, reject) =>
            reject('No subscriptionId passed!')
         )
      }

      const url = `${this._subscriptionsApi}/${subscriptionId}`

      const request$ = this._http.get<ISubscription>(url).pipe(take(1))

      return await lastValueFrom<ISubscription>(request$)
   }
   //
   async getProductFromToken(token: string): Promise<TokenProduct> {
      if (!token) {
         return new Promise((resolve, reject) =>
            reject('No token passed!')
         )
      }

      //TODO - testing remove
      //return new Promise((resolve, reject) =>
      //   resolve({ sourceId: 'LEM'} as TokenProduct)
      //)

      const url = `${this._subscriptionsApi}/product-sourceid?token=${token}`

      const request$ = this._http.get<TokenProduct>(url).pipe(take(1))

      return await lastValueFrom<TokenProduct>(request$)
   }

   async createSubscription(
      registrationSubmission: IRegistrationSubmission
   ): Promise<
      any /*having to denote "any" since passing in IRegistrationSubmission and post is returning an ISubscription*/
   > {
      if (!registrationSubmission) {
         return new Promise((resolve, reject) =>
            reject('No registrationSubmission passed!')
         )
      }

      const url = `${this._subscriptionsApi}/register`

      const request$ = this._http.post<ISubscription>(
         url,
         registrationSubmission,
         this._httpOptions
      ).pipe(take(1))

      return await lastValueFrom<ISubscription>(request$)
   }

   async registerProfileWithSubmission(
      registrationSubmission: IRegistrationSubmission
   ): Promise<
      any /*having to denote "any" since passing in IRegistrationSubmission and post is returning an ISubscription*/
   > {
      if (!registrationSubmission) {
         return new Promise((resolve, reject) =>
            reject('No registrationSubmission passed!')
         )
      }

      const url = `${this._subscriptionsApi}/register-profile-with-submission`

      const request$ = this._http.post<ISubscription>(
         url,
         registrationSubmission,
         this._httpOptions
      ).pipe(take(1))

      return await lastValueFrom<ISubscription>(request$)
   }

   async createSubscriptionAuthorization(
      subscriptionId: number,
      paymentInstrumentSubmission: IPaymentInstrumentSubmission
   ): Promise<
      any /*having to denote "any" since passing in IPaymentInstrument and post is returning an ISubscription*/
   > {
      if (!subscriptionId || !paymentInstrumentSubmission) {
         return new Promise((resolve, reject) =>
            reject('Not all subscriptionId/paymentInstrumentSubmission passed!')
         )
      }

      //TODO
      //return new Promise((resolve, reject) =>
      //   resolve(this.FAKE_GOOD_SUB as ISubscription)
      //)

      const url = `${this._subscriptionsApi
         }/${subscriptionId}/subscription-authorizations`

      const request$ = this._http.post<ISubscription>(
         url,
         paymentInstrumentSubmission,
         this._httpOptions
      ).pipe(take(1))

      return await lastValueFrom<ISubscription>(request$)
   }

   async confirmSubscription(
      subscriptionId: number,
      //TODO move later billingFrequency: BillingFrequency_,
      confirmationCode: string
   ): Promise<void> {
      if (!subscriptionId || !confirmationCode /* || !billingFrequency*/) {
         return new Promise((resolve, reject) =>
            reject('No all subscriptionId/confirmationCode passed!')
         )
      }

      const url = `${this._subscriptionsApi
         }/${subscriptionId}/confirm/${confirmationCode}`

      const request$ = this._http.post<void>(
         url,
         this._httpOptions
      ).pipe(take(1))

      return await lastValueFrom<void>(request$)
   }

   async sendSubscriptionConfirmation(subscriptionId: number): Promise<void> {
      if (!subscriptionId) {
         return new Promise((resolve, reject) =>
            reject('No subscriptionId passed!')
         )
      }

      const url = `${this._subscriptionsApi
         }/${subscriptionId}/notify-subscription-authorization`

      const request$ = this._http.post<void>(
         url,
         this._httpOptions
      ).pipe(take(1))

      return await lastValueFrom<void>(request$)
   }

   async sendSubscriptionConfirmationText(
      subscriptionId: number,
      phoneNumber: string
   ): Promise<void> {
      if (!subscriptionId || !phoneNumber) {
         return new Promise((resolve, reject) =>
            reject('No subscriptionId/phoneNumber passed!')
         )
      }

      const url = `${this._subscriptionsApi
         }/${subscriptionId}/notify-subscription-authorization-text?phoneNumber=${encodeURIComponent(
            phoneNumber
         )}`

      const request$ = this._http.post<void>(
         url,
         this._httpOptions
      ).pipe(take(1))

      return await lastValueFrom<void>(request$)
   }

   async createSubscriptionSeat(
      profileId?: number
   ): Promise<ISubscriptionSeat> {
      if (!profileId) {
         return new Promise((resolve, reject) =>
            reject('No profileId passed!')
         )
      }

      const url = `${this._subscriptionsApi
         }/register-with-token?token=${encodeURIComponent(profileId)}`

      //TODO remove
      let subscriptionSeat: ISubscriptionSeat =
         profileId === 1234
            ? ({
               id: 987654,
               profileId: 1234,
               subscriptionId: 654
            } as ISubscriptionSeat)
            : null
      return new Promise(
         (resolve, reject) =>
            profileId === 1234
               ? resolve(subscriptionSeat)
               : reject('You are a LOSER!')
      )


      const request$ = this._http.post<ISubscriptionSeat>(url, this._httpOptions).pipe(take(1))

      return await lastValueFrom<ISubscriptionSeat>(request$)
   }

   registerProfileWithToken(
      token: string,
      profileSubmission: IProfileSubmission
   ): Observable<ISubscription> {
      if (!token || !profileSubmission) {
         throwError(() => new Error('No all token/profileSubmission passed!'))
      }

      //TODO
      //return of(this.FAKE_GOOD_SUB as ISubscription)

      const url = `${this._subscriptionsApi
         }/register-profile-with-token?token=${token}`

      return this._http.post<ISubscription>(
         url,
         profileSubmission,
         this._httpOptions
      )
   }
}
