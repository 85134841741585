import { Component, OnInit, TrackByFunction, ChangeDetectionStrategy, inject } from '@angular/core'
import { NgIf, NgFor } from '@angular/common'
import { toSignal } from '@angular/core/rxjs-interop'
import { ISubscriptionAuthorization } from '@appShared/interfaces/[Model-based]/subscription-authorization.interface'
import {
   AccountsService,
   HybridWebviewInteropService,
   UrlService
} from '@appShared/services'
import { AppFacade } from '@appShared/services/app.facade'
import { SubscriptionStatus_ } from '@appShared/services/lookup/[CodeGen]/subscription-status.domain'
import { AccountPaymentAuthComponent } from './payment-auth/payment-auth.component'
import { PaymentInstrumentDisplayComponent } from '../payment-instrument-display/payment-instrument-display.component'
import { AccountBillingHistoryComponent } from './history/history.component'
import { environment } from '@appEnvironments/environment'
import * as _ from 'lodash'

@Component({
    selector: 'app-account-billing',
    templateUrl: './billing.component.html',
    styleUrls: ['./billing.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, AccountBillingHistoryComponent, NgFor, PaymentInstrumentDisplayComponent, AccountPaymentAuthComponent]
})
export class AccountBillingComponent implements OnInit {
   private _user = inject(AppFacade).currentUser
   canEditBillingInfo = (!this._user().isAdmin && !this._user().isRep)
   subscriptions = this._user()?.profile?.account?.subscriptions || []
   showOnHoldMessage = this.subscriptions.length
      ? this.subscriptions[0].statusCode === SubscriptionStatus_.OnHold
      : false

   private _accountsService = inject(AccountsService)
   subscriptionAuthorizations = toSignal(this._accountsService.activeSubscriptionAuthorizations$)

   private _hybridService = inject(HybridWebviewInteropService)
   isDotNetInteroperable = toSignal(this._hybridService.isDotNetInteroperable$)
   accountBillingUri = `${environment.baseUri}/${environment.routes.member.account.billing.uri}`

   constructor(
      urlService: UrlService
   ) {

      setTimeout(() => { urlService.setHeaderTitle(environment.routes.member.account.billing.title) })
   }

   ngOnInit() {
      this._accountsService.getActiveSubscriptionAuthorizations()
   }

   /*
   * private methods
   * */

   /*
   * public methods
   * */

   trackBySubscriptionAuthId: TrackByFunction<ISubscriptionAuthorization> = (index, subscriptionAuth) => subscriptionAuth.id

   externalNavigate(uri: string) {
      this._hybridService.externalNavigate(uri)
   }
}
