import { ChangeDetectionStrategy, Component, ElementRef, inject, OnInit, ViewChild } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { RouterLink } from '@angular/router'
import { UrlService } from '@appShared/services/url.service'
import { HybridWebviewInteropService } from '@appShared/services/hybrid-webview-interop.service'
import { AppFacade } from '@appShared/services/app.facade'
import { BrightcoveExperiencePlayerDirective } from '@appShared/directives/brightcove-experience-player.directive'
import { environment } from '@appEnvironments/environment'

@Component({
    selector: 'app-content-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [BrightcoveExperiencePlayerDirective, RouterLink]
})
export class DashboardComponent implements OnInit {
   private _hybridService = inject(HybridWebviewInteropService)
   private _urlService = inject(UrlService)
   user = inject(AppFacade).currentUser

   //https://medium.com/@venkateshprasad.devaraj/integrating-brightcove-html5-player-with-angular-2-83a81fd2c8e7
   @ViewChild('brightcoveVideo') brightcoveVideoElement!: ElementRef
   brightcoveClientUserId = this.user().profile.contact.auditId
   brightcoveSettings: any = environment.brightcove
   brightcoveScript = `https://players.brightcove.net/${this.brightcoveSettings.accountId}/experience_${this.brightcoveSettings.experienceId}/live.js`
   brightcoveScriptVideoJS = `https://players.brightcove.net/${this.brightcoveSettings.accountId}/${this.brightcoveSettings.playerId}_default/index.min.js`

   private _dashboardImagesUri = `${environment.publicCdn.uri}/${environment.publicCdn.dashboardImagesFolder}`
   private _currentTime = (new Date()).getTime()
   scheduleMentorImageUri = `${this._dashboardImagesUri}/mentorship.png?=${this._currentTime}`
   coursesImageUri = `${this._dashboardImagesUri}/courses.png?=${this._currentTime}`
   coursesUri = environment.routes.content.courseLibrary.uri

   constructor(
      titleService: Title
   ) {
      titleService.setTitle(environment.routes.title)
   }

   ngOnInit() {
      setTimeout(() => { this._urlService.setHeaderTitle(environment.routes.title) })
   }

   /*
   * private methods
   * */

   /*
   * public methods
   * */

   externalNavigate(uri: string) {
      this._hybridService.externalNavigate(uri)
   }
}
