import { ChangeDetectionStrategy, Component, DestroyRef } from '@angular/core'
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { filter, map, switchMap } from 'rxjs/operators'
import { LeadSection, LeadSections, LeadSection_ } from '@appShared/services/lookup/lead-section'

@Component({
    selector: 'app-member-shell',
    templateUrl: './member-shell.component.html',
    styleUrls: ['./member-shell.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [RouterOutlet]
})
export class MemberShellComponent {
   private _initialLeadSection = LeadSections.find(section => section.code === LeadSection_.Home)
   leadSection = { ...this._initialLeadSection }

   constructor(
      router: Router,
      activatedRoute: ActivatedRoute,
      destroyRef: DestroyRef
   ) {

      router.events
         .pipe(
            filter(routeEvent => routeEvent instanceof NavigationEnd),
            map(() => activatedRoute),
            map(route => {
               while (route.firstChild) route = route.firstChild
               return route
            }),
            switchMap(firstChild => {
               return firstChild?.data
            }),
            map((data: LeadSection) => {
               return data
            }),
            takeUntilDestroyed(destroyRef)
         )
         .subscribe(data => {
            this.leadSection = { ...(data['section'] || this._initialLeadSection as LeadSection) }

         })

         //breadcrumbService.breadCrumbData.subscribe(breadcrumbs => {
         //   console.log('from member-shell')
         //   console.table(breadcrumbs)
         // })
   }
}
