import { ChangeDetectionStrategy, Component, inject, ViewEncapsulation } from '@angular/core'
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router'
import { NgIf } from '@angular/common'
import { AppFacade } from '@appShared/services/app.facade'
import { CommonService } from '@appShared/services/common.service'
import { environment } from '@appEnvironments/environment'

@Component({
    selector: 'app-account-shell',
    templateUrl: './account-shell.component.html',
    styleUrls: ['./account-shell.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [NgIf, RouterLink, RouterLinkActive, RouterOutlet]
})
export class AccountShellComponent {
   private _commonService = inject(CommonService)
   private _appFacade = inject(AppFacade)
   isLocalIdentity = (this._appFacade.currentUser()?.profile?.info.isLocalIdentity || false)
   userHasValidSubscription = this._appFacade.userHasValidSubscription
   accountRoutes = environment.routes.member.account

   /*
   * private methods
   * */

   /*
   * public methods
   * */

   comingSoon(areaName?: string) {
      const message = areaName ? `"${areaName}" feature is coming soon.` : ''
      this._commonService.comingSoon(message)
   }
}
