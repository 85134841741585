import { ChangeDetectionStrategy, Component, effect, inject, OnInit } from '@angular/core'
import { NgClass, NgIf, NgFor, NgStyle } from '@angular/common'
import { Router } from '@angular/router'
import { filter, take } from 'rxjs/operators'
import { SubscriptionStatus_ } from '@appShared/services/lookup/[CodeGen]/subscription-status.domain'
import { AccountsService, RegistrationSite } from '@appShared/services'
import { AppFacade } from '@appShared/services/app.facade'
import { environment } from '@appEnvironments/environment'

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgClass, NgIf, NgFor, NgStyle]
})
export class HomeComponent implements OnInit {
   private _registrationProducts = environment.registrationProducts;
   registrationSites: RegistrationSite[]
   isLoading = true;

   private _currentUser = inject(AppFacade).currentUser

   constructor(
      private _accountsService: AccountsService,
      private _router: Router
   ) {
      effect(() => {
         if (this._currentUser()?.isUser) {
            this._accountsService.getSubscriptions()
         } else {
            this._setRegistrationSites()
            this.isLoading = false
         }
      })
   }

   ngOnInit() {

      this._accountsService.subscriptions$
         .pipe(
            filter(data => !!data), // filter till has data
            take(1) // now that is has filtered data - take 1)
         )
         .subscribe(subscriptions => {
            let doRoute = false

            if (subscriptions?.length) {
               var nonNewSubscriptions = subscriptions.filter(sub => sub.statusCode !== SubscriptionStatus_.New)
               doRoute = !!(nonNewSubscriptions?.length)
            }

            if (doRoute) {
               this._router.navigate(['member/account'])
               return
            }

            this._setRegistrationSites()
         })


   }

   /*
   * private methods
   * */

   private _setRegistrationSites() {
      let registrationProduct = this._registrationProducts.find(
         /* for now (temporarily), we are going to show the Global Academy links */
         registrationProduct => registrationProduct.productSourceId === 'SWA'
      )

      this.registrationSites = [...registrationProduct.registrationSites]
      this.registrationSites.forEach(site => site.uri = site.uri.replace('/registration/posconfirmation', '/account/billingaddress'))

      /* alter link to go to Account/BillingAddress for each site */

      this.isLoading = false
   }

   /*
   * public methods
   * */
}
