
<div class="container mt-5">



   <!--<div *ngIf="isLoading">
      <div class="text-center">
         <span class="fs-4">Retrieving Registration Information...</span>
         <div class="text-center mt-4">
            <span class="fa fa-spinner spinner-splash"></span>
         </div>
      </div>
   </div>-->

   <div [ngClass]="{'d-none':!isLoading}"
        class="text-center mt-4">
      <div class="d-flex justify-content-center align-items-center">

         <div class="d-inline background-box-opaque-rounded shadow p-3">
            <img alt="Southwestern LEAD" src="/assets/images/southwesternlead-brand_small.png" class="img-fluid logo">
         </div>

      </div>
      <span class="fa fa-spinner spinner-splash"></span>
   </div>

   <div class="container mt-5"
        *ngIf="!isLoading">
      <div class="text-center">
         <h2 class="section-heading">To manage your account please access one of the sites below:</h2>
         <hr class="text-site-primary mx-5" />
      </div>

      <div class="d-flex justify-content-center flex-wrap">
         <div *ngFor="let registrationSite of registrationSites"
              class="registration-site border-1 bg-white p-3 m-3 flex-shrink-0 flex-grow-0 shadow text-center d-flex flex-column justify-content-between">
            <div>
               <img [src]="registrationSite.logoUri"
                    [ngStyle]="{'background-color': registrationSite.backgroundColor || defaultBackgroundColor}"
                    alt="{{registrationSite.logoAlt}}"
                    class="img-fluid p-3 rounded-1" />
            </div>
            <p class="text-start text-muted bg-white p-2 px-1">{{registrationSite.description}}</p>
            <a href="{{registrationSite.uri}}" class="btn btn-lg btn-site-primary text-uppercase register-button align-self-center">
               Manage Account
            </a>
         </div>
      </div>
   </div>

   <!--<div *ngIf="(subscriptions$|async) as subscriptions"
        id="divActivePayment" class="ms-3 pt-3">

      <div *ngFor="let subscription of subscriptions">

         <h5>{{subscription.id}} | {{subscription.productId}} | {{subscription.statusCode}}</h5>

      </div>

   </div>-->

</div>
