import { HttpClient } from '@angular/common/http'
import { Injectable, OnDestroy } from '@angular/core'
import { BehaviorSubject, Observable, Subject, lastValueFrom, take, takeUntil, withLatestFrom } from 'rxjs'
import { CommonService } from './common.service'
import { HybridWebviewInteropService } from '@appShared/services/hybrid-webview-interop.service'
import { IContactPartitionProgress } from '@appShared/interfaces/[Model-based]/contact-partition-progress.interface'

@Injectable({ providedIn: 'root' })
export class ContentRegistrationProgressesService implements OnDestroy {
   private _contentRegistrationProgressesApi = '/api/metrics/content-registration-progresses';
   private _defaultContactPartitionProgress = { overallPointsEarned: 0 } as IContactPartitionProgress
   private _contactPartitionProgress: BehaviorSubject<IContactPartitionProgress>
      = new BehaviorSubject<IContactPartitionProgress>(this._defaultContactPartitionProgress);
   private _progressPollingTimeout: ReturnType<typeof setTimeout>
   private _progressPollingDelay = 60000 /* polling progress every minute */
   private _ngDestroyed$ = new Subject()
   private _httpOptions = {}

   public get contactPartitionProgress$(): Observable<IContactPartitionProgress> {
      return this._contactPartitionProgress.asObservable()
   }

   constructor(
      private _hybridService: HybridWebviewInteropService,
      private _httpClient: HttpClient,
      commonService: CommonService
   ) {
      this._httpOptions = commonService.httpOptions()

      //setTimeout(() => { console.log('blah') }, 1000)

      /* polling for Contact Partition Progress */
      this._hybridService.isDotNetInteroperable$
         .pipe(takeUntil(this._ngDestroyed$))
         .subscribe(isDotNetInteroperable => {
            /* if in context of app - quit listening */
            if (isDotNetInteroperable) {
               this.completeNgDestroyed(false/*includeNgDestroyed*/)
               console.log('isDotNetInteroperable (APP) - destroying all listeners for progress polling')
            } else {
               console.log('NOT isDotNetInteroperable (NOT APP) - setup progress polling')
               /* start polling */
               this.contactPartitionProgress()
            }
         })


      /* in case of relay trigger*/
      this._hybridService.relayTriggered$
         .pipe(
            takeUntil(this._ngDestroyed$),
            withLatestFrom(this._hybridService.isDotNetInteroperable$)
         )
         .subscribe(([relayTriggered, isDotNetInteroperable]) => {
            //if NOT in context of app, stop existing polling and start another
            if (!isDotNetInteroperable && relayTriggered) {
               console.log('relay triggered')
               this.stopPolling()
               this.contactPartitionProgress()
            }
         })
   }

   /*
   * private methods
   * */

   private contactPartitionProgress() {
      this.getContactPartitionProgress()
         .pipe(take(1))
         .subscribe(contactPartitionProgress => {
            if (!this._contactPartitionProgress.closed) {
               this._contactPartitionProgress.next(contactPartitionProgress)
               this._progressPollingTimeout = setTimeout(this.contactPartitionProgress.bind(this), this._progressPollingDelay)
            }
         })
   }

   private stopPolling() {
      /* stop polling */
      if (this._progressPollingTimeout) {
         clearTimeout(this._progressPollingTimeout)
      }
   }

   private completeNgDestroyed(includeNgDestroyed) {
      this.stopPolling()

      if (!this._contactPartitionProgress.closed) {
         this._contactPartitionProgress.next(this._defaultContactPartitionProgress)
         this._contactPartitionProgress.complete()
         this._contactPartitionProgress.unsubscribe()
      }

      if (includeNgDestroyed) {
         this._ngDestroyed$.next(true)
         this._ngDestroyed$.complete()
         this._ngDestroyed$.unsubscribe()
      }
   }

   ngOnDestroy() {
      if (!this._ngDestroyed$.closed) {
         this.completeNgDestroyed(true)
      }
   }

   /*
    * public methods
    * */

   async updateProgressRegistration(token: string): Promise<any> {
      const url = `${this._contentRegistrationProgressesApi}/update-registration-progress?token=${token}`

      const request$ = this._httpClient.put(url, null, this._httpOptions).pipe(take(1))

      return lastValueFrom(request$)
   }

   //private _counter: number = 150
   getContactPartitionProgress(): Observable<IContactPartitionProgress> {
      const url = `${this._contentRegistrationProgressesApi}/contact-partition-progress`

      //return of({
      //   overallPointsEarned: this._counter++
      //} as IContactPartitionProgress)

      return this._httpClient.get<IContactPartitionProgress>(url)
   }
}
