import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { Title } from '@angular/platform-browser'
import { UrlService } from '@appShared/services/url.service'
import { HybridWebviewInteropService } from '@appShared/services/hybrid-webview-interop.service'
import { environment } from '@appEnvironments/environment'

@Component({
    selector: 'app-content-resources',
    templateUrl: './resources.component.html',
    styleUrls: ['./resources.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true
})
export class ResourcesComponent implements OnInit {
   contentRoute = environment.routes.content
   subMenu = environment.routes.content.subMenu
   subMenuTitle = this.subMenu.title
   resourceImagesFolder = `${environment.publicCdn.uri}/${environment.publicCdn.resourceImagesFolder}`
   resourceMenuUris = environment.resourceMenuUris
   contentRoutes = environment.routes.content
   //linkTarget$: Observable<string>

   constructor(
      private _urlService: UrlService,
      private _hybridService: HybridWebviewInteropService,
      private _router: Router,
      titleService: Title
   ) {
      titleService.setTitle(`LEAD - ${this.subMenuTitle}`)

      //this.linkTarget$ = this._hybridService.linkTarget$
   }

   ngOnInit() {
      //they don't want header for now since page had a header
      //setTimeout(() => { this._urlService.setHeaderTitle(this.resourcesTitle) })
      setTimeout(() => { this._urlService.setHeaderTitle('') })
   }

   /*
   * private methods
   * */

   /*
   * public methods
   * */

   externalNavigate(uri: string) {
      this._hybridService.externalNavigate(uri)
   }

   navigate(uri) {
      if(uri) this._router.navigate([uri])
   }
}
