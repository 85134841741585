<div class="d-flex justify-content-center">

   <div class="area">

      <div class="my-3 mt-0 p-3 pt-2 background-box-opaque-rounded shadow">

         <fieldset>
            <legend class="text-uppercase">Notifications</legend>

            <form [formGroup]="notificationsForm"
                  autocomplete="off"
                  novalidate class="">

               <div class="fs-5 pt-4 text-wrap">
                  Send me a
                  <span class="text-site-primary">Weekly Parent Newsletter</span>
                  for the following sites:
               </div>

               <div class="ms-3">

                  <div class="row" *ngFor="let site of sites">

                     <div class="col">
                        <div class="pretty p-icon">
                           <input type="checkbox"
                                  id="parentNewsletter{{site.literal}}"
                                  (change)="setNotification(site, $event.target.checked)" />
                           <div class="state p-success">
                              <i class="icon material-icons mdi mdi-check"></i>
                              <label>{{site.description}}</label>
                           </div>
                        </div>
                     </div>

                  </div>

               </div>

            </form>
            
         </fieldset>

      </div>

   </div>

</div>
