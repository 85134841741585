<div *ngIf="!isDotNetInteroperable()"
     class="col mx-auto">

   <div *ngIf="showOnHoldMessage"
        class="text-danger fs-5 fw-bold mb-3">
      <em>* Your LEAD membership is currently on hold due to an issue with your latest payment. Please verify your payment method below.</em>
   </div>

   <app-account-billing-history *ngIf="canEditBillingInfo"></app-account-billing-history>

   <div class="mt-0 p-3 pt-2 background-box-opaque-rounded shadow" *ngIf="canEditBillingInfo">

      <fieldset>
         <legend>Payment Method</legend>

         <div *ngIf="subscriptionAuthorizations()"
              id="divActivePayment" class="ms-3 pt-3 mb-3">

            <!-- if multiple subscriptions were supported we would need edit for each subauth (edit would call subscriptionsconroller method  -->
            <div *ngFor="let subscriptionAuthorization of subscriptionAuthorizations(); trackBy: trackBySubscriptionAuthId">

               <h5>{{subscriptionAuthorization.subscription.product.displayName}}: {{subscriptionAuthorization.paymentInstrument.info.currencyShortDescription || '$'}}{{subscriptionAuthorization.amount}}</h5>

               <app-payment-instrument-display [paymentInstrument]="subscriptionAuthorization.paymentInstrument"></app-payment-instrument-display>

            </div>

         </div>

         <app-account-payment-auth></app-account-payment-auth>

      </fieldset>

   </div>

   <div class="mt-0 p-3 pt-2 background-box-opaque-rounded shadow" *ngIf="!canEditBillingInfo">
      Updating profile & billing information is not supported here for this account type.
   </div>

</div>
<div *ngIf="isDotNetInteroperable()" class="text-center pt-4 fs-3">
   Visit <span (click)="externalNavigate(accountBillingUri)" class="text-site-secondary fw-bold pointable">southwesternlead.com</span> on the web to manage your settings
</div>
