import { ChangeDetectionStrategy, Component, Input, Signal } from '@angular/core'
import { IContactAchievementInfo } from '@appShared/interfaces/[Model-based]/contact-achievement-info.interface'
import { NgFor } from '@angular/common';

@Component({
    selector: 'app-mentor-badges',
    templateUrl: './badges.component.html',
    styleUrls: ['./badges.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgFor]
})
export class MentorBadgesComponent {
   @Input() contactMentorAchievements?: Signal<IContactAchievementInfo[]>

   /*
   * private methods
   * */

   /*
   * public methods
   * */

   /* might allow to go to the course in the future */
   //goToRoute(progressBadge: IProgressBadge) {
   //   console.log('progressBadge:', progressBadge)
   //}
}
