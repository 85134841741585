<div class="site-container" [ngClass]="{'hybrid-view': isDotNetInteroperable()}">
   <app-public-header *ngIf="!user()"></app-public-header>

   <app-internal-header *ngIf="user() && !isDotNetInteroperable()"
                        [leadSection]="leadSection" class="flex-grow-0 flex-shrink-1 flex-basis-auto"></app-internal-header>

   <section>
      <router-outlet></router-outlet>
   </section>

   <app-site-footer *ngIf="!isDotNetInteroperable()" class="flex-grow-0 flex-shrink-1"></app-site-footer>
</div>
