import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { UrlService } from '@appShared/services/url.service'
import { ContainerListComponent } from '@appContent/components/container-list/container-list.component'
import { environment } from '@appEnvironments/environment'

@Component({
   selector: 'app-content-course-library',
   templateUrl: './course-library.component.html',
   styleUrls: ['./course-library.component.less'],
   changeDetection: ChangeDetectionStrategy.OnPush,
   standalone: true,
   imports: [ContainerListComponent]
})
export class CourseLibraryComponent implements OnInit {
   private _urlService = inject(UrlService)
   courseLibrary = environment.routes.content.courseLibrary

   constructor(
      titleService: Title
   ) {
      titleService.setTitle(`LEAD - ${this.courseLibrary.title}`)
   }

   ngOnInit() {
      //they don't want header for now since page had a header
      //setTimeout(() => { this._urlService.setHeaderTitle(this.courseLibrary.title) })

      setTimeout(() => { this._urlService.setHeaderTitle('') })
   }

   /*
   * private methods
   * */

   /*
   * public methods
   * */
}
