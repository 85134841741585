import { Injectable, signal } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs'
import { toSignal } from '@angular/core/rxjs-interop'
import { ISubscription } from '@appShared/interfaces/[Model-based]/subscription.interface'

export interface RegistrationSite {
   uri: string;
   uriSuffix: string;
   appendToken: boolean;
   appendSubscriptionId: boolean;
   logoUri: string;
   logoAlt: string;
   description: string;
   backgroundColor: string;
   color: string;
}

export interface RegistrationProduct {
   productSourceId: string;
   showLandingIfSingle: boolean;
   registrationSites: RegistrationSite[];
}

@Injectable({
   providedIn: 'root'
})
export class RegistrationsService {
   registrationToken = signal<string|undefined>(undefined)

   private _registrationSubscription: BehaviorSubject<ISubscription> =
      new BehaviorSubject<ISubscription>(null)
   public get registrationSubscription$(): Observable<ISubscription> {
      return this._registrationSubscription.asObservable()
   }

   private _lastPaymentFailed: BehaviorSubject<boolean> =
      new BehaviorSubject<boolean>(false)

   private _currentError: BehaviorSubject<string> = new BehaviorSubject<string>(null)
   public get currentError$(): Observable<string> {
      return this._currentError.asObservable()
   }

   public get lastPaymentFailed$(): Observable<boolean> {
      return this._lastPaymentFailed.asObservable()
   }

   setRegistrationToken(registrationToken: string): void {
      this.registrationToken.set(registrationToken)
   }

   setRegistrationSubscription(currentSubscription: ISubscription): void {
      this._registrationSubscription.next(currentSubscription)
   }

   setError(currentError: string): void {
      this._currentError.next(currentError)
   }

   setLastPaymentFailed(paymentFailed: boolean): void {
      this._lastPaymentFailed.next(paymentFailed)
   }
}
