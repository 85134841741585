<div *ngIf="subscriptionPaymentTransactions()"
     class="my-3 mt-0 mb-5 p-3 pt-2 background-box-opaque-rounded shadow">

   <fieldset *ngIf="!isLoading && subscriptionPaymentTransactions()?.length">
      <legend>Billing History</legend>

      <table class="table table-striped sansserif_font">

         <thead>
            <tr>
               <th class="payment-type">Source</th>
               <th class="payment-effective">Effective</th>
               <th class="payment-status">Status</th>
               <th class='text-end'>Amount</th>
            </tr>
         </thead>

         <tbody>
            <tr *ngFor="let subscriptionPaymentTransaction of subscriptionPaymentTransactions(); trackBy: trackByPaymentTransactionId">
               <td class="payment-type">
                  <app-payment-instrument-display [paymentInstrument]="subscriptionPaymentTransaction.paymentTransaction.paymentInstrument"></app-payment-instrument-display>
               </td>
               <td style="white-space: nowrap">{{subscriptionPaymentTransaction.paymentTransaction.effective|date:'MM/dd/yy'}}</td>
               <td class="payment-status text-success"
                   [ngClass]="{'text-danger': !!(subscriptionPaymentTransaction.paymentTransaction.statusCode)}">
                  {{subscriptionPaymentTransaction.paymentTransaction.responseMessage}}
               </td>
               <td class='text-end'>{{subscriptionPaymentTransaction.paymentTransaction.amount|currency}}</td>
            </tr>
         </tbody>

      </table>

   </fieldset>

</div>
