import { Component, EventEmitter, Input, OnInit, Output, TrackByFunction, ChangeDetectionStrategy, inject, computed } from '@angular/core'
import { NgIf, NgFor } from '@angular/common'
import { finalize } from 'rxjs'
import { toObservable } from '@angular/core/rxjs-interop'
import { ColumnMode } from '@swimlane/ngx-datatable'
import { AccountsService } from '@appShared/services/accounts.service'
import { AppFacade } from '@appShared/services/app.facade'
import { CommonService } from '@appShared/services/common.service'
import {
   ConfirmService,
   ConfirmOptions
} from '@appShared/components/confirm-modal-and-service'
import { IProfile } from '@appShared/interfaces/[Model-based]/profile.interface'
import { SubscriptionStatus_ } from '@appShared/services/lookup/[CodeGen]/subscription-status.domain'
import { ConfirmTemplateDirective, ConfirmModalComponent } from '@appShared/components/confirm-modal-and-service'

@Component({
    selector: 'app-account-students-list',
    templateUrl: './students-list.component.html',
    styleUrls: ['./students-list.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, NgFor, ConfirmTemplateDirective, ConfirmModalComponent]
})
export class AccountStudentsListComponent implements OnInit {
   @Input() canEdit: boolean
   @Input() refreshGrid: boolean
   @Output() profileSelected = new EventEmitter()
   @Output() profileCreatedUpdated = new EventEmitter()
   @Output() profileDeleted = new EventEmitter()

   selectedProfile: IProfile
   isSubmitting: boolean
   /* grid */
   ColumnMode = ColumnMode

   private _appFacade = inject(AppFacade)
   private _currentUser = inject(AppFacade).currentUser
   userRoleCanEdit = computed(() => {
      const currentUser = this._currentUser()

      return !currentUser.isAdmin && !currentUser.isRep
   })
   userHasValidSubscription = this._appFacade.userHasValidSubscription

   private _accountsService = inject(AccountsService)
   profiles = this._accountsService.profiles
   showWelcome = computed(() => this.userHasValidSubscription() && !this.profiles()?.length)

   private _confirmService = inject(ConfirmService)
   private _commonService = inject(CommonService)

   ngOnInit(): void {
      this._loadProfiles()
   }

   /*
    * private methods
    * */

   private _showProfileInfo(): void {
      this.profileSelected.emit(this.selectedProfile)
   }

   private _loadProfiles() {
      this._accountsService.getProfiles(/*includeAccountOwner*/false, this._currentUser())
   }

   /*
    * public methods
    * */

   trackByProfileId: TrackByFunction<IProfile> = (index, profile) => profile.id

   clearForm(): void {
      this.selectedProfile = null
      this._showProfileInfo()
   }

   getSubscriptionSeats(): void {
      this._loadProfiles()
   }

   addProfile(profile?: IProfile): void {
      this.selectedProfile =
         profile || this._accountsService.getNewProfile()

      this._showProfileInfo()
   }

   editProfile(profile: IProfile): void {
      if (profile) {
         this.addProfile(profile)
      }
   }

   deleteProfile(profile: IProfile): void {
      if (profile) {
         this._confirmService
            .confirm({
               title: 'Confirm Delete!',
               message: `You are deleting student information for<br/><strong class="pl-4 text-site-primary">-
                     ${profile.contact.firstName}
                     ${profile.contact.lastName}
                     </strong><br/><br/>
                     Are you sure?`
            } as ConfirmOptions)
            .then(() => {
               this.isSubmitting = true
               this.selectedProfile = null
               this._showProfileInfo()

               this._accountsService
                  .deleteProfile(profile.id)
                  .then(() => {
                     this._loadProfiles()
                     this._commonService.messageUser(
                        'User deleted!'
                     )
                     this.clearForm()
                     this.profileDeleted.emit()
                  })
                  .catch(() => {
                     /* Do Nothing */
                  })
                  .finally(() => (this.isSubmitting = false))
            })
            .catch(() => {
               /* Do Nothing */
            })
      }
   }
}
