<div class="d-flex justify-content-around flex-wrap">
   <!--class="d-flex flex-column align-content-center "-->
   <div class="area">

      <div class="my-3 mt-0 p-3 pt-2 background-box-opaque-rounded shadow" *ngIf="!canEditProfileInfo">
         Updating profile & billing information is not supported here for this account type.
      </div>

      <div class="my-3 mt-0 p-3 pt-2 background-box-opaque-rounded shadow" *ngIf="canEditProfileInfo">
         <form [formGroup]="profileSubmissionForm"
               (ngSubmit)="updateProfile()"
               autocomplete="off"
               novalidate class="">
            <fieldset>
               <legend class="text-uppercase text-nowrap">Profile Information</legend>

               <div class="row">

                  <div class="col"
                       [ngClass]="{'error': firstName.invalid && (firstName.dirty || firstName.touched || mouseoverProfileSubmit)}">

                     <label for="firstName" class="form-label">First Name</label>
                     <em *ngIf="firstName.invalid && (firstName.dirty || firstName.touched || mouseoverProfileSubmit)" class="error-msg">
                        Required
                     </em>
                     <input formControlName="firstName"
                            id="firstName"
                            type="text"
                            class="form-control"
                            required />
                  </div>

               </div>

               <div class="row">

                  <div class="col"
                       [ngClass]="{'error': lastName.invalid && (lastName.dirty || lastName.touched || mouseoverProfileSubmit)}">
                     <label for="lastName" class="form-label">Last Name</label>
                     <em *ngIf="lastName.invalid && (lastName.dirty || lastName.touched || mouseoverProfileSubmit)" class="error-msg">
                        Required
                     </em>
                     <input formControlName="lastName"
                            id="lastName"
                            type="text"
                            class="form-control"
                            required />
                  </div>

               </div>

               <div class="row">

                  <div class="col">

                     <div [ngClass]="{'error': emailAddress.invalid && (emailAddress.dirty || emailAddress.touched || mouseoverProfileSubmit) }"
                          *ngIf="!isLocalIdentity">
                        <label for="emailAddress" class="form-label">
                           Username/Email Address (for sign-in)

                        </label>
                        <em *ngIf="emailAddress.invalid && (emailAddress.dirty || emailAddress.touched || mouseoverProfileSubmit)" class="error-msg">
                           <span *ngIf="emailAddress.errors?.required">
                              Required
                           </span>
                           <span *ngIf="emailAddress.errors?.pattern">
                              Enter valid email
                           </span>
                        </em>
                        <input formControlName="emailAddress"
                               id="emailAddress"
                               type="text"
                               class="form-control"
                               placeholder="Email Address"
                               required />
                     </div>

                     <div [ngClass]="{'error': nickname.invalid && (nickname.dirty || nickname.touched || mouseoverProfileSubmit) }"
                          *ngIf="isLocalIdentity">
                        <label for="nicknameInput" class="form-label">
                           Username (for sign-in)
                        </label>
                        <em *ngIf="nickname.invalid && (nickname.dirty || nickname.touched || mouseoverProfileSubmit)" class="error-msg">
                           <span *ngIf="nickname.errors?.required">
                              Required
                           </span>
                           <span *ngIf="nickname.errors?.pattern">
                              {{nicknameMessage}}
                           </span>
                        </em>
                        <input formControlName="nickname"
                               id="nicknameInput"
                               type="text"
                               maxlength="16"
                               [textMask]="{mask: nicknameMask, guide: false}"
                               class="form-control"
                               placeholder="Nickname"
                               required />
                     </div>

                  </div>

               </div>

               <div class="row">

                  <div class="col text-center">

                     <span (mouseenter)="mouseoverProfileSubmit=true" (mouseleave)="mouseoverProfileSubmit=false">
                        <app-button [buttonConfig]="{textChange: ['Update']}"
                                    [isLoading]="isSubmitting"
                                    [disabled]="!profileSubmissionForm.valid || isSubmitting"
                                    (textBtnClickEmt)="updateProfile()"></app-button>
                     </span>

                  </div>

               </div>

            </fieldset>
         </form>
      </div>

   </div>

   <div class="area" *ngIf="canEditProfileInfo">

      <div class="my-3 mt-0 p-3 pt-2 background-box-opaque-rounded shadow">
         <app-reset-password [isReset]="true"></app-reset-password>
      </div>

   </div>

</div>
