import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonService } from '@appShared/services/common.service';
import {
   SiteType,
   SiteTypes,
   SiteType_
} from '@appShared/services/lookup/site-type';
import { ToastrType } from '@appShared/services/toastr.service';
import { NgFor } from '@angular/common';

@Component({
    selector: 'app-account-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.less'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgFor]
})
export class AccountNotificationsComponent implements OnInit {
   isSubmitting = false;
   mouseoverNotificationsSubmit = false;
   sites = SiteTypes.filter(type =>
      [
         SiteType_.Advantage4Kids,
         SiteType_.Advantage4Teens,
         SiteType_.Skwids
      ].includes(type.code)
   );

   /* form/form-fields */
   notificationsForm: FormGroup;
   parentNewsletterSkwids: FormControl;
   parentNewsletterAdv4Kids: FormControl;
   parentNewsletterAdv4Teens: FormControl;

   constructor(
      private _commonService: CommonService
   ) {}

   ngOnInit() {
      this._createFormGroup()
   }

   /*
   * private methods
   * */

   private _createFormGroup(): void {
      this.parentNewsletterSkwids = new FormControl(null, Validators.required);
      this.parentNewsletterAdv4Kids = new FormControl(null, [
         Validators.required,
         Validators.pattern(this._commonService.regexp().password)
      ]);
      this.parentNewsletterAdv4Teens = new FormControl(null, [
         Validators.required,
         Validators.pattern(this._commonService.regexp().password)
      ]);

      this.notificationsForm = new FormGroup({
         parentNewsletterSkwids: this.parentNewsletterSkwids,
         parentNewsletterAdv4Kids: this.parentNewsletterAdv4Kids,
         parentNewsletterAdv4Teens: this.parentNewsletterAdv4Teens
      });
   }

   /*
   * public methods
   * */

   setNotification(site: SiteType, includeNewsletter?: boolean) {
      if (site) {
         this._commonService.messageUser(
            `<div class="text-center fs-5 text-uppercase">
            You have been ${
               includeNewsletter ? 'added to' : 'removed from'
            } the ${site.description} Weekly Parent Newsletter
         </div>`,
            null,
            includeNewsletter ? ToastrType.success : ToastrType.info
         );
      }
   }
}
