import { Inject, Injectable, InjectionToken, NgZone } from "@angular/core"
import { Router } from '@angular/router'
import { BehaviorSubject, Observable, take, tap } from 'rxjs'
import { CommonService } from '@appShared/services/common.service'
import { ContainersService } from '@appContent/shared/services/containers.service'
import { ToastrType } from '@appShared/services/toastr.service'
import { IContact } from '@appShared/interfaces/[Model-based]/contact.interface'
import {
   IHybridNavigation,
   IRelayedActivity,
   IRelayedActivityType,
   IUserInfo
} from '@appShared/interfaces/[HybridInterface-based]'
import * as _ from 'lodash'
import { environment } from '@appEnvironments/environment'

export const HYBRID_WEBVIEW_NAME = 'HybridWebView'
export let HYBRID_WEBVIEW_TOKEN = new InjectionToken<object>(HYBRID_WEBVIEW_NAME)

@Injectable({ providedIn: 'root' })
export class HybridWebviewInteropService {
   private _hybridNotExistError = `- ${HYBRID_WEBVIEW_NAME} not detected...`
   private _hybridAssertMethod = 'AssertInteroperability'
   private _isDotNetInteroperable$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
   private _hybridNavigation: IHybridNavigation
   private _routes = environment.routes
   private _relayTriggered$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
   private _linkTarget$: BehaviorSubject<string> = new BehaviorSubject<string>('_blank');
   relayedActivityType: IRelayedActivityType

   public get isDotNetInteroperable$(): Observable<boolean> {
      return this._isDotNetInteroperable$.asObservable()
         .pipe(
            /*
              certain links in app context need to target "_self",
              app will catch them and send to outside browser
            */
            tap(isDotNetInteroperable => this._linkTarget$.next(isDotNetInteroperable ? '' : '_blank'))
         )
   }

   public get relayTriggered$(): Observable<boolean> {
      return this._relayTriggered$.asObservable()
   }

   public get linkTarget$(): Observable<string> {
      return this._linkTarget$.asObservable()
   }

   constructor(
      @Inject(HYBRID_WEBVIEW_TOKEN) private _hybridWebView: any,
      private _commonService: CommonService,
      private _containersService: ContainersService,
      private _router: Router,
      private _window: Window,
      private _zone: NgZone) {

      this._assertDotNetInterop()
   }

   /*
   * private methods
   * */

   private _assertDotNetInterop() {

      console.log(`************ ${HYBRID_WEBVIEW_NAME} service ************`)
      console.group()

      //TESTING - comment back out
      //setTimeout(() => this._isDotNetInteroperable$.next(true))
      this._callToDotNet({ method: this._hybridAssertMethod }, true /*excludeCheckForInteropability*/)

      const _clientInteropName = 'JavaScriptClientInterop'

      // create singleton on client
      this._window[_clientInteropName] = {
         navigateToRoute: this.navigateToRoute.bind(this),
         overrideIsDotNetInteroperable: this.overrideIsDotNetInteroperable.bind(this),
         setNavigation: this.setNavigation.bind(this),
         setRelayedActivityMapping: this.setRelayedActivityMapping.bind(this),
         showError: this.showError.bind(this),
         showInfo: this.showInfo.bind(this),
         showSuccess: this.showSuccess.bind(this),
         showWarning: this.showWarning.bind(this),
         signIn: this.signIn.bind(this),
         signOut: this.signOut.bind(this)
      }

      console.log(`- ${_clientInteropName} available on client...`)
      console.groupEnd()
      console.log('***********************************************')
   }

   private _showNotification(args: any, type?: ToastrType) {
      console.log('call from app: _showNotification')

      const argsIsObject = _.isObject(args)
      const argsIsString = _.isString(args)

      if (!args || (argsIsObject && !args['msg'])) {
         console.error('No args/msg passed for notification')
         return
      } else if (!argsIsObject && !argsIsString) {
         console.error('No args/msg passed for notification')
         return
      } else if (argsIsString) {
         args = { msg: args }
      }

      args.type = args.type || type || ToastrType.success

      this._zone.run(() => {
         this._commonService.messageUser(args.msg, args?.title, args.type)
      })
   }

   private /*async*/ _callToDotNet(args?: any, excludeCheckForInteropability?: boolean): void {

      this.isDotNetInteroperable$
         .pipe(take(1))
         .subscribe(isDotNetInteroperable => {
            if ((isDotNetInteroperable || excludeCheckForInteropability)
               && this._hybridWebView?.SendInvokeMessageToDotNet && args?.method) {

               try {
                  //return await this._hybridWebView.SendInvokeMessageToDotNet(args.method, args.params)
                  this._hybridWebView.SendInvokeMessageToDotNet(args.method, args.params)
                  setTimeout(() => this._isDotNetInteroperable$.next(true))

                  if (args.method === this._hybridAssertMethod) {
                     console.log(`- ${HYBRID_WEBVIEW_NAME} connection to app succeeded...`)
                  }
               } catch (err) {
                  const errorMessage = err?.message || err
                  if (errorMessage === 'hybridWebViewHost is not defined') {
                     console.info(this._hybridNotExistError)
                  } else {
                     console.error(errorMessage || 'Call to app could not be dispatched (no matching method signature)...')
                  }
               }

            }
         })
   }

   /*
    * public "CALL TO APP" methods
    * */

   clearUserInfo() {
      this._callToDotNet({ method: 'ClearUserInfo' })
   }

   integrateUserInfo(contact: IContact) {

      if (contact) {
         const userInfo: IUserInfo = {
            contactId: contact.id,
            contactFirstName: contact.firstName,
            contactLastName: contact.lastName
         }
         this._callToDotNet({ method: 'IntegrateUserInfo', params: userInfo })
      }
   }

   relayActivity(contact: IContact, activityTypeCode: number) {
      /* for any listeners that something happened (e.g. polling progress) */
      this._relayTriggered$.next(true)

      if (contact && activityTypeCode) {
         const relayedActivity: IRelayedActivity = {
            contactId: contact.id,
            activityTypeCode: activityTypeCode
         }

         this._callToDotNet({ method: 'RelayActivity', params: relayedActivity })
      }
   }

   externalNavigate(uri: string) {
      if (uri.startsWith('http')) {
         this.isDotNetInteroperable$
            .pipe(take(1))
            .subscribe(isDotNetInteroperable => {
               if (isDotNetInteroperable) {
                  this._callToDotNet({ method: 'Navigate', params: uri })
               } else {
                  this._window.open(uri, '_blank')
               }
            })
      } else {
         console.log(`externalNavigate called with invalid uri: ${uri}`)
      }
   }

   /*
    * public "CALL TO SITE" methods
    * */

   overrideIsDotNetInteroperable(on: boolean) {
      console.log('overrideIsDotNetInteroperable:', (new Date()).getTime())
      this._isDotNetInteroperable$.next(on)
   }

   setNavigation(hybridNavigation: IHybridNavigation): void {
      console.log('call from app: setNavigation')

      this._hybridNavigation = hybridNavigation
   }

   setRelayedActivityMapping(relayedActivityType: IRelayedActivityType): void {
      console.log('call from app: setRelayedActivityMapping')

      this.relayedActivityType = relayedActivityType
   }

   navigateToRoute(route: number): void {
      console.log('call from app: navigateToRoute')

      const contentRoutes = this._routes.content
      const memberRoutes = this._routes.member
      switch (route) {
         case this._hybridNavigation.home:
         case this._hybridNavigation.signIn:
            this._zone.run(() => {
               this._router.navigate([`${contentRoutes.uri}`])
            })
            break
         case this._hybridNavigation.videoLibrary:
            this._zone.run(() => {
               this._router.navigate([`${contentRoutes.videoLibrary.uri}`])
            })
            break
         case this._hybridNavigation.courseLibrary:
            this._zone.run(() => {
               //this._router.navigate([`${contentRoutes.courseLibrary.uri}`])
               /* for now, they want the app course menu to go to the next course */
               this._containersService.goToNextInProgressCourse()
            })
            break
         case this._hybridNavigation.progress:
            this._zone.run(() => {
               this._router.navigate([`${contentRoutes.progress.uri}`])
            })
            break
         case this._hybridNavigation.subMenu:
            this._zone.run(() => {
               this._router.navigate([`${contentRoutes.subMenu.uri}`])
            })
            break
         case this._hybridNavigation.account:
            this._zone.run(() => {
               this._router.navigate([`${memberRoutes.account.uri}`])
            })
            break
         case this._hybridNavigation.accountProfile:
            this._zone.run(() => {
               this._router.navigate([`${memberRoutes.account.profile.uri}`])
            })
            break
         case this._hybridNavigation.signOut:
            this._zone.run(() => {
               this._commonService.logout()
            })
            break
         default:
            console.error(`Not a supported route: '${route}'`)
      }
   }

   showError(args): void {
      this._showNotification(args, ToastrType.error)
   }

   showInfo(args): void {
      this._showNotification(args, ToastrType.info)
   }

   showSuccess(args): void {
      this._showNotification(args, ToastrType.success)
   }

   showWarning(args): void {
      this._showNotification(args, ToastrType.warning)
   }

   signIn(): void {
      this.navigateToRoute(this._hybridNavigation.signIn)
   }

   signOut(): void {
      this.navigateToRoute(this._hybridNavigation.signOut)
   }
}
