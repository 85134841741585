<div class="mx-auto">
   <div class="d-none d-sm-flex align-items-center my-3 mt-0 p-3 text-white background-box-opaque-rounded shadow-sm">
      <img class="me-3 logo" src="/assets/images/LEAD-logo-blue-medium.png" alt=""><!-- width="48" height="38"-->
      <!--<div class="sidebar-heading border-bottom bg-light">
         <img src="/assets/images/LEADLogo-medium.png" alt="LEAD - Account" class="img-fluid mx-auto d-block">
      </div>-->
      <div class="lh-1">
         <h1 class="h6 mb-0 text-primary lh-1">Life Skills & Leadership Development for Young People</h1>
      </div>
   </div>

   <div class="p-3 pt-0 background-box-opaque-rounded">
      <!--<h6 class="border-bottom pb-2 mb-0">Recent updates</h6>-->

      <div class="row site-links">

         <div class="col-sm-6">

            <div class="d-flex text-muted pt-3">
               <a routerLink="/{{accountRoutes.profile.uri}}" class="flex-shrink-0 me-2" title="Profile">
                  <svg class="bd-placeholder-img bi" width="32" height="32" role="img" fill="#0484ba" style="color: #0484ba" aria-label="Profile"><use xlink:href="#people-circle" /></svg>
               </a>
               <p class="pb-3 mb-0 small lh-sm border-bottom">
                  <a routerLink="/{{accountRoutes.profile.uri}}" class="d-block fw-bold site-page-link" title="Profile">
                     Profile Information
                     <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                     </svg>
                  </a>
                  Update your name, email, and password.
               </p>
            </div>

            <!--<div class="d-flex text-muted pt-3">
               <a [routerLink]="['/member/account/notifications']" class="flex-shrink-0 me-2" title="Notifications">
                  <svg class="bd-placeholder-img bi" width="32" height="32" role="img" fill="#e83e8c" aria-label="Notifications"><use xlink:href="#alert" /></svg>
               </a>

               <p class="pb-3 mb-0 small lh-sm border-bottom">
                  <a [routerLink]="['/member/account/notifications']" class="d-block fw-bold site-page-link" title="Notifications">
                     Notifications
                     <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                     </svg>
                  </a>
                  Do some things that are very cool.
               </p>
            </div>-->
            <!--<div class="d-flex text-muted pt-3">
               <a href="#" class="flex-shrink-0 me-2" title="Connections">
                  <svg class="bd-placeholder-img bi" width="32" height="32" role="img" fill="#6f42c1" aria-label="Connections"><use xlink:href="#diagram" /></svg>
               </a>

               <p class="pb-3 mb-0 small lh-sm border-bottom">
                  <a href="#" class="d-block fw-bold site-page-link" title="Connections">
                     Connections
                     <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                     </svg>
                  </a>
                  Do some things that are very cool.
               </p>
            </div>-->

            <div class="d-flex text-muted pt-3">
               <a routerLink="/{{accountRoutes.users.uri}}" class="flex-shrink-0 me-2" title="Users">
                  <svg class="bd-placeholder-img bi" width="32" height="32" role="img" fill="green" style="color:green!important" aria-label="Users"><use xlink:href="#people" /></svg>
               </a>

               <p class="pb-3 mb-0 small lh-sm border-bottom">
                  <a routerLink="/{{accountRoutes.users.uri}}" class="d-block fw-bold site-page-link" title="Users">
                     Users
                     <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                     </svg>
                  </a>
                  Add/Update users for this account.
               </p>
            </div>

            <div class="d-flex text-muted pt-3">
               <a routerLink="/{{accountRoutes.billing.uri}}" class="flex-shrink-0 me-2" title="Billing">
                  <svg class="bd-placeholder-img bi" width="32" height="32" role="img" fill="black" style="color:black!important" aria-label="Billing"><use xlink:href="#credit-card" /></svg>
               </a>

               <p class="pb-3 mb-0 small lh-sm border-bottom">
                  <a routerLink="/{{accountRoutes.billing.uri}}" class="d-block fw-bold site-page-link" title="Billing">
                     Billing
                     <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                     </svg>
                  </a>
                  Add payment and/or update payment method for this account.
               </p>
            </div>

            <!--<div *ngIf="!isAccountOnlyMode"
                 class="d-flex text-muted pt-3">
               <a [routerLink]="['/member/account/progress']" class="flex-shrink-0 me-2" title="Awards">
                  <svg class="bd-placeholder-img bi" width="32" height="32" role="img" fill="#ffc107"style="color:#ffc107" aria-label="Awards"><use xlink:href="#trophy" /></svg>
               </a>

               <p class="pb-3 mb-0 small lh-sm border-bottom">
                  <a [routerLink]="['/member/account/progress']"
                     class="d-block fw-bold site-page-link" title="Awards">
                     Awards
                     <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                     </svg>
                  </a>
                  View the Awards for all Users
               </p>
            </div>-->

         </div>

         <div class="col-sm-6">

            <div class="d-flex text-muted pt-3">
               <a routerLink="/{{accountRoutes.membership.uri}}" class="flex-shrink-0 me-2" title="Membership">
                  <svg class="bd-placeholder-img bi" width="32" height="32" role="img" fill="#0484ba" style="color:#0484ba!important" aria-label="Users"><use xlink:href="#membership" /></svg>
               </a>

               <p class="pb-3 mb-0 small lh-sm border-bottom">
                  <a routerLink="/{{accountRoutes.membership.uri}}" class="d-block fw-bold site-page-link" title="Users">
                     Membership
                     <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                     </svg>
                  </a>
                  Update your membership status.
               </p>
            </div>

            <div class="d-flex text-muted pt-3 align-items-center">
               <!--<a [routerLink]="['/member/account/membership']"-->
               <span (click)="logout()"
                     class="flex-shrink-0 me-2" title="Sign Out" style="color: #0484ba">
                  <svg class="bd-placeholder-img bi" width="32" height="32" role="img" fill="indianred" style="color: indianred !important" aria-label="Sign Out"><use xlink:href="#sign-out" /></svg>
               </span>

               <p class="mb-0 small lh-sm">
                  <!--<a [routerLink]="['/member/account/membership']"-->
                  <span (click)="logout()"
                        class="d-block fw-bold site-page-link" title="Sign Out">
                     Sign Out
                  </span>
               </p>
            </div>

            <!--<div class="d-flex text-muted pt-3">
             <a [routerLink]="['/member/account/membership']"
                  class="flex-shrink-0 me-2" title="Membership">
               <svg class="bd-placeholder-img bi" width="32" height="32" role="img" fill="gray" aria-label="Membership"><use xlink:href="#membership" /></svg>
            </a>-->
            <!--<span (click)="comingSoon('Membership')"
                  class="flex-shrink-0 me-2" title="Membership">
               <svg class="bd-placeholder-img bi" width="32" height="32" role="img" fill="gray" aria-label="Membership"><use xlink:href="#membership" /></svg>
            </span>-->
            <!--<p class="pb-3 mb-0 small lh-sm border-bottom">
            <a [routerLink]="['/member/account/membership']"
                  class="d-block fw-bold site-page-link" title="Membership">
               Membership
               <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
               </svg>
            </a>-->
            <!--<span (click)="comingSoon('Membership')"
                  class="d-block fw-bold site-page-link" title="Membership">
               Membership
               <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
               </svg>
            </span>-->
            <!--Coming Soon!
            </p>-->
            <!--</div>-->

         </div>

      </div>
      <!--<small class="d-block text-end mt-3">
         <a href="#">All updates</a>
      </small>-->
   </div>

</div>
