<div class="mt-0 p-3 pt-2 background-box-opaque-rounded shadow membership">
   <div class="d-none d-lg-block text-center bg-gray rounded shadow-sm p-3">
      <img src="/assets/images/LEAD-logo-blue-medium.png" class="logo" alt="">
   </div>

   <div class="d-flex flex-column ">

      <header class="mt-3 align-self-center">

         <div *ngIf="subscriptionIsOnHold"
              class="d-flex flex-column align-items-center justify-content-center text-site-secondary text-center fs-4 fw-bold text-center">
            Your LEAD membership is currently on hold due to an issue with your latest payment.
            <div class="mt-4" [hidden]="isDotNetInteroperable()">
               <a class="btn btn-primary"
                  routerLink="/{{accountBillingUrl}}">Verify Payment Method</a>
            </div>
         </div>

         <div *ngIf="subscriptionIsEnded"
              class="d-flex flex-column align-items-center justify-content-center text-site-secondary text-center fs-4 fw-bold text-center">
            Your LEAD membership has ended.
            <div class="mt-4">
               Please contact your sales representative if you wish to renew your membership.
            </div>
         </div>

         <div *ngIf="subscriptionIsCancelled"
              class="d-flex flex-column align-items-center justify-content-center text-site-secondary text-center fs-4 fw-bold text-center">
            Your LEAD membership has been cancelled.
            <div class="mt-4">
               Please contact your sales representative if you wish to renew your membership.
            </div>
         </div>

         <ng-container *ngIf="!subscriptionIsCancelled && !subscriptionIsOnHold && !subscriptionIsEnded">

            <div *ngIf="subscriptionIsPendingCancellation; else activeOrPaused"
                 class="d-flex flex-column align-items-center justify-content-center text-site-secondary text-center fs-4 fw-bold text-center">
               Your LEAD membership will be cancelled<br /> on {{cancellationEffective|date}}.

               <div class="mt-4" [hidden]="isDotNetInteroperable()">
                  <app-button [buttonConfig]="{textChange: ['Don\'t Cancel -  Resume Membership'], class: 'btn btn-sm btn-success ms-4', svgType: ' '}"
                              [isLoading]="isSubmitting"
                              [disabled]="isSubmitting"
                              (textBtnClickEmt)="undoPendingCancel()"></app-button>
               </div>
            </div>

            <ng-template #activeOrPaused>

               <div *ngIf="!subscriptionIsPaused"
                    class="text-site-secondary text-center fs-4 fw-bold text-center">
                  Your LEAD membership
                  <span *ngIf="subscriptionIsPendingPaused; else updates">
                     will be paused<br />on {{nextPaymentDue|date}} and will
                     remain paused<br />until {{pausedUntil|date}}
                  </span>
                  <ng-template #updates>renews on {{nextPaymentDue|date}}</ng-template>
               </div>

               <div *ngIf="subscriptionIsPaused"
                    class="text-site-secondary text-center fs-4 fw-bold text-center">
                  Your membership is currently paused and will resume on {{pausedUntil|date}}
               </div>

               <div *ngIf="subscriptionIsAnnual"
                    class="pt-2 text-center">
                  If you take no action before the above date, your current plan will
                  become a month-to-month plan that can be cancelled at any time.
               </div>

            </ng-template>

         </ng-container>

         <div *ngIf="isDotNetInteroperable() && !subscriptionIsEnded && !subscriptionIsCancelled" class="text-center pt-4 fs-4 mb-3">
            Visit <span (click)="externalNavigate(accountMembershipUri)" class="text-site-secondary fw-bold pointable">southwesternlead.com</span> on the web to manage your settings
         </div>

      </header>

      <div [hidden]="isDotNetInteroperable()">

         <div class="d-flex justify-content-center align-items-center mt-4 ps-3">
            <div [hidden]="subscriptionIsPendingCancellation || subscriptionIsCancelled || subscriptionIsEnded">
               <div class="pretty p-icon">
                  <input type="checkbox"
                         [formControl]="updateSubscription" />
                  <div class="state p-danger">
                     <i class="icon material-icons mdi mdi-check fs-5"></i>
                     <label class="fs-5">{{updateSubscriptionText}}</label>
                  </div>
               </div>
            </div>
            <app-button [hidden]="!subscriptionIsPendingPaused"
                        [buttonConfig]="{textChange: ['Resume Membership'], class: 'btn btn-sm btn-success ms-4', svgType: ' '}"
                        [isLoading]="isSubmitting"
                        [disabled]="isSubmitting"
                        (textBtnClickEmt)="unPause()"></app-button>
            <app-button [hidden]="!subscriptionIsPaused"
                        [buttonConfig]="{textChange: ['Resume Membership'], class: 'btn btn-sm btn-success ms-4', svgType: ' '}"
                        [isLoading]="isSubmitting"
                        [disabled]="isSubmitting"
                        (textBtnClickEmt)="resume()"></app-button>
         </div>

         <form [formGroup]="subscriptionStatusForm"
               (ngSubmit)="setSubscription()"
               [hidden]="!(updateSubscription$|async)"
               autocomplete="off"
               novalidate class="align-self-center mt-4 subscription-form">

            <div class="d-flex flex-column align-items-center">

               <div class="d-flex flex-column align-items-center"
                    [hidden]="subscriptionIsAnnual">

                  <h5 class="border-top pt-4">Please select from the following options:</h5>

                  <div class="d-flex flex-column gap-2 pt-2">

                     <ng-template ngFor let-newStatusType [ngForOf]="newStatusTypes">

                        <div class="ps-3 pt-2 plan-{{newStatusType.literal}}">
                           <div class="d-flex align-content-center">
                              <div class="position-relative">
                                 <div class="pretty p-icon p-round me-3 fs-1">
                                    <input type="radio"
                                           [formControlName]="newStatusTypeSelectFieldName"
                                           [value]="newStatusType.code"
                                           (change)="setNewStatusStatus(newStatusType)" />
                                    <div class="state p-success">
                                       <i class="icon mdi mdi-check"></i>
                                       <label class="fs-1 bold-title"></label>
                                    </div>
                                 </div>
                              </div>
                              <div class="align-self-center">
                                 <div class="fs-4 lh-1 pt-1">{{newStatusType.description}}</div>
                                 <!--<div class="text-site">{{newStatusType.description}}</div>-->
                              </div>
                           </div>
                        </div>

                     </ng-template>

                  </div>

               </div>

               <h3 *ngIf="newStatusTypeSelect && !newStatusTypeSelect.isCancel"
                   class="mt-4 pt-4 text-center border-top">

                  Your LEAD membership<br />
                  <ng-container *ngIf="subscriptionIsPaused; else futurePause">
                     is paused and will resume<br />on
                  </ng-container>
                  <ng-template #futurePause>
                     will be paused as of {{nextPaymentDue|date}} and
                     will remain paused until
                  </ng-template>
                  {{newStatusTypeSelect.resumeDate|date}}

               </h3>

               <h3 *ngIf="newStatusTypeSelect?.isCancel && !showConfirmCancellation"
                   class="mt-4 pt-4 text-center border-top">
                  Are you sure you want to cancel? Once you do, you will lose all of your progress in the LEAD program.
               </h3>

               <div *ngIf="newStatusTypeSelect?.isCancel && showConfirmCancellation"
                    class="d-flex flex-column align-items-center mt-3 pt-3 border-top">

                  <h5>We hate to see you go! Please select your reason for cancellation to help provide feedback for our team:</h5>

                  <div class="d-flex flex-column gap-2 mt-3">

                     <ng-template ngFor let-cancellationReason [ngForOf]="cancellationReasons">

                        <div class="ps-3 plan-{{cancellationReason.literal}}">
                           <div class="d-flex align-content-center">
                              <div class="position-relative">
                                 <div class="pretty p-icon p-round me-3 fs-1">
                                    <input type="radio"
                                           [formControlName]="cancellationReasonSelectFieldName"
                                           [value]="cancellationReason.code"
                                           (change)="setCancellationReason(cancellationReason)" />
                                    <div class="state p-success">
                                       <i class="icon mdi mdi-check"></i>
                                       <label class="fs-1 bold-title"></label>
                                    </div>
                                 </div>
                              </div>
                              <div class="align-self-center">
                                 <div class="text-site">{{cancellationReason.description}}</div>
                              </div>
                           </div>
                        </div>

                     </ng-template>

                     <textarea [formControl]="cancellationReasonText"
                               [hidden]="!(cancellationReasonSelect?.isOther)"
                               class="ms-5 mt-2"></textarea>

                  </div>

               </div>

               <div id="confirm-buttons" class="d-flex justify-content-center gap-3 mt-4 pt-4">

                  <button class="btn btn-lg btn-danger" (click)="cancelNewSubscriptionStatus()" type="button">Never mind</button>

                  <app-button [buttonConfig]="{textChange: ['Confirm'], class: 'btn btn-lg btn-success', svgType: ' '}"
                              [isLoading]="isSubmitting"
                              [disabled]="!subscriptionStatusForm.valid || isSubmitting"
                              (textBtnClickEmt)="setSubscription()"></app-button>
               </div>

            </div>

         </form>

      </div>

   </div>

</div>
<ng-template app-confirm-simple>
   <app-confirm-modal-component></app-confirm-modal-component>
</ng-template>
