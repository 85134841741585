<div class="container"
     id="account-profile-edit">

   <ng-container *ngIf="userRoleCanEdit(); else invalidRole">

      <ng-container *ngIf="!userHasValidSubscription()">
         <div class="row">
            <div class="col">
               <div class="alert alert-warning fw-bold">
                  ** Unable to add users because LEAD subscription registration has not been completed.
               </div>
            </div>
         </div>
      </ng-container>

      <ng-container *ngIf="showWelcome()">
         <div class="row">
            <div class="col">
               <div class="alert alert-warning fw-bold">Welcome to the LEAD program, add your student profile(s) here before you begin</div>
            </div>
         </div>
      </ng-container>

      <div class="" *ngIf="profiles()?.length">

         <table class="table table-striped table-hover mb-3">
            <thead>
               <tr>
                  <th *ngIf="userHasValidSubscription()" scope="col" colspan="2">Name</th>
                  <th *ngIf="!userHasValidSubscription()" scope="col">Name</th>
               </tr>
            </thead>
            <tbody>
               <tr *ngFor="let profile of profiles(); trackBy: trackByProfileId">
                  <td class="pointable" (click)="editProfile(profile)">{{profile.contact.firstName}} {{profile.contact.lastName}}</td>
                  <td class="text-end" *ngIf="userHasValidSubscription()">
                     <button class="btn btn-sm btn-danger"
                             (click)="deleteProfile(profile)">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                           <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                        </svg>
                     </button>
                  </td>
               </tr>
            </tbody>
         </table>

      </div>

      <ng-container *ngIf="userHasValidSubscription() && !selectedProfile">

         <div class="row">

            <div class="col text-right">
               <button class="btn btn-site-secondary"
                       (click)="addProfile()">
                  <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                     <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                  </svg> Add User
               </button>
            </div>

         </div>

      </ng-container>

   </ng-container>

   <ng-template #invalidRole>

      <div class="row">
         <div class="col">
            <div class="alert alert-warning fw-bold">
               Adding student seats is not supported for this account type.
            </div>
         </div>
      </div>

   </ng-template>

</div>

<ng-template app-confirm-simple>
   <app-confirm-modal-component></app-confirm-modal-component>
</ng-template>

