import { ChangeDetectionStrategy, Component, inject, Input, OnInit } from '@angular/core'
import { NgIf, NgClass } from '@angular/common'
import { FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { AppFacade } from '@appShared/services/app.facade'
import { AuthService } from '@appShared/services/auth.service'
import { CommonService } from '@appShared/services/common.service'
import { MustMatchValidator } from '@appShared/validators/must-match.validator'
import { ButtonComponent } from '@appShared/components/button/button.component'
import { environment } from '@appEnvironments/environment'

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgIf, NgClass, ButtonComponent]
})
export class ResetPasswordComponent implements OnInit {
   @Input() isReset = false
   isSubmitting = false
   mouseoverPasswordSubmit = false

   private _appFacade = inject(AppFacade)
   private _authService = inject(AuthService)
   private _commonService = inject(CommonService)
   private _activatedRoute = inject(ActivatedRoute)
   private _router = inject(Router)

   private _currentRoute = this._router.routerState.snapshot.url
   private _routes = environment.routes
   private _isPostRegistation = !!(this._activatedRoute.snapshot.queryParamMap.get(this._commonService.postRegistrationQueryParam))

   /* form/form-fields */
   passwordForm: FormGroup
   currentPassword: FormControl
   password: FormControl
   passwordMessage = this._commonService.regexp().passwordMessage
   confirmPassword: FormControl

   ngOnInit() {
      this._createFormGroup()
   }

   /*
   * private methods
   * */

   private _createFormGroup(): void {
      this.currentPassword = new FormControl(
         null,
         this.isReset ? Validators.required : null
      )

      this.password = new FormControl(null, [
         Validators.required,
         Validators.pattern(this._commonService.regexp().password),
         MustMatchValidator('confirmPassword', true)
      ])
      this.confirmPassword = new FormControl(null, [
         Validators.required,
         MustMatchValidator('password')
      ])

      this.passwordForm = new FormGroup({
         currentPassword: this.currentPassword,
         password: this.password,
         confirmPassword: this.confirmPassword
      })
   }

   /*
   * public methods
   * */

   updatePassword() {
      if (this.passwordForm.valid) {
         this.isSubmitting = true

         const passwordFormValues = this.passwordForm.value

         this._authService
            .resetPassword(passwordFormValues.password)
            .then(() => {
               this._appFacade.setUserMustResetPassword()

               this._commonService.messageUser(
                  `<div class="text-center fs-5 text-uppercase">Password Updated!</div>`
               )
               if (this._currentRoute.startsWith(`/${this._routes.member.setPassword.uri}`)) {
                  if (this._isPostRegistation) {
                     this._router.navigate([`${this._routes.member.account.users.uri}`])
                  } else {
                     this._router.navigate([`${this._routes.content.uri}`])
                  }
               }

               this.passwordForm.clearValidators()
               this.passwordForm.reset()
            })
            .finally(() => setTimeout(() => (this.isSubmitting = false), 500))
      }
   }
}
