import { mapToCanActivate, Routes } from '@angular/router'
import {
   AuthGuardUserService,
   RegistrationGuardService,
   PublicOnlyGuardService
} from '@appShared/services'
import { IFramePlayerComponent } from '@appShared/components/iframe-player/iframe-player.component'
import {
   LandingComponent,
   PageNotFoundComponent,
   ShellComponent,
} from './containers'
import { MEMBER_ROUTES } from './member/member.routes'
import { environment } from '@appEnvironments/environment'
import { CONTENT_ROUTES } from './content/content.routes'

export const APP_ROUTES: Routes = [
   {
      path: '',
      component: ShellComponent,
      children: [
         {
            path: '',
            canActivate: mapToCanActivate([PublicOnlyGuardService]),
            component: LandingComponent
         },
         ...CONTENT_ROUTES, /* If NOT lazy-loading */
         //{
         //   path: environment.routes.content.baseUri,
         //   canActivate: mapToCanActivate([AuthGuardUserService]),
         //   loadChildren: () =>
         //      import('./content/content.routes').then(mod => mod.CONTENT_ROUTES)
         //},
         {
            path: environment.playerUri,
            canActivate: mapToCanActivate([AuthGuardUserService]),
            component: IFramePlayerComponent
         },
         ...MEMBER_ROUTES, /* If NOT lazy-loading */
         //{
         //   path: environment.routes.member.baseUri,
         //   canActivate: mapToCanActivate([AuthGuardService]),
         //   loadChildren: () =>
         //      import('./member/member.routes').then(routes => routes.MEMBER_ROUTES)
         //},
         //...REGISTRATION_LANDING_ROUTES, /* If NOT lazy-loading */
         {
            path: environment.routes.registrationLanding.baseUri,
            loadComponent: () =>
               import('./containers/registration-landing/registration-landing.component').then(
                  mod => mod.RegistrationLandingComponent
               )
         },
         {
            path: environment.routes.registration.baseUri,
            canActivate: mapToCanActivate([RegistrationGuardService]),
            loadChildren: () => import('./registration/registration.routes').then(
               routes => routes.REGISTRATION_ROUTES
            )
         },
         {
            path: environment.routes.signUp.baseUri,
            canActivate: mapToCanActivate([RegistrationGuardService]),
            loadComponent: () => import('./containers/sign-up/sign-up.component').then(
               mod => mod.SignUpComponent
            )
         },
         {
            path: '404',
            component: PageNotFoundComponent
         },
         { path: '**', redirectTo: '404', pathMatch: 'full' }
      ]
   }
]
