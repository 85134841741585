import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core'
import { NgClass, NgIf } from '@angular/common'
import { FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms'
import { CommonService } from '@appShared/services/common.service';
import { ProfileService } from '@appShared/services/profile.service';
import { Countries, Country, Country_ } from '@appShared/services/lookup/[CodeGen]/country.domain';
import { StateProvince, StateProvinces, StateProvince_ } from '@appShared/services/lookup/[CodeGen]/state-province.domain';
import { IContactAddress, IProfile } from '@appShared/interfaces/[Model-based]';
import { ButtonComponent } from '@appShared/components/button/button.component';
import { NgSelectModule } from '@ng-select/ng-select';

@Component({
    selector: 'app-account-billing-address',
    templateUrl: './address.component.html',
    styleUrls: ['./address.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgClass, NgIf, NgSelectModule, ButtonComponent]
})
export class AccountBillingAddressComponent implements OnInit {
   /**
    * THIS PAGE IS NO LONGER USED
    */
   _profile: IProfile;
   isLoading = false;
   isSubmitting = false;
   mouseoverBillingAddressSubmit = false;

   /* form/form-fields */
   billingAddressForm: FormGroup;
   street: FormControl;
   city: FormControl;
   billingCountriesSelect: FormControl;
   billingCountries = Countries.filter(country => country.code) //filter out (0) uknown;
   billingStatesSelect: FormControl;
   billingStates: StateProvince[];
   standardDomainSearchFn: (
      term: string,
      item: any,
      matchingFieldOverride1?: string,
      matchingFieldOverride2?: string) => boolean;
   billingPostalCode: FormControl;

   constructor(
      private _commonService: CommonService,
      private _profileService: ProfileService
   ) {
      this._createFormGroup()

      this.standardDomainSearchFn = this._commonService.standardDomainSearchFn;
   }

   ngOnInit() {
      this._profile = { id: 1234, contactId: 2345 } as IProfile
      this._createFormGroup();
   }

   /*
   * private methods
   * */

   private _createFormGroup(): void {
      this.street = new FormControl(null, Validators.required);
      this.city = new FormControl(null, Validators.required);
      this.billingCountriesSelect = new FormControl(null, Validators.required);
      this.billingStatesSelect = new FormControl(null, Validators.required);
      this.billingPostalCode = new FormControl(null, Validators.required);

      this.billingAddressForm = new FormGroup({
         street: this.street,
         city: this.city,
         billingCountriesSelect: this.billingCountriesSelect,
         billingStatesSelect: this.billingStatesSelect,
         billingPostalCode: this.billingPostalCode
      });

      this._getBillingAddress();
   }

   private _getBillingAddress(): void {
      if (this._profile?.contactId) {
         this.isLoading
         this._profileService
            .getBillingAddress(this._profile.contactId)
            .then(billingAddress => {
               this._setBillingAddressData(billingAddress);
            })
            .catch(() => {
               /* Do Nothing */
            })
            .finally(() => (this.isLoading = false))
      }
      //else {
      //   this.planDetails = []
      //}
   }
   private _setBillingAddressData(billingAddress: IContactAddress) {

      billingAddress = billingAddress || { contactId: this._profile?.contactId, stateProvinceCode: StateProvince_.Unknown } as IContactAddress;

      if (billingAddress.stateProvinceCode) {
         const billingAddressProvince = StateProvinces.find(province => province.code == billingAddress.stateProvinceCode);

         if (billingAddressProvince) {
            this._commonService.setFormControlSelect(
               billingAddressProvince.countryCode,
               this.billingCountries,
               this.billingCountriesSelect
            )

            if (this.billingCountriesSelect) {
               const countryCode = (this.billingCountriesSelect.value as Country).code;
               this.billingStates = StateProvinces.filter(province => province.countryCode === countryCode);

               this._commonService.setFormControlSelect(
                  billingAddress.stateProvinceCode,
                  this.billingStates,
                  this.billingStatesSelect
               )
            }
         }
      }

      //this._commonService.setFormControlSelect(
      //   billingAddress.stateProvinceCode,
      //   this.billingStates,
      //   this.billingStatesSelect
      //)

      //if (this.billingStatesSelect.value) {
      //   this._commonService.setFormControlSelect(
      //      (this.billingStatesSelect.value as StateProvince).countryCode,
      //      this.billingCountries,
      //      this.billingCountriesSelect
      //   )
      //}

      this.billingAddressForm.patchValue({
         street: billingAddress.line1 || null,
         city: billingAddress.city || null,
         billingPostalCode: billingAddress.postalCode || null
      });
   }

   /*
   * public methods
   * */

   onBillingCountryChange(country: Country) {
      this.billingStatesSelect.setValue(null);
      this.billingStates = StateProvinces.filter(state => state.countryCode == country.code);
      this.billingStates.length ? this.billingStatesSelect.enable() : this.billingStatesSelect.disable();

      const regExp = this._commonService.regexp();
      let postalValidators = [Validators.required];
      if (country.code === Country_.CA) postalValidators.push(Validators.pattern(regExp.postalCode.caOnly));
      if (country.code === Country_.US) postalValidators.push(Validators.pattern(regExp.postalCode.usOnly));

      this.billingPostalCode.setValidators(postalValidators);
      this.billingPostalCode.updateValueAndValidity();
   }

   updateBilling() {
      if (this.billingAddressForm.valid) {
         this.isSubmitting = true

         const billingAddressFormValues = this.billingAddressForm.value;
         const billingCountryCode =
            billingAddressFormValues.billingCountriesSelect &&
            billingAddressFormValues.billingCountriesSelect.code;

         setTimeout(() => {
            this._commonService.messageUser(`<div class="text-center fs-5 text-uppercase">Billing Address Updated!</div>`)
            this.isSubmitting = false
         }, 2000)
         // .catch (() => {
         //   /* Do Nothing */
         //})
         //.finally(() => setTimeout(() => (this.isSubmitting = false), 500));
      }
      //else {
      //   this.isSubmitting = false
      //   this._commonService.messageUser(
      //      'Please select a Membership Plan',
      //      null,
      //      ToastrType.error
      //   )
      //}
   }
}
