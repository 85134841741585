import { Injectable, inject } from '@angular/core'
import { ActivatedRouteSnapshot, Router } from '@angular/router'
import { Observable, of } from 'rxjs'
import { AppFacade } from '@appShared/services/app.facade'
import { CommonService } from '@appShared/services/common.service'
import { environment } from '@appEnvironments/environment'

@Injectable({ providedIn: 'root' })
export class RegistrationGuardService {
   private _commonService = inject(CommonService)
   private _router = inject(Router)
   private _appFacade = inject(AppFacade)

   canActivate(
      route: ActivatedRouteSnapshot
   ): Observable<boolean> {

      const returnUrl = this._commonService.getResolvedUrl(route)
      const appFacade = this._appFacade
      const user = appFacade.currentUser()
      const routes = environment.routes
      const token = route.queryParamMap.get('token') || ''

      if (!token) {
         //no token - they shouldn't be here
         this._router.navigate([routes.uri])

         return of(false)
      }

      // Requesting Step 1 - if logged in already - Go to step 2
      if (returnUrl.startsWith('/sign-up') && !!user) {
         this._router.navigate([routes.registration.uri], {
            queryParams: { 'token': token }
         })

         return of(false)

      } else if (returnUrl.startsWith('/registration') && !!!user) {
         // Requesting Step 2 - if NOT logged in - go to Step 1
         this._router.navigate([routes.signUp.uri], {
            queryParams: { 'token': token }
         })

         return of(false)
      }

      return of(true)
   }
}
