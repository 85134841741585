import { ChangeDetectionStrategy, Component, computed, effect, EventEmitter, inject, OnInit, Output, TrackByFunction } from '@angular/core'
import { NgIf, NgFor, NgClass } from '@angular/common'
import { AccountsService, AppFacade } from '@appShared/services'
import { IProfile } from '@appShared/interfaces/[Model-based]/profile.interface'

@Component({
    selector: 'app-account-progress-student-list',
    templateUrl: './student-list.component.html',
    styleUrls: ['./student-list.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, NgFor, NgClass]
})
export class AccountProgressStudentListComponent implements OnInit {
   @Output() profileSelected = new EventEmitter()

   private _accountsService = inject(AccountsService)
   private _currentUser = inject(AppFacade).currentUser()

   private _profiles = this._accountsService.profiles
   filteredProfiles = computed(() => {
      const profiles = this._profiles()
      if (this._currentUser?.profile?.info?.isAccountOwner) {
         return profiles
      }

      return [this._currentUser?.profile]

   })

   selectedProfile: IProfile

   constructor() {
      effect(() => {
         const profiles = this.filteredProfiles()
         if (profiles?.length === 1) {
            this.selectStudent(null, profiles[0])
         }
      })
   }

   ngOnInit(): void {

      if (this._currentUser?.profile?.info?.isAccountOwner) {

         this._accountsService.getProfiles(true/*includeAccountOwner?*/)
      }
   }

   /*
    * private methods
    * */

   /*
    * public methods
    * */

   trackByProfileId: TrackByFunction<IProfile> = (index, profile) => profile.id

   selectStudent(evt, profile: IProfile) {
      if (profile) {
         this.selectedProfile = profile
         this.profileSelected.emit(this.selectedProfile)
      }
   }
}
