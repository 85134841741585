<div class="d-flex justify-content-center flex-wrap badges">

   <ng-template ngFor let-mentorAchievement [ngForOf]="contactMentorAchievements()">

      <div class="badge pointable d-flex flex-column">

          <!--might allow to go to the course in the future:
          (click)=goToRoute(mentorAchievement)-->

         <img src="{{mentorAchievement.imageSourceUri}}?v=23.06.08" class="card-img" />
      </div>

   </ng-template>

</div>

