<div class="d-flex justify-content-around flex-wrap">
   
   <div class="area">

      <div class="my-3 mt-0 p-3 pt-2 background-box-opaque-rounded shadow">
         <app-reset-password [isReset]="false"></app-reset-password>
      </div>

   </div>

</div>
