import { ChangeDetectionStrategy, Component, inject } from '@angular/core'
import { NgIf } from '@angular/common'
import { AppFacade, UrlService } from '@appShared/services'
import { IProfile } from '@appShared/interfaces/[Model-based]/profile.interface'
import { AccountProgressStudentBadgesComponent } from './student-badges/student-badges.component'
import { AccountProgressStudentListComponent } from './student-list/student-list.component'
import { environment } from '@appEnvironments/environment'

@Component({
    selector: 'app-account-progress',
    templateUrl: './progress.component.html',
    styleUrls: ['./progress.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, AccountProgressStudentListComponent, AccountProgressStudentBadgesComponent]
})
export class AccountProgressComponent {
   currentUser = inject(AppFacade).currentUser
   selectedProfile: IProfile
   progressTitle = environment.routes.member.account.progress.title

   constructor(
      urlService: UrlService
   ) {
      urlService.setHeaderTitle(this.progressTitle)
   }

   /*
    * private methods
    * */

   /*
    * public methods
    * */

   setSelectedProfile(profile: IProfile): void {
      this.selectedProfile = profile ? { ...profile } : null
   }
}
