import { HttpClient, HttpHeaders } from '@angular/common/http'
import { inject, Injectable } from '@angular/core'
import { lastValueFrom, Observable, throwError } from 'rxjs'
import { take, tap } from 'rxjs/operators'
import { CommonService } from '@appShared/services/common.service'
import { ISiteConfiguration } from '@appShared/interfaces/[Interface-based]/site-configuration.interface'
import { environment } from '@appEnvironments/environment'

@Injectable({ providedIn: 'root' })
export class AuthService {
   private _commonService = inject(CommonService)
   private _httpClient = inject(HttpClient)
   private _authApi = '/auth'
   private _siteConfig: ISiteConfiguration

   siteConfig$ = this._httpClient.get<ISiteConfiguration>('api/config').pipe(
      tap((siteConfig) => this._siteConfig = siteConfig)
   )

   /*
    public methods
   */

   delegateLogin(userPrincipalId: number): Observable<any> {
      if (!userPrincipalId) {
         throwError(() => new Error('No userPrincipalId  passed!'))
      }

      let headers = new HttpHeaders()
         .set(environment.userPrincipalIdHeaderName, userPrincipalId.toString())
         .set(environment.oAuthClientIdHeaderName, environment.oAuthClientIdentifier)
         .set(environment.oAuthClientSecretHeaderName, environment.oAuthClientSecret)

      return this._httpClient.post<any>(this._siteConfig.delegatedAuthorizationUri, null, {
         headers,
         withCredentials: true
      })
   }

   async resetPassword(password: string): Promise<any> {
      if (!password) {
         return new Promise((resolve, reject) => reject('No password passed!'))
      }

      const url = `${this._authApi}/set-password`

      const request$ = this._httpClient.put(url, { password }, this._commonService.httpOptions()).pipe(take(1))

      return await lastValueFrom(request$)
   }
}
