import { inject, Injectable } from '@angular/core'
import {
    ActivatedRouteSnapshot,
   Router,
   RouterStateSnapshot
} from '@angular/router'
import { Observable, of } from 'rxjs'
import { AppFacade, CommonService, NotificationsService, SiteNotificationType_ } from '@appShared/services'
import { ISubscription } from '@appShared/interfaces/[Model-based]/subscription.interface'
import { SubscriptionStatus_ } from '@appShared/services/lookup/[CodeGen]/subscription-status.domain'
import { environment } from '@appEnvironments/environment'

@Injectable({ providedIn: 'root' })
export class AuthGuardUserService {
   private _router = inject(Router)
   private _commonService = inject(CommonService)
   private _appFacade = inject(AppFacade)
   private _notificationsService = inject(NotificationsService)

   canActivate(
      route: ActivatedRouteSnapshot,
      state: RouterStateSnapshot
   ): Observable<boolean> {

      const returnUrl = state.url
      const appFacade = this._appFacade
      const user = appFacade.currentUser()

      if (!(user?.profile)) {

         this._commonService.navigateToLogin(returnUrl)
         return of(false)
      }

      if (user.isAdmin || user.isRep || user.isPromo) {
         return of(true)
      }

      const memberRoutes = environment.routes.member
      const setPasswordUrl = `/${memberRoutes.setPassword.uri}`

      if (user.mustResetPassword
         && !returnUrl.startsWith(setPasswordUrl)) {
         // force them to set-password
         this._router.navigate([setPasswordUrl])

         return of(false)
      }

      if (!user.mustResetPassword
         && returnUrl.startsWith(setPasswordUrl)) {
         // don't need set-password
         this._router.navigate([memberRoutes.uri])

         return of(false)

      }

      const accountRoutes = memberRoutes.account
      const config = appFacade.siteConfig()
      if (config.isAccountOnlyMode) {
         this._router.navigate([accountRoutes.uri])
         return of(false)
      }


      const accountBillingUrl = `/${accountRoutes.billing.uri}`
      const accountMembershipUrl = `/${accountRoutes.membership.uri}`
      const accountProfileUrl = `/${accountRoutes.profile.uri}`
      const siteNotificationsUrl = `/${memberRoutes.siteNotifications.uri}`
      const profile = user?.profile
      const subscriptions = profile?.account?.subscriptions || [] as ISubscription[]
      const currentSubscription = subscriptions.find(sub => [
         SubscriptionStatus_.Active,
         SubscriptionStatus_.Paused,
         SubscriptionStatus_.OnHold,
         SubscriptionStatus_.Cancelled,
         SubscriptionStatus_.Ended
      ].includes(sub.statusCode))

      if ((!currentSubscription
            || [SubscriptionStatus_.OnHold,
               SubscriptionStatus_.Paused,
               SubscriptionStatus_.Cancelled,
               SubscriptionStatus_.Ended].includes(currentSubscription?.statusCode))
            && (returnUrl !== accountMembershipUrl && returnUrl !== accountBillingUrl &&
                  returnUrl !== accountProfileUrl && returnUrl !== siteNotificationsUrl)) {

         if (profile?.info?.isAccountOwner) {
            // subscription is on hold - must address billing
            if (currentSubscription?.statusCode === SubscriptionStatus_.OnHold) {
               this._router.navigate([accountBillingUrl])
            } else {
               this._router.navigate([accountMembershipUrl])
            }

         } else {
            //non-accountowner user - send to notification page
            this._notificationsService.setCurrentNotification(SiteNotificationType_.NonAccountOwnerIssueWithAccount)
            setTimeout(() => this._router.navigate([siteNotificationsUrl]), 0)
         }

         return of(false)
      }

      return of(true)
   }
}
