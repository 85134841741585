import {
   Component,
   ChangeDetectionStrategy,
   Input
} from '@angular/core';
import { IClientPaymentInstrument } from '@appShared/services/accounts.service';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-payment-instrument-display',
    templateUrl: './payment-instrument-display.component.html',
    styleUrls: ['./payment-instrument-display.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf]
})
export class PaymentInstrumentDisplayComponent {
   @Input() paymentInstrument: IClientPaymentInstrument
}
