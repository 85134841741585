<div *ngIf="filteredProfiles()?.length > 1"
     class="list-group student-list">

   <button type="button" class="list-group-item list-group-item-action"
           *ngFor="let profile of filteredProfiles(); trackBy: trackByProfileId"
           (click)="selectStudent($event, profile)"
           [ngClass]="{'active': selectedProfile == profile}"
           data-bs-toggle="button">
      {{profile.contact.firstName}}
   </button>

</div>
