import {
   AccountBillingComponent,
   AccountBillingAddressComponent,
   AccountBillingHistoryComponent,
   AccountPaymentAuthComponent
} from './billing'
import { AccountHomeComponent } from './home/home.component'
import { AccountMembershipComponent } from './membership/membership.component'
import { AccountNotificationsComponent } from './notifications/notifications.component'
import { AccountPasswordComponent } from './password/password.component'
import { AccountProfileComponent } from './profile/profile.component'
import {
   AccountProgressComponent,
   AccountProgressStudentBadgesComponent,
   AccountProgressStudentListComponent
} from './progress'
import {
   AccountStudentComponent,
   AccountStudentsComponent,
   AccountStudentsListComponent
} from './students'
import { PaymentInstrumentDisplayComponent } from './payment-instrument-display/payment-instrument-display.component'

export const components: any[] = [
   AccountBillingAddressComponent,
   AccountBillingComponent,
   AccountBillingHistoryComponent,
   AccountHomeComponent,
   AccountMembershipComponent,
   AccountNotificationsComponent,
   AccountPasswordComponent,
   AccountPaymentAuthComponent,
   AccountProfileComponent,
   AccountProgressComponent,
   AccountProgressStudentBadgesComponent,
   AccountProgressStudentListComponent,
   AccountStudentComponent,
   AccountStudentsComponent,
   AccountStudentsListComponent,
   PaymentInstrumentDisplayComponent
]

export * from './billing'
export * from './home/home.component'
export * from './membership/membership.component'
export * from './notifications/notifications.component'
export * from './password/password.component'
export * from './profile/profile.component'
export * from './progress'
export * from './students'
export * from './payment-instrument-display/payment-instrument-display.component'
