import { Routes, mapToCanActivate } from '@angular/router'
import {
   LeadSection,
   LeadSection_,
   LeadSections
} from '@appShared/services/lookup/lead-section'
import {
   AccountBillingComponent,
   AccountHomeComponent,
   AccountMembershipComponent,
   AccountNotificationsComponent,
   AccountProfileComponent,
   AccountProgressComponent,
   AccountStudentsComponent
} from './components'
import { AccountShellComponent } from './containers'
import { AuthGuardAccountOwnerService, AuthGuardUserService } from '@appShared/services'
import { environment } from '@appEnvironments/environment'

const _homeSection: LeadSection = LeadSections.find(
   section => section.code == LeadSection_.Home
)

const accountRoutes = environment.routes.member.account

const childAccountRoutes: Routes = [
   {
      path: '',
      component: AccountHomeComponent,
      data: { section: { ..._homeSection, title: accountRoutes.title } },
   },
   {
      path: accountRoutes.profile.baseUri,
      canActivate: mapToCanActivate([AuthGuardUserService]),
      component: AccountProfileComponent,
      data: { section: { ..._homeSection, title: accountRoutes.profile.title } }
   },
   {
      path: accountRoutes.notifications.baseUri,
      component: AccountNotificationsComponent,
      data: { section: { ..._homeSection, title: accountRoutes.notifications.title } }
   },
   {
      path: accountRoutes.users.baseUri,
      canActivate: mapToCanActivate([AuthGuardAccountOwnerService]),
      component: AccountStudentsComponent,
      data: { section: { ..._homeSection, title: accountRoutes.users.title } }
   },
   {
      path: accountRoutes.progress.baseUri,
      canActivate: mapToCanActivate([AuthGuardUserService]),
      component: AccountProgressComponent,
      data: { section: { ..._homeSection, title: accountRoutes.progress.title } }
   },
   {
      path: accountRoutes.membership.baseUri,
      canActivate: mapToCanActivate([AuthGuardAccountOwnerService]),
      component: AccountMembershipComponent,
      data: { section: { ..._homeSection, title: accountRoutes.membership.title } }
   },
   {
      path: accountRoutes.billing.baseUri,
      canActivate: mapToCanActivate([AuthGuardAccountOwnerService]),
      component: AccountBillingComponent,
      data: { section: { ..._homeSection, title: accountRoutes.billing.title } }
   }
]

export const ACCOUNT_ROUTES: Routes = [
   {
      path: accountRoutes.baseUri, /* If not lazy-loading accountRoutes.baseUri, else '' */
      component: AccountShellComponent,
      data: { section: { ..._homeSection, title: accountRoutes.title } },
      children: childAccountRoutes
   }
]
