<fieldset id="account-profile"
           class="mt-2">
   <legend>{{(isNewRecord ? 'Add' : 'Edit')}} User<span class="text-site" *ngIf="!isNewRecord">: {{this.profile?.contact?.firstName}} {{this.profile?.contact?.lastName}}</span></legend>

   <form [formGroup]="profileSubmissionForm"
         (ngSubmit)="updateProfile()"
         autocomplete="off" novalidate>

      <div class="row mt-4">

         <div class="col-md-12 d-lg-none"
              *ngIf="isNewRecord">

            <div class="mt-3 d-flex h-100 align-items-center">

               <div class="pretty p-icon p-smooth">
                  <input type="checkbox"
                         #useNicknameInsteadCheckBox
                         id="useNicknameInsteadCheckBox"
                         [checked]="useNickname"
                         (change)="useNicknameInstead(useNicknameInsteadCheckBox.checked)" />
                  <div class="state p-success">
                     <i class="icon mdi mdi-check"></i>
                     <label>Sign-in with Nickname instead</label>
                  </div>
               </div>

            </div>

         </div>

         <div class="col-lg-6">

            <div [ngClass]="{'error': emailAddress.invalid && (emailAddress.dirty || emailAddress.touched || mouseoverProfileSubmissionSubmit) }"
                 *ngIf="!useNickname">
               <label for="emailAddress" class="form-label">
                  Email Address (for sign-in)

               </label>
               <em *ngIf="emailAddress.invalid && (emailAddress.dirty || emailAddress.touched || mouseoverProfileSubmissionSubmit)" class="error-msg">
                  <span *ngIf="emailAddress.errors?.required">
                     Required
                  </span>
                  <span *ngIf="emailAddress.errors?.pattern">
                     Enter valid email
                  </span>
               </em>
               <input formControlName="emailAddress"
                      id="emailAddress"
                      type="text"
                      class="form-control"
                      placeholder="Email Address"
                      required />
            </div>

            <div [ngClass]="{'error': nickname.invalid && (nickname.dirty || nickname.touched || mouseoverProfileSubmissionSubmit) }"
                 *ngIf="useNickname">
               <label for="nicknameInput" class="form-label">
                  Nickname (for sign-in)
               </label>
               <em *ngIf="nickname.invalid && (nickname.dirty || nickname.touched || mouseoverProfileSubmissionSubmit)" class="error-msg">
                  <span *ngIf="nickname.errors?.required">
                     Required
                  </span>
                  <span *ngIf="nickname.errors?.pattern">
                     {{nicknameMessage}}
                  </span>
               </em>
               <input formControlName="nickname"
                      id="nicknameInput"
                      type="text"
                      maxlength="16"
                      [textMask]="{mask: nicknameMask, guide: false}"
                      class="form-control"
                      placeholder="Nickname"
                      required />
            </div>

         </div>

         <div class="col-lg-6 d-none d-lg-block"
              *ngIf="isNewRecord">

            <div class="mt-3 d-flex h-100 align-items-center">

               <div class="pretty p-icon p-smooth">
                  <input type="checkbox"
                         #useNicknameInsteadCheckBox
                         id="useNicknameInsteadCheckBox"
                         [checked]="useNickname"
                         (change)="useNicknameInstead(useNicknameInsteadCheckBox.checked)" />
                  <div class="state p-success">
                     <i class="icon mdi mdi-check"></i>
                     <label>Sign-in with Nickname instead</label>
                  </div>
               </div>

            </div>

         </div>

      </div>

      <div class="row">

         <div class="col-lg-6">
            <div class="form-group"
                 [ngClass]="{'error': firstName.invalid && (firstName.dirty || firstName.touched || mouseoverProfileSubmissionSubmit)}">
               <label for="firstName" class="form-label">Legal First Name</label>
               <em *ngIf="firstName.invalid && (firstName.dirty || firstName.touched || mouseoverProfileSubmissionSubmit)" class="error-msg">
                  Required
               </em>
               <input formControlName="firstName"
                      id="firstName"
                      type="text"
                      class="form-control"
                      placeholder="First Name"
                      required />
            </div>
         </div>

         <div class="col-lg-6">
            <div class="form-group"
                 [ngClass]="{'error': lastName.invalid && (lastName.dirty || lastName.touched || mouseoverProfileSubmissionSubmit)}">
               <label for="lastName" class="form-label">Last Name</label>
               <em *ngIf="lastName.invalid && (lastName.dirty || lastName.touched || mouseoverProfileSubmissionSubmit)" class="error-msg">
                  Required
               </em>
               <input formControlName="lastName"
                      id="lastName"
                      type="text"
                      class="form-control"
                      placeholder="Last Name"
                      required />
            </div>
         </div>

      </div>

      <div class="row">

         <div class="col-lg-4">

            <div class="form-group"
                 [ngClass]="{'error': dateOfBirthPicker.invalid && (dateOfBirthPicker.dirty || dateOfBirthPicker.touched || mouseoverProfileSubmissionSubmit)}">
               <span class="fake-label">Birth Date <small class="text-danger ps-2">(optional)</small></span>
               <div class="input-group">
                  <input formControlName="dateOfBirthPicker"
                         #dateOfBirthInput="bsDatepicker"
                         type="text"
                         class="form-control"
                         container=""
                         bsDatepicker
                         [bsConfig]="datePickerBaseConfig"
                         [minDate]="dateOfBirthMinDate"
                         [maxDate]="dateOfBirthMaxDate"><!--(bsValueChange)="onItemTextTypeDateSelection(true, $event)"-->
                  <div class="input-group-append">
                     <button class="btn btn-outline-secondary"
                             type="button"
                             (click)="dateOfBirthInput.toggle()">
                        <i class="fas fa-calendar-times"></i>
                     </button>
                  </div>
               </div>
            </div>

         </div>

         <div class="col-lg-8">

            <span class="fake-label">Which <span class="d-none d-md-inline-block">best</span> describes you this next school year?</span>
            <em *ngIf="educationLevelSelect.invalid && (educationLevelSelect.dirty || educationLevelSelect.touched || mouseoverProfileSubmissionSubmit)" class="error-msg">
               Req
            </em>
            <ng-select formControlName="educationLevelSelect"
                       [items]="educationLevels"
                       bindLabel="description"
                       [clearable]="false"
                       appendTo="body">
            </ng-select>

         </div>

      </div>

      <div class="row">

         <div class="col-lg-4">

            <div class="well mt-2">
               <label for="institutionTypeSelectFieldName" class="form-label">School Type</label>
               <em *ngIf="!institutionTypeSelect && mouseoverProfileSubmissionSubmit"
                   class="error-msg float-none">
                  Required
               </em>
               <div class="row well" style="margin: 0 10px 10px;">
                  <span class="d-inline-block mt-2" *ngFor="let institutionType of institutionTypes">
                     <span class="d-block pretty p-default p-round">
                        <input type="radio"
                               [formControlName]="institutionTypeSelectFieldName"
                               [value]="institutionType.code"
                               (change)="institutionTypeSelect = institutionType"
                               required />
                        <span class="d-block state p-success-o">
                           <label>{{institutionType.description}}</label>
                        </span>
                     </span>
                  </span>
               </div>
            </div>

         </div>

         <div class="col-lg-8 border-top"
              [hidden]="!isNewRecord">

            <div class="row">

               <div class="col"
                    [ngClass]="{'error': password.invalid && (password.dirty || password.touched || mouseoverProfileSubmissionSubmit)}">
                  <label for="password" class="form-label">Password</label>
                  <em *ngIf="password.invalid && (password.dirty || password.touched || mouseoverProfileSubmissionSubmit)" class="error-msg">
                     <span *ngIf="password.errors?.required">
                        Required
                     </span>
                     <span *ngIf="password.errors?.pattern">
                        {{passwordMessage}}
                     </span>
                  </em>
                  <input formControlName="password"
                         id="password"
                         type="password"
                         class="form-control"
                         required />
               </div>

            </div>

            <div class="row">
               <div class="col"
                    [ngClass]="{'error': confirmPassword.invalid && (confirmPassword.dirty || confirmPassword.touched || mouseoverProfileSubmissionSubmit) }">
                  <label for="confirmPassword" class="form-label">Confirm Password:</label>
                  <em *ngIf="confirmPassword.invalid && (confirmPassword.dirty || confirmPassword.touched || mouseoverProfileSubmissionSubmit)" class="error-msg">
                     <span *ngIf="confirmPassword.errors?.required">
                        Required
                     </span>
                     <span *ngIf="!confirmPassword.errors?.required && confirmPassword.errors?.matching">
                        Confirm password must match
                     </span>
                  </em>
                  <input formControlName="confirmPassword"
                         id="confirmPassword"
                         type="password"
                         class="form-control"
                         required />
               </div>

            </div>

            </div>

      </div>

      <div>

         <div class="row">

            <div class="col d-flex justify-content-end align-items-center">

               <span (mouseenter)="mouseoverProfileSubmissionSubmit=true" (mouseleave)="mouseoverProfileSubmissionSubmit=false">
                  <app-button [buttonConfig]="{textChange: ['Create', 'Update']}"
                              [textChangeIndex]="+(!isNewRecord)"
                              [isLoading]="isSubmitting"
                              [disabled]="!profileSubmissionForm.valid || isSubmitting"
                              (textBtnClickEmt)="updateProfile()"></app-button>
               </span>

               <button class="shadow btn btn-danger ms-2 d-flex justify-content-between align-items-center"
                       type="button"
                       (click)="cancel()">
                  <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                     <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                  </svg> Cancel
               </button>

            </div>

         </div>

      </div>

   </form>

</fieldset>
