import { ChangeDetectionStrategy, Component } from '@angular/core'
import { NgIf, AsyncPipe } from '@angular/common'
import { Router } from '@angular/router'
import { Observable, of } from 'rxjs'
import { IUser } from '@appShared/interfaces/[Interface-based]/user.interface'
import { AuthService } from '@appShared/services/auth.service'
import { HybridWebviewInteropService } from '@appShared/services/hybrid-webview-interop.service'
import { environment } from '@appEnvironments/environment'

@Component({
    selector: 'app-landing',
    templateUrl: './landing.component.html',
    styles: [
        `
      .logo { max-width: 500px }
      a.btn { width: 350px }
      a.btn.bordered { border: solid 1px #0484ba }
      .app-store img { width: 260px}
      @media (max-width: 650px) {
         .app-store img { width: 200px}
      }
      @media (max-width: 430px) {
         .app-store { flex-direction: column !important }
      }
   `
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, AsyncPipe]
})
export class LandingComponent {
   user: IUser
   isAdminOnlyMode$: Observable<boolean>
   stockPlanUri$: Observable<string>
   pin: string
   registrationUri = environment.routes.registration.uri
   appStore = environment.appStore
   isDotNetInteroperable$: Observable<boolean>

   constructor(
      //private _appFacade: AppFacade,
      //private _shareholderService: ShareholderService,
      private _router: Router,
      private _authService: AuthService,
      hybridService: HybridWebviewInteropService
   ) {
      this.isDotNetInteroperable$ = hybridService.isDotNetInteroperable$
      //this._authService
      //   .delegateLogin(4066827)
      //   .then(response => {
      //      this._router.navigate(['/member/set-password'])
      //   })
      //   .catch(err => {
      //      /* Do Nothing */
      //      console.log(err)
      //   })
   }

   /*
   * private methods
   * */

   /*
   * public methods
   * */

}
