import { AfterViewInit, ChangeDetectionStrategy, Component, DestroyRef, inject } from '@angular/core'
import { NgClass, NgIf } from '@angular/common'
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router'
import { filter, map, switchMap } from 'rxjs'
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop'
import { LeadSection, LeadSections, LeadSection_ } from '@appShared/services/lookup/lead-section'
import { AppFacade } from '@appShared/services/app.facade'
import { HybridWebviewInteropService } from '@appShared/services/hybrid-webview-interop.service'
import { SiteFooterComponent } from '@appShared/components/site-footer/site-footer.component'
import { InternalHeaderComponent } from '@appShared/components/internal-header/internal-header.component'
import { PublicHeaderComponent } from '@appShared/components/public-header/public-header.component'

@Component({
   selector: 'app-shell',
   templateUrl: './shell.component.html',
   styles: [`
      .site-container {
         height: 100vh;
         height: 100dvh;
         display: grid;
         grid-template-rows: auto 1fr auto;
      }

      .site-container.hybrid-view {
         grid-template-rows: 1fr;
      }

      app-public-header,
      app-internal-header {
         position: sticky; /* Makes the header sticky */
         top: 0; /* Sticks the header to the top */
         z-index: 1000; /* Ensures the header is above other content */
      }
   `],
   changeDetection: ChangeDetectionStrategy.OnPush,
   standalone: true,
   imports: [NgIf, NgClass, PublicHeaderComponent, InternalHeaderComponent, RouterOutlet, SiteFooterComponent]
})
export class ShellComponent implements AfterViewInit {
   title = 'SWFC - LEAD'
   currentYear = new Date().getFullYear()
   sidebarToggle: HTMLElement
   user = inject(AppFacade).currentUser
   isDotNetInteroperable = toSignal(inject(HybridWebviewInteropService).isDotNetInteroperable$)

   private _initialLeadSection = LeadSections.find(section => section.code === LeadSection_.Home)
   leadSection = { ...this._initialLeadSection }

   constructor(
      private _router: Router,
      activatedRoute: ActivatedRoute,
      destroyRef: DestroyRef
   ) {

      //this._globalPubSubService.subscribe("angular.learning.say-hi", this.sayHi, this)
      //this._globalPubSubService.subscribe("angular.learning.retrieve-user-info", this.getMeUserInfo, this)
      //this._globalPubSubService.subscribe("angular.learning.trigger-and-emit-something", this.triggerAndEmit, this)

      this._router.events
         .pipe(
            takeUntilDestroyed(),
            filter(routeEvent => routeEvent instanceof NavigationEnd),
            map(() => activatedRoute),
            map(route => {
               while (route.firstChild) route = route.firstChild
               return route
            }),
            switchMap(firstChild => {
               return firstChild?.data
            }),
            map((data: LeadSection) => {
               return data
            }),
            takeUntilDestroyed(destroyRef)
         )
         .subscribe(data => {
            this.leadSection = { ...(data['section'] || this._initialLeadSection as LeadSection) }

         })
   }

   ngAfterViewInit() {
      this.sidebarToggle = document.getElementById('sidebar-toggle')
      if (this.sidebarToggle) {
         //Uncomment Below to persist sidebar toggle between refreshes
         //if (localStorage.getItem('sb|sidebar-toggle') === 'true') {
         //   document.body.classList.toggle('sb-sidenav-toggled')
         //}
         this.sidebarToggle.addEventListener('click', event => {
            event.preventDefault()
            document.body.classList.toggle('sb-sidenav-toggled')
            //localStorage.setItem(
            //   'sb|sidebar-toggle',
            //   document.body.classList.contains('sb-sidenav-toggled').toString()
            //)
         })
      }

      const sidebarWrapperLinks = document.body.querySelectorAll(
         '#sidebar-wrapper li > a'
      )
      if (sidebarWrapperLinks) {
         sidebarWrapperLinks.forEach(link => {
            link.addEventListener('click', event => {
               //only toggle sidebar if toggle button is visible (has offsetParent)
               if (this.sidebarToggle.offsetParent) {
                  document.body.classList.toggle('sb-sidenav-toggled')
                  //localStorage.setItem(
                  //   'sb|sidebar-toggle',
                  //   document.body.classList
                  //      .contains('sb-sidenav-toggled')
                  //      .toString()
                  //)
               }

               // issue with tooltips that won't go away when a link is clicked - remove it
               let tooltips = document.querySelectorAll("div[role='tooltip']")
               tooltips.forEach(item => item.remove())
            })
         })
      }
   }
}
