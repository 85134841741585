import { ChangeDetectionStrategy, Component, inject } from '@angular/core'
import { take } from 'rxjs'
import { Title } from '@angular/platform-browser'
import { UrlService } from '@appShared/services/url.service'
import { AppFacade } from '@appShared/services/app.facade'
import { BrightcoveExperiencePlayerDirective } from '@appShared/directives/brightcove-experience-player.directive'
import { environment } from '@appEnvironments/environment'

@Component({
    selector: 'app-content-faq',
    template: `
            <div brightcove-experience-player
                 [attr.data-experience-id]="faqExperience"
                 [attr.data-user-id]="brightcoveClientUserId">
            </div>
   `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [BrightcoveExperiencePlayerDirective]
})
export class FaqComponent {
   faq = environment.routes.content.faq
   faqTitle = this.faq.title
   faqExperience = environment.brightcove.experiences.faq

   private _currentUser = inject(AppFacade).currentUser
   brightcoveClientUserId = this._currentUser()?.profile.contact.auditId

   constructor(
      /*router: Router*/
      private _urlService: UrlService,
      titleService: Title
   ) {
      titleService.setTitle(`LEAD - ${this.faqTitle}`)
   }

   ngOnInit() {
      //they don't want header for now since page had a header
      //setTimeout(() => { this._urlService.setHeaderTitle(this.faqTitle) })

      setTimeout(() => { this._urlService.setHeaderTitle('') })
   }
}
