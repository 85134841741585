import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

// custom validator to check that two fields match
export function MustMatchValidator(
   matchTo: string,
   reverse?: boolean
): ValidatorFn {
   return (control: AbstractControl):
      ValidationErrors | null => {
      if (control.parent && reverse) {
         const c = (control.parent?.controls as any)[matchTo] as AbstractControl;
         if (c) {
            c.updateValueAndValidity();
         }
         return null;
      }
      return !!control.parent &&
         !!control.parent.value &&
         control.value ===
         (control.parent?.controls as any)[matchTo].value
         ? null
         : { matching: true };//'matching' is the error that is set on the control
   }
}
/*
 *
 * example in ts:
 *
   this.password = new FormControl(null, [
      Validators.required,
      Validators.pattern(this._commonService.regexp().password),
      MustMatchValidator('confirmPassword', true)
   ]);
   this.confirmPassword = new FormControl(null, [
      Validators.required,
      MustMatchValidator('password')
   ]);

   * example in markup (check for errors.matching on control you set to "true" above - error only shows for that control:
   *

   <em *ngIf="confirmPassword.invalid && (confirmPassword.dirty || confirmPassword.touched || mouseoverPasswordSubmit)" class="error-msg">
      <span *ngIf="confirmPassword.errors.required">
         Required
      </span>
      <span *ngIf="password.errors.pattern">
         {{passwordMessage}}
      </span>
      <span *ngIf="confirmPassword.errors.matching">
         Confirm password must match above
      </span>
   </em>

 * */
