import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ResetPasswordComponent } from '../../components/reset-password/reset-password.component';

@Component({
    selector: 'app-reset-password-shell',
    templateUrl: './reset-password-shell.component.html',
    styleUrls: ['./reset-password-shell.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [ResetPasswordComponent]
})
export class ResetPasswordShellComponent {

   constructor() { }
}
