
<h3 *ngIf="profile">
   {{profile.contact.firstName}} - Levels Achieved /
   <span *ngIf="badgesLevel">level {{badgesLevel}}</span>
   <span *ngIf="!badgesLevel">none</span>
</h3>

<div class="d-flex justify-content-center flex-wrap badges">
   <ng-template ngFor let-profileBadge [ngForOf]="profileBadges$ | async">
      <a *ngIf="profileBadge.isInProgress || profileBadge.isMastered"
         routerLink="/{{contentRootUri}}/{{profileBadge.innerRoutePath}}/c/{{(profileBadge.urlSlug || 'module')}}"
         class="badge">
         <div class="card bg-dark text-white mb-1 position-relative"
              [ngClass]="{'in-progress': profileBadge.isInProgress}">

            <svg *ngIf="profileBadge.isMastered"
                 xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="green"
                 class="bi bi-check-lg position-absolute top-0 end-0 m-2" viewBox="0 0 16 16">
               <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
            </svg>

            <svg *ngIf="profileBadge.isInProgress"
                 xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="yellow"
                 class="bi bi-three-dots position-absolute top-0 end-0 m-2" viewBox="0 0 16 16">
               <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
            </svg>


            <!--<img [src]="lessonCard.imageSource" class="card-img" alt="{{lessonCard.name}}">-->
            <img src="/assets/images/life-leadership-modules/schedule.png?v=22.06.08" class="card-img" />
            <!--<div class="card-img-overlay d-flex"
        [ngClass]="{'quiz': lessonCard.evaluationId, 'quiz-is-mastered': lessonCard.isMastered}">
      <div class="lesson-name flex-fill align-self-center text-center text-white">{{lessonCard.name}}</div>
   </div>-->
         </div>
      </a>

      <span *ngIf="!(profileBadge.isInProgress || profileBadge.isMastered)"
            class="badge">
         <div class="card bg-dark text-white mb-1 position-relative"
              [ngClass]="{'in-progress': profileBadge.isInProgress}">
            <img src="/assets/images/progress/in-progress.png" class="position-absolute top-0 end-0 m-2" alt="In Progress"
                 *ngIf="profileBadge.isInProgress" />
            <!--<img [src]="lessonCard.imageSource" class="card-img" alt="{{lessonCard.name}}">-->
            <img *ngIf="!profileBadge.isMastered"
                 src="/assets/images/progress/progress-badge-alt.png?v=23.04.11" class="card-img" />
            <img *ngIf="profileBadge.isMastered"
                 src="/assets/images/progress/progress-badge.png?v=23.04.11" class="card-img" />
            <!--<div class="card-img-overlay d-flex"
              [ngClass]="{'quiz': lessonCard.evaluationId, 'quiz-is-mastered': lessonCard.isMastered}">
            <div class="lesson-name flex-fill align-self-center text-center text-white">{{lessonCard.name}}</div>
         </div>-->
         </div>
      </span>

   </ng-template>

</div>
