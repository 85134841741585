import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonService } from '@appShared/services/common.service';
import { environment } from '../../../../environments/environment';
import { RouterLink } from '@angular/router';

@Component({
    selector: 'app-account-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [RouterLink]
})
export class AccountHomeComponent {
   accountRoutes = environment.routes.member.account

   constructor(
      private _commonService: CommonService
   ) { }

   comingSoon(areaName?: string) {
      const message = areaName ? `"${areaName}" feature is coming soon.` : ''
      this._commonService.comingSoon(message)
   }

   logout() {
      this._commonService.logout()
   }
}
